import * as actionTypes from '../action-types';
import { addBrand,delBrand,getBrand } from '../../api/brand';

const actionCreators = {
    addBrand(data) {
        return (dispatch) => new Promise(async (resolve, reject) => {
            try {
                dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
                let res = await addBrand(data)
                resolve(res)
            } catch (error) {                
               
            } finally {
                dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
            }
        })
       
    },
    getBrand(data) {
        return (dispatch) => new Promise(async (resolve, reject) => {
            try {
                dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
                let res = await getBrand(data)
                resolve(res)
            } catch (error) {                
               
            } finally {
                dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
            }
        })
       
    },
    delBrand(id) {
        return (dispatch) => new Promise(async (resolve, reject) => {
            try {
                dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
                let res = await delBrand(id)
                resolve(res)
            } catch (error) {                
               
            } finally {
                dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
            }
        })
       
    },
}
export default actionCreators;