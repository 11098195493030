import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import Select from "react-select";

var walletOptions = [
  { value: "one", label: "one" },
  { value: "two", label: "Two" },
  { value: "three", label: "Three" },
  { value: "four", label: "Four" },
  { value: "five", label: "Five" },
  { value: "six", label: "Six" },
];

var payeeOptions = [
  { value: "one", label: "Shaw Cable" },
  { value: "two", label: "Two" },
  { value: "three", label: "Three" },
  { value: "four", label: "Four" },
  { value: "five", label: "Five" },
  { value: "six", label: "Six" },
];

function WireTransfer({ setPage }) {
  const [payeeSelect, setPayeeSelect] = React.useState(null);
  const [walletSelect, setWalletSelect] = React.useState(null);

  const continueButton = () => {
    window.location.href = "/admin/wire-transfer/confirm";
  };

  return (
    <>
      <div className="content paybill">
        <Row>
          <Col md="7">
            <Card>
              <CardHeader className="paybill-header">
                <h5 className="title ml-2">Edit Campaign</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>Title</label>
                        <Input
                          defaultValue="Jorge Smith"
                          placeholder="Beneficiary Name"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="" md="6">
                      <FormGroup>
                        <label>Amount</label>
                        <Input placeholder="Amount" type="number" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="" md="12">
                      <FormGroup>
                        <label>Description</label>
                        <Input
                          defaultValue="Something"
                          placeholder="Description"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="" md="12">
                      <FormGroup>
                        <label>Keywords</label>
                        <Input
                          defaultValue="nike"
                          placeholder="Search words"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>Category</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          placeholder="Single Select"
                          name="singleSelect"
                          value={walletSelect}
                          options={walletOptions}
                          onChange={(value) => setWalletSelect(value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>Beneficiary Name</label>
                        <Input
                          defaultValue="Jorge Smith"
                          placeholder="Beneficiary Name"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      md="6"
                      className="footer-buttons d-flex justify-content-start">
                      <Button
                        onClick={() => setPage("2")}
                        size="md"
                        className="btn-round mr-2 cancel-button">
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        size="md"
                        className="btn-round"
                        onClick={continueButton}>
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default WireTransfer;
