import * as actionTypes from '../action-types';

let initialState = {
    cities:[],
    provinces:[]
};
function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_CITY:
            let city = action.payload
            return {
                ...state,
                cities:city
            }
        case actionTypes.GET_PROVINCES:
            let province = action.payload
            return {
                ...state,
                provinces:province
            }  
        default:
            return state;
    }
}
export default reducer;