import React,{useEffect} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import Select from "react-select";
// import "../style.css";
import actionCreators from "../../../../../store/actionCreators/intesetsCategory"
import { connect } from "react-redux"
import { message } from "antd";
function CreateCategory({ setPage,addCategory }) {
  const [Category, setCategory] = React.useState('');
  
  
  const continueButton = () => {
    // window.location.href = "/admin/move-money/confirm";
    let obj={name:Category}
    addCategory(obj).then(res=>{
      message.success('Category successfully added')
      setPage("1")
    })
  };

  return (
    <>
      <div className="content paybill">
        <Row>
          <Col md="7">
            <Card>
              <CardHeader className="paybill-header">
                <h5 className="title ml-2">Create Category</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>Category Name</label>
                        <Input placeholder="Category Name" type="text" value={Category} onChange={(e)=>{ setCategory(e.target.value)}}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col
                      md="6"
                      className="footer-buttons d-flex justify-content-start">
                      <Button
                        onClick={() => setPage("1")}
                        color="secondary"
                        size="md"
                        className="btn-round mr-2 cancel-button">
                        Cancel{" "}
                      </Button>

                      <Button
                        onClick={continueButton}
                        color="primary"
                        size="md"
                        className="btn-round">
                        Ok
                      </Button>
                    </Col>
                    <Col md="6"></Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
function mapStateToProps(state) {
  return state.user
}
export default connect(mapStateToProps, actionCreators)(CreateCategory);
