import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"
import { message } from "antd"
import "bootstrap/dist/css/bootstrap.css"
import "assets/scss/now-ui-dashboard.scss?v=1.5.0"
import "assets/css/demo.css"
import "./antd.css"
import AdminLayout from "layouts/Admin.js"
import AuthLayout from "layouts/Auth.js"
import { Provider } from "react-redux"
import store from "./store"
import history from "./history"
import Loading from "./components/Loading"
import { ConnectedRouter } from "connected-react-router"
message.config({
  duration: 1,
  maxCount: 1,
})
ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Loading />
      <Switch>
        <Route
          path="/admin"
          render={(props) => {
            return <AdminLayout {...props} />
          }}
        />
        <Route
          path="/auth"
          render={(props) => {
            return <AuthLayout {...props} />
          }}
        />
        <Redirect to="/admin/dashboard" />
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
)
