import React, {useEffect, useRef} from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
} from "reactstrap";
import leftIcon from "assets/icons/icon-left.png";
import { connect } from "react-redux";
import actionCreators from "store/actionCreators/offer.js";
import Table from "components/Table/Table.js";
import moment from "moment"
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

function CouponAnalytics(props) {
    const [analyticsByAge, setAnalyticsByAge] = React.useState([]);
    const [analyticsByGender, setAnalyticsByGender] = React.useState([]);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const myRef = useRef(null);
    let vStartDate = new moment().add("month", 0).format("YYYY/MM") + "/01";
    let vEndM = moment(vStartDate).add(1, "month").add(-1, "days");
    let vEndDate = moment(vEndM).format("YYYY/MM/DD");

    useEffect(() => {
        getStats();
        myRef.current.scrollIntoView();
    },[])
    useEffect(() => {
        getStats();
    }, [startDate, endDate])

    const backButton = () => {
        props.setPage("2");
    }
    const getStats = () => {
        console.log(props.selectedOffer);
        let StartDate = startDate ? startDate.format("YYYY/MM/DD") : vStartDate;
        let EndDate = endDate ? endDate.format("YYYY/MM/DD") : vEndDate;
        props.getCouponAnalytics(props.partnerId,props.selectedOffer.id,{StartDate, EndDate}).then(res => {
            console.log(res);
            let ageArr = res.analyticsByAgeGroupSummary.analyticsByAgeGroup.map((item) => {
                return [item.legend, item.totalViewed, item.totalSaved, item.totalRedemptions];
            });
            ageArr.push(["", res.analyticsByAgeGroupSummary.totalViewed, res.analyticsByAgeGroupSummary.totalSaved, res.analyticsByAgeGroupSummary.totalRedeemed]);
            setAnalyticsByAge(ageArr);
            let genderArr = res.analyticsByGenderSummary.analyticsGyGender.map((item) => {
                return [item.gender, item.totalViewed, item.totalSaved, item.totalRedemptions];
            });
            genderArr.push(["", res.analyticsByGenderSummary.totalViewed, res.analyticsByGenderSummary.totalSaved, res.analyticsByGenderSummary.totalRedeemed]);
            setAnalyticsByGender(genderArr);
        });
    }
    const dateChange = (date, dateString) => {
        if(date) {
            setStartDate(date[0]);
            setEndDate(date[1]);
        }
        else {
            setStartDate(null);
            setEndDate(null);
        }
    }

    return (
        <div className="content paybill" ref={myRef}>
            <Row>
                <Col>
                <div onClick={backButton} className="back-button-title mb-2">
                    <img className="mb-1" width="14px" src={leftIcon} />
                    Back
                </div>
                <Card>
                    <CardHeader className="paybill-header">
                        <div className="w-full flex justify-between items-center">
                            <div>
                                <p className="ml-2" style={{color:'#4F4E52', fontSize:'16px'}}>Offer analytics</p>
                                <h5 className="title ml-2" style={{fontSize:'22px'}}>{props.selectedOffer.title}</h5>
                            </div>
                            <RangePicker className="mt-2 mr-2"format="YYYY/MM/DD" onChange={dateChange} style={{maxHeight:'44px'}}/>
                        </div>
                    </CardHeader>
                </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <CardHeader className="paybill-header">
                            <div className="w-full pb-6 box-content">
                                <Table striped tableHead={["Age Group", "Total Viewed", "Total Saved", "Total Redeemed by Age Group"]} tableData={analyticsByAge} />
                            </div>
                        </CardHeader>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <CardHeader className="paybill-header">
                            <div className="w-full pb-6 box-content">
                                <Table striped tableHead={["Gender", "Total Viewed", "Total Saved", "Total Redeemed by Gender"]} tableData={analyticsByGender} />
                            </div>
                        </CardHeader>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

function mapStateToProps(state) {
    return state.user;
}

export default connect(mapStateToProps, actionCreators)(CouponAnalytics);