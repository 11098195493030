import axios from "../utils/request";

export function addIntesets(data) {
  return axios({
    url: `/api/v1/Interests`,
    method: "post",
    data,
  });
}
export function addCategory(data) {
  return axios({
    url: `/api/v1/Interests/category`,
    method: "post",
    data,
  });
}
export function getIntesets(data) {
  return axios({
    url: `/api/v1/Interests`,
    method: "get",
    params: data,
  });
}
export function getCategory(data) {
  return axios({
    url: `/api/v1/Interests/grouped-by-category`,
    method: "get",
    params: data,
  });
}
export function deleteInterest(data) {
  return axios({
    url: `/api/v1/Interests/${data.id}`,
    method: "delete",
  });
}
