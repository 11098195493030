import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import Select from "react-select";
// import "../style.css";
import { connect } from "react-redux";
import actionCreators from "../../../../store/actionCreators/login";
import { message } from "antd";

function CreateCategory(props) {
  let [email, setEmail] = React.useState("");
  let [name, setName] = React.useState("");
  let [lastName, setLastName] = React.useState("");

  const emailChange = (e) => {
    setEmail(e.target.value);
  };

  const nameChange = (e) => {
    setName(e.target.value);
  };

  const laseNameChange = (e) => {
    setLastName(e.target.value);
  };

  const continueButton = () => {
    if (name == "") {
      message.error("first name required.");
      return;
    }
    if (lastName == "") {
      message.error("last name required.");
      return;
    }
    if (email == "") {
      message.error("email required.");
      return;
    }
    let obj = { email, name, lastName };
    props.createAccount(obj).then((res) => {
      if (res.userId) {
        message.success("Admin created!");

        setEmail("");
        setName("");
        setLastName("");
      }
    });
  };

  return (
    <>
      <div className="content paybill">
        <Row>
          <Col md="7">
            <Card>
              <CardHeader className="paybill-header">
                <h5 className="title ml-2">Invite Super Admin</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>Email</label>
                        <Input
                          type="email"
                          placeholder="Enter Email"
                          value={email}
                          onChange={emailChange}
                        />
                      </FormGroup>
                      <FormGroup>
                        <label>First Name</label>
                        <Input
                          type="text"
                          placeholder="Enter First Name"
                          value={name}
                          onChange={nameChange}
                        />
                      </FormGroup>
                      <FormGroup>
                        <label>Last Name</label>
                        <Input
                          type="text"
                          placeholder="Enter Last Name"
                          value={lastName}
                          onChange={laseNameChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      md="6"
                      className="footer-buttons d-flex justify-content-start">
                      <Button
                        onClick={continueButton}
                        color="primary"
                        size="md"
                        className="btn-round"
                        disabled={email == "" || name == "" || lastName == ""}>
                        Ok
                      </Button>
                    </Col>
                    <Col md="6"></Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
function mapStateToProps(state) {
  return state.user;
}
export default connect(mapStateToProps, actionCreators)(CreateCategory);
