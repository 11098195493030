import axios from "../utils/request"

export function uploadImage(data) {
    return axios({
        url: `/api/v1/Files/Upload/Image`,
        method: "post",
        headers: {
            'content-type': 'multipart/form-data',
        },
        data,
      })
}
