import axios from "../utils/request";

export function getGroupAdmins(data) {
  return axios({
    url: `/api/v1/Admin/group-admins`,
    method: "get",
    data,
  });
}

export function addGroupAdmin(data) {
  return axios({
    url: `/api/v1/Admin/create-group-admin`,
    method: "post",
    data,
  });
}

export function getGroupAdminDetails(id) {
  return axios({
    url: `/api/v1/Admin/group-admins/${id}`,
    method: "get",
  });
}

export function updateGroupAdmin(data) {
  return axios({
    url: `/api/v1/Admin/group-admin/${data.id}`,
    method: "put",
    data,
  });
}
export function deleteGroupAdmin(data) {
  return axios({
    url: `/api/v1/Admin/${data.id}`,
    method: "delete",
    data,
  });
}

export function getSubscribedAdmins(data) {
  return axios({
    url: `/api/v1/Admin/subscribers`,
    method: "get",
    params: data,
  });
}
