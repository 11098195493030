import axios from "../utils/request";

export function addPartner(data) {
  return axios({
    url: `/api/v1/admin/partners`,
    method: "post",
    data,
  });
}

export function getDealershipPartner(data) {
  return axios({
    url: `/api/v1/Admin/partners/by-type/4`,
    method: "get",
    params: data,
  });
}
export function getDealershipPartnerCount(data) {
  return axios({
    url: `/api/v1/Admin/partners/4/count`,
    method: "get",
    params: data,
  });
}
export function getLifeStylePartner(data) {
  return axios({
    url: `/api/v1/Admin/partners/by-type/5`,
    method: "get",
    params: data,
  });
}
export function getLifeStylePartnerCount(data) {
  return axios({
    url: `/api/v1/Admin/partners/5/count`,
    method: "get",
    params: data,
  });
}

export function getCorporatePartner(data) {
  return axios({
    url: `/api/v1/Admin/partners/by-type/8`,
    method: "get",
    params: data,
  });
}
export function getCorporatePartnerCount(data) {
  return axios({
    url: `/api/v1/Admin/partners/8/count`,
    method: "get",
    params: data,
  });
}

export function updatePartner(data) {
  return axios({
    url: `/api/v1/Admin/partners/${data.id}/status/${data.statusCode}`,
    method: "put",
    data,
  });
}

export function searchPartner({ searchTerm, type }) {
  return axios({
    url: `/api/v1/Admin/partners/search/${searchTerm}`,
    method: "get",
    params: {
      type,
    },
  });
}

export function getPartnerDetails(id) {
  return axios({
    url: `/api/v1/Admin/partners/${id}`,
    method: "get",
  });
}

export function getMemberTiers(data) {
  return axios({
    url: `/api/v1/Partners/member-tiers`,
    method: "get",
    data,
  });
}

export function updatePartnerTier(data) {
  return axios({
    url: `api/v1/Admin/partners/${data.partnerId}/tier/${data.tierId}`,
    method: "put",
    data,
  });
}

export function updateTierStatus(data) {
  return axios({
    url: `/api/v1/Admin/partners/tiers/${data.id}/status/${data.statusCode}`,
    method: "put",
    data,
  });
}

export function upgradeLifestylePartner(id) {
  return axios({
    url: `/api/v1/Partners/lifestyle-partner/${id}/upgrade-enterprise`,
    method: "put",
  });
}

export function getBillingsCalendar(data) {
  return axios({
    url: `/api/v1/Admin/partners/billing-calendar`,
    method: "get",
    params: data,
  });
}

export function updatePartnerStartOfBillingCycle(data) {
  return axios({
    url: `/api/v1/Admin/partners/${data.partnerId}/billing-calendar`,
    method: "put",
    params: {
      billingCycleStart: data.billingCycleStart,
    },
  });
}

export function deleteEnterpriseLocation(data) {
  return axios({
    url: `/api/v1/Partners/lifestyle-partner/${data.partnerId}/locations/${data.locationId}`,
    method: "delete",
  });
}

export function saveEnterpriseLocations(data) {
  return axios({
    url: `/api/v1/Partners/lifestyle-partner/${data.id}/locations`,
    method: "put",
    data: data,
  });
}

export function uploadCsv(data) {
  return axios({
    url: `/api/v1/Files/Upload/Csv`,
    method: "post",
    headers: {
      "content-type": "multipart/form-data",
    },
    data,
  });
}

export function processCsv(data) {
  return axios({
    url: `/api/v1/Partners/lifestyle-partner/${data.id}/locations/csv/${data.fileId}`,
    method: "put",
  });
}

export function getPartnerOffersCount(id, data) {
  return axios({
    url: `/api/v1/admin/analytics/partners/${id}/offers/count`,
    method: "get",
    data,
  });
}

export function getPartnerOffers(id, data) {
  return axios({
    url: `/api/v1/admin/analytics/partners/${id}/offers`,
    method: "get",
    params: data,
  });
}

export function getPartnerQualifyingPurchasesCount(id, data) {
  return axios({
    url: `/api/v1/admin/analytics/partners/${id}/qualifying-purchases/count`,
    method: "get",
    data,
  });
}

export function getPartnerQualifyingPurchases(id, data) {
  return axios({
    url: `/api/v1/admin/analytics/partners/${id}/qualifying-purchases`,
    method: "get",
    params: data,
  });
}

export function getPartnerActiveUsers(data) {
  return axios({
    url: `/api/v1/admin/analytics/active-users/${data.id}`,
    method: "get",
    data,
  });
}

export function unPauseTier(id) {
  return axios({
    url: `/api/v1/ReferralProgram/tiers/${id}/unpause`,
    method: "put",
  });
}

export function getEventsList(data) {
  return axios({
    url: `/api/v1/events`,
    method: "get",
    params: data,
  });
}
