import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";
import actionCreators from "../../../../store/actionCreators/partner.js";
import actionCreators1 from "../../../../store/actionCreators/brand";

import { message, DatePicker, Switch } from "antd";
import moment from "moment";
const ClassifyOptions = [
  { value: 4, label: "Dealership Partner" },
  { value: 5, label: "Business partner" },
  { value: 8, label: "Corporate partner" },
];
const CanOptions = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];
const CountryOptions = [{ value: 1, label: "Canada" }];
const SubscriptionOptions = [
  { value: 1, label: "tier 1" },
  { value: 2, label: "tier 2" },
  { value: 3, label: "tier 3" },
];

function WireTransfer({
  setPage,
  addPartner,
  getProvinces,
  getCities,
  getIntesets,
  getMemberTiers,
}) {
  const [intesetsOptions, setIntesetsOptions] = React.useState([]);
  const [automotiveBrands, setAutomotiveBrands] = React.useState([]);
  const [automotiveBrandsOptions, setAutomotiveBrandsOptions] = React.useState(
    []
  );
  const [interests, setInterests] = React.useState([]);

  const [classifySelect, setClassifySelect] = React.useState(null);
  const [subscriptionOptions, setSubscriptionOptions] = React.useState([]);
  const [subscription, setSubscription] = React.useState(null);
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(null);
  const [country, setCountry] = React.useState(1);
  const [province, setProvince] = React.useState("1");
  const [address, setAddress] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [isEnterpriseLP, setIsEnterpriseLP] = React.useState(false);
  const [disableOffers, setDisableOffers] = React.useState(false);
  const [corporateMembersLimit, setCorporateMembersLimit] = React.useState(0);

  const [city, setCity] = React.useState("");
  const [businessname, setBusinessname] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [contactemail, setContactemail] = React.useState("");
  const [contactemailError, setcontactemailError] = React.useState(null);
  const [reminder, setReminder] = React.useState("");
  const [websitelink, setWebsitelink] = React.useState("");
  const [webError, setWebError] = React.useState(null);
  const [linkservices, setLinkservices] = React.useState("");
  const [linkservicesError, setLinkservicesError] = React.useState(null);
  const [provinceOptions, setProvinceOptions] = React.useState([]);
  const [cityOptions, setCityOptions] = React.useState([]);
  const [maxState, setmaxState] = React.useState("");
  const [billingAmount, setBillingAmount] = React.useState("");
  const [billingCycleStart, setBillingCycleStart] = React.useState(moment());
  const dispatch = useDispatch();

  useEffect(() => {
    getIntesets().then((res) => {
      setIntesetsOptions(res);
    });
    getAutomotiveBrands();
    getMemberTiers().then((res) => {
      let tierOptions = res.map((prop, key) => {
        return {
          value: prop.membersTierId,
          label: prop.tierName,
        };
      });
      setSubscriptionOptions(tierOptions);
    });
    return componentWillUnmount;
  }, []);

  const getAutomotiveBrands = () => {
    dispatch(actionCreators1.getBrand()).then((res) => {
      let arr = res;
      setAutomotiveBrandsOptions(arr);
    });
  };

  const componentWillUnmount = () => {
    message.destroy();
  };

  const continueButton = (e) => {
    e.preventDefault();
    if (
      !classifySelect ||
      !email ||
      (!isEnterpriseLP && !address) ||
      !businessname ||
      !description ||
      !websitelink ||
      !billingAmount ||
      !billingCycleStart ||
      contactemailError ||
      emailError ||
      webError ||
      linkservicesError
    ) {
      message.error({
        content: "Please complete the form!",
        duration: 1,
      });
      return;
    }

    if (maxState === "has-danger") return;
    let partnerObj = {};
    if (
      classifySelect &&
      (classifySelect.value == 4 || classifySelect.value == 8)
    ) {
      if (!contactemail || !phone) {
        message.error({
          content: "Please complete the form2!",
          duration: 1,
        });
        return;
      }
      partnerObj.partnerType = classifySelect?.value;
      partnerObj.canResendInvitation = true;
      partnerObj.cityId = city?.value;
      partnerObj.address = address || "";
      partnerObj.postalCode = postalCode;
      partnerObj.businessName = businessname || "";
      partnerObj.description = description || "";
      partnerObj.phoneNumber = phone || "";
      partnerObj.communicationPhoneNumber = phone || "";
      partnerObj.email = email || "";
      partnerObj.contactEmail = contactemail || "";
      partnerObj.subscription = subscription && subscription.value;
      partnerObj.servicesLink = linkservices || "";
      partnerObj.website = websitelink || "";
      partnerObj.automotiveBrands = automotiveBrands;
      partnerObj.reminder = moment(reminder).format("YYYY/MM/DD");
      partnerObj.billingAmount = parseFloat(billingAmount);
      partnerObj.billingCycleStart =
        moment(billingCycleStart).format("YYYY/MM/DD");
      partnerObj.isEnterprise = false;
    }
    if (classifySelect && classifySelect.value == 5) {
      if (!interests) {
        message.error({
          content: "Please complete the form!",
          duration: 1,
        });
        return;
      }
      partnerObj.partnerType = classifySelect?.value;
      partnerObj.canResendInvitation = true;
      partnerObj.cityId = city?.value;
      partnerObj.address = address || "";
      partnerObj.businessName = businessname || "";
      partnerObj.description = description || "";
      partnerObj.phoneNumber = phone || "";
      partnerObj.communicationPhoneNumber = phone || "";
      partnerObj.email = email || "";
      partnerObj.contactEmail = contactemail || "";
      partnerObj.subscription = subscription && subscription.value;
      partnerObj.servicesLink = linkservices || "";
      partnerObj.website = websitelink || "";
      partnerObj.interests = interests;
      partnerObj.reminder = moment(reminder).format("YYYY/MM/DD");
      partnerObj.billingAmount = parseFloat(billingAmount);
      partnerObj.billingCycleStart =
        moment(billingCycleStart).format("YYYY/MM/DD");
      partnerObj.isEnterprise = isEnterpriseLP;
    }
    if (classifySelect.value == 8) {
      partnerObj.disableOffers = disableOffers;
      partnerObj.corporateMembersLimit = JSON.parse(corporateMembersLimit);
    }
    //console.log(partnerObj);
    addPartner(partnerObj).then((res) => {
      if (res) {
        message.success({
          content: "create partner success!",
          duration: 1,
        });
        setInterests(null);
        setClassifySelect(null);
        setSubscription(null);
        setAddress("");
        setEmail("");
        setCity("");
        setBusinessname("");
        setDescription("");
        setPhone("");
        setContactemail("");
        setReminder("");
        setWebsitelink("");
        setLinkservices("");
        setBillingAmount("");
        setBillingCycleStart("");
      }
    });
    // window.location.href = "/admin/wire-transfer/confirm";
  };
  const countryChange = (value) => {
    setCountry(value);
    getProvinces(value.value).then((res) => {
      // console.log(res);
      let arr = res.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      setProvinceOptions(arr);
    });
  };

  const provinceChange = (value) => {
    setProvince(value);
    getCities(value.value).then((res) => {
      // console.log(res);
      let arr = res.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      setCityOptions(arr);
    });
  };

  const cityChange = (value) => {
    setCity(value);
  };

  const CheckBrandHandler = (e) => {
    const value = e.target.value;
    console.log(value);
    setAutomotiveBrands((prev) =>
      automotiveBrands.includes(value)
        ? prev.filter((cur) => cur !== value)
        : [...prev, e.target.value]
    );
  };

  const CheckInterestsHandler = (e) => {
    const value = e.target.value;
    console.log(value);
    setInterests((prev) =>
      interests.includes(value)
        ? prev.filter((cur) => cur !== value)
        : [...prev, e.target.value]
    );
  };

  const checkvalidEmail = (value) => {
    if (!value) {
      setEmailError("Email is required");
    } else if (!value || value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) == null) {
      setEmailError("Email format error");
    } else {
      setEmailError(null);
    }
  };
  const checkvalidContactEmail = (value) => {
    if (value && value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) == null) {
      setcontactemailError("Email format error");
    } else {
      setcontactemailError(null);
    }
  };
  const checkvalidWeb = (value) => {
    if (
      value &&
      value.match(
        /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/
      ) == null
    ) {
      setWebError("URL format error!");
    } else {
      setWebError(null);
    }
  };
  const checkvalidLinkservices = (value) => {
    if (
      value &&
      value.match(
        /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/
      ) == null
    ) {
      setLinkservicesError("URL format error!");
    } else {
      setLinkservicesError(null);
    }
  };
  const verifyNumber = (value) => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="content paybill">
        <Row>
          <Col md="7">
            <Card>
              <CardHeader className="paybill-header">
                <h5 className="title ml-2">Add New Partner</h5>
              </CardHeader>
              <CardBody>
                <Form onSubmit={continueButton}>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>
                          Classify <span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          placeholder="Single Select"
                          name="singleSelect"
                          value={classifySelect}
                          options={ClassifyOptions}
                          onChange={(value) => {
                            setClassifySelect(value);
                            setIsEnterpriseLP(false);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="" md="6">
                      <FormGroup>
                        <label>
                          Registered Email{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          placeholder="Email"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            checkvalidEmail(e.target.value);
                          }}
                        />
                        {emailError ? (
                          <label className="error" style={{ color: "red" }}>
                            {emailError}
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>

                  {classifySelect?.value === 8 && (
                    <>
                      <Row>
                        <Col className="" md="6">
                          <FormGroup>
                            <label>
                              Member Limit{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <br />
                            <Input
                              required
                              type="number"
                              min={0}
                              placeholder="Maximum number of members allowed"
                              value={corporateMembersLimit}
                              onChange={(e) => {
                                setCorporateMembersLimit(e.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col className="" md="6">
                          <FormGroup>
                            <label>Disable Offers </label>
                            <div className="mt-2">
                              <Switch
                                checked={disableOffers}
                                onChange={(e) => {
                                  setDisableOffers(!disableOffers);
                                }}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  )}

                  {classifySelect && classifySelect.value == 5 && (
                    <Row>
                      <Col className="" md="12">
                        <FormGroup>
                          <label>
                            Enterprise-level
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div style={{ margin: "0px 20px" }}>
                            <Input
                              type="checkbox"
                              checked={isEnterpriseLP}
                              onChange={(e) => {
                                setIsEnterpriseLP(!isEnterpriseLP);
                              }}
                            />
                          </div>
                          <div
                            style={{ margin: "0px 20px", fontSize: "0.8em" }}>
                            By marking a Business Partner as Enterprise, it will
                            allow them to add multiple locations of their
                            business. Once Enterprise is selected, this feature
                            cant be reverted.
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  {isEnterpriseLP == false && (
                    <>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>
                              Unit number and Street name{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <Input
                              placeholder="Address"
                              value={address}
                              onChange={(e) => {
                                setAddress(e.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col className="" md="6">
                          <FormGroup>
                            <label>Country</label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              placeholder="Single Select"
                              name="singleSelect"
                              value={country}
                              options={CountryOptions}
                              onChange={countryChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Province</label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              placeholder="Single Select"
                              name="singleSelect"
                              value={province}
                              isDisabled={
                                provinceOptions.length > 0 ? false : true
                              }
                              options={provinceOptions}
                              onChange={provinceChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col className="" md="6">
                          <FormGroup>
                            <label>City</label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              placeholder="Single Select"
                              name="singleSelect"
                              isDisabled={cityOptions.length > 0 ? false : true}
                              value={city}
                              options={cityOptions}
                              onChange={cityChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {/* //POstal */}
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>
                              Postal Code
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <Input
                              required
                              pattern="^[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]{1}\d{1}[A-Za-z]{1} *\d{1}[A-Za-z]{1}\d{1}$"
                              placeholder="Postal Code"
                              value={postalCode}
                              onChange={(e) => {
                                setPostalCode(e.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  )}

                  {classifySelect?.value === 4 && (
                    <Row>
                      <Col className="" md="12">
                        <label>Automotive Brands</label>
                        <div className="flex flex-wrap sm:items-center">
                          {automotiveBrandsOptions.map((item) => {
                            return (
                              <div className="flex items-center" key={item.id}>
                                <input
                                  onClick={CheckBrandHandler}
                                  value={item.id}
                                  name="roles-filter"
                                  type="checkbox"
                                  className="h-5 w-5 border-gray-300  focus:ring-primaryLight text-primaryLight rounded-md"
                                />
                                <label className="ml-2 mt-1 block text-md font-medium leading-6 text-gray-900">
                                  {item.name}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col className="mt-4" md={isEnterpriseLP ? "12" : "6"}>
                      <FormGroup>
                        <label>
                          Business name <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          placeholder="Business name"
                          value={businessname}
                          onChange={(e) => {
                            setBusinessname(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="" md="12">
                      <FormGroup>
                        <label>
                          Business description{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          placeholder="Business description"
                          type="text"
                          value={description}
                          onChange={(e) => {
                            setDescription(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {classifySelect &&
                  (classifySelect.value == 4 || classifySelect.value == 8) ? (
                    <Row>
                      <Col className="" md="6">
                        <FormGroup>
                          <label>
                            Phone number
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <Input
                            placeholder="Phone number"
                            type="tel"
                            maxLength={10}
                            value={phone}
                            onChange={(e) => {
                              if (
                                !(
                                  verifyNumber(e.target.value) &&
                                  e.target.value.length == 10
                                )
                              ) {
                                setmaxState("has-danger");
                              } else {
                                setmaxState("has-success");
                              }
                              setPhone(e.target.value);
                            }}
                          />
                          {maxState === "has-danger" ? (
                            <label className="error" style={{ color: "red" }}>
                              The phone can only be a number and is 10
                              characters.
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col className="" md="6">
                        <FormGroup>
                          <label>
                            Contact email
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <Input
                            placeholder="Contact email "
                            value={contactemail}
                            onChange={(e) => {
                              setContactemail(e.target.value);
                              checkvalidContactEmail(e.target.value);
                            }}
                          />
                          {contactemailError ? (
                            <label className="error" style={{ color: "red" }}>
                              {contactemailError}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  <Row>
                    {/* <Col className="" md="6">
                      <FormGroup>
                        <label>Exclusive Member Cap (only Dealership Partner) <span style={{color:'red'}}>*</span></label>
                        <Input placeholder="Exclusive Member Cap " type="number" value={memberCap} onChange={(e)=>{ setMemberCap(e.target.value)}}/>
                      </FormGroup>
                    </Col> */}
                    {classifySelect &&
                    (classifySelect.value == 4 || classifySelect.value == 8) ? (
                      <Col className="" md="6">
                        <FormGroup>
                          <label>
                            Subscription <span style={{ color: "red" }}>*</span>
                          </label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            placeholder="Select a tier"
                            name="singleSelect"
                            value={subscription}
                            options={subscriptionOptions}
                            onChange={(value) => setSubscription(value)}
                          />
                        </FormGroup>
                      </Col>
                    ) : (
                      ""
                    )}
                    <Col className="" md="6">
                      <FormGroup>
                        <label>
                          Billing Amount <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          type="number"
                          placeholder="Billing amount"
                          value={billingAmount}
                          onChange={(e) => {
                            setBillingAmount(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>{" "}
                    <Col className="" md="6">
                      <FormGroup>
                        <label>
                          Billing Date (Start of Billing Cycle){" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <DatePicker
                          defaultValue={moment()}
                          disabledDate={(current) =>
                            current.isBefore(moment().subtract(1, "day"))
                          }
                          style={{ width: "100%", height: "36px" }}
                          value={billingCycleStart}
                          onChange={(date, dateString) => {
                            setBillingCycleStart(date);
                          }}
                        />
                        <label className="" style={{ fontSize: "9px" }}>
                          *Partner will be billed every year from this date.
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="" md="12">
                      <FormGroup>
                        <label>
                          Reminder Date<span style={{ color: "red" }}>*</span>
                        </label>{" "}
                        <br />
                        <i style={{ fontSize: "12px" }}>
                          The start date for receiving a reminder email with
                          details of the subscription. Reminder emails will
                          continue to be sent out every year after this date.
                        </i>
                        {/* <Input
                          placeholder="Set a reminder for themselves "
                          value={reminder}
                          onChange={(e) => {
                            setReminder(e.target.value)
                          }}
                        /> */}
                        <DatePicker
                          style={{ width: "100%", height: "36px" }}
                          value={reminder}
                          onChange={(date, dateString) => {
                            setReminder(date);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="" md="6">
                      <FormGroup>
                        <label>
                          Website link <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          placeholder="Website link "
                          value={websitelink}
                          onChange={(e) => {
                            setWebsitelink(e.target.value);
                            checkvalidWeb(e.target.value);
                          }}
                        />
                        {webError ? (
                          <label className="error" style={{ color: "red" }}>
                            {webError}
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    {/* <Col className="" md="6">
                      <FormGroup>
                        <label>Interests (Only for LifeStyle partners) </label>
                        <Input placeholder="Interests" value={interests} onChange={(e)=>{ setInterests(e.target.value)}}/>
                      </FormGroup>
                    </Col> */}
                    {classifySelect &&
                    (classifySelect.value == 4 || classifySelect.value == 8) ? (
                      <Col className="" md="6">
                        <FormGroup>
                          <label>Link with services</label>
                          <Input
                            placeholder="Link with services provided by Dealership Partner"
                            value={linkservices}
                            onChange={(e) => {
                              setLinkservices(e.target.value);
                              checkvalidLinkservices(e.target.value);
                            }}
                          />
                          {linkservicesError ? (
                            <label className="error" style={{ color: "red" }}>
                              {linkservicesError}
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                    ) : (
                      ""
                    )}

                    {classifySelect && classifySelect.value == 5 ? (
                      <Row>
                        <Col md="">
                          <label className="mx-3">
                            Interests(only Business partner){" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div
                            style={{ justifyContent: "start" }}
                            className="flex flex-wrap my-2">
                            {intesetsOptions.map((item) => {
                              return (
                                <div className="flex ml-3" key={item.id}>
                                  <input
                                    onClick={CheckInterestsHandler}
                                    value={item.id}
                                    name="roles-filter"
                                    type="checkbox"
                                    className="h-5 w-5 border-gray-300 ml-2 focus:ring-primaryLight text-primaryLight rounded-md"
                                  />
                                  <label className="mx-1 mt-1 block text-md font-medium leading-6 text-gray-900">
                                    {item.name}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </Row>

                  <Row>
                    <Col
                      md="6"
                      className="footer-buttons d-flex justify-content-start">
                      {/* <Button
                        onClick={() => setPage("2")}
                        size="md"
                        className="btn-round mr-2 cancel-button">
                        Cancel
                      </Button> */}
                      <Button
                        color="primary"
                        size="md"
                        className="btn-round"
                        type="submit"
                        // onClick={continueButton}
                      >
                        Send Invite
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
function mapStateToProps(state) {
  return state.user;
}
export default connect(mapStateToProps, actionCreators)(WireTransfer);
