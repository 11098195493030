import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
} from "reactstrap";
// core components

const thead = ["ID", "Name", "Amount", "Status"];
const tbody = [
  {
    className: "table-success",
    data: ["Dakota Rice", "Niger", "Oud-Turnhout", "(123)-456-7895"],
  },
  {
    className: "",
    data: ["Minerva Hooper", "Curaçao", "Sinaai-Waas", "(123)-456-7895"],
  },
  {
    className: "table-info",
    data: ["Sage Rodriguez", "Netherlands", "Baileux", "(123)-456-7895"],
  },
  {
    className: "",
    data: ["Philip Chaney", "Korea, South", "Overland Park", "(123)-456-7895"],
  },
  {
    className: "table-danger",
    data: [
      "Doris Greene",
      "Malawi",
      "Feldkirchen in Kärnten",
      "(123)-456-7895",
    ],
  },
  {
    className: "",
    data: ["Mason Porter", "Chile", "Gloucester", "(123)-456-7895"],
  },
  {
    className: "",
    data: ["Jon Porter", "Portugal", "Gloucester", "(123)-456-7895"],
  },
];

function IdentificationHistory() {
  return (
    <>
      <Row>
        <Col lg={7} md={12} sm={12}>
          <Card>
            <CardHeader>
              <CardTitle tag="h5">
                <div>Donations</div>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Table responsive>
                <thead className="users-table-head">
                  <tr>
                    {thead.map((prop, key) => {
                      if (key === thead.length - 1)
                        return (
                          <th key={key} className="">
                            {prop}
                          </th>
                        );
                      return <th key={key}>{prop}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>

                    <td>Mark Hammil</td>

                    <td>5</td>

                    <td className="text-success">Paid</td>
                  </tr>
                  <tr>
                    <td>1</td>

                    <td>Mark Hammil</td>

                    <td>50</td>

                    <td className="text-warning">Pending</td>
                  </tr>
                  <tr>
                    <td>1</td>

                    <td>Mark Hammil</td>

                    <td>100</td>

                    <td className="text-danger">Cancelled</td>
                  </tr>
                  <tr>
                    <td>1</td>

                    <td>Mark Hammil</td>

                    <td>Canada Post</td>

                    <td className="text-success">Paid</td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
        {/*  */}
      </Row>
    </>
  );
}

export default IdentificationHistory;
