import React, { useEffect } from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
} from "reactstrap";
// import "./style.css";
import CreateOffer from "./Create";
import ReactTable from "components/ReactTable/ReactTable.js";
import actionCreators from "../../../../store/actionCreators/brand";
import { connect } from "react-redux";
import { message } from "antd";

function Brand(props) {
  const [newContactModal, setNewContactModal] = React.useState(false);
  const [page, setPage] = React.useState("1");
  const [add, setAdd] = React.useState(false);
  const [item, setItem] = React.useState("");
  useEffect(() => {
    getList();
  }, [page]);
  const delItem = (id) => {
    props.delBrand(id).then((res) => {
      message.success("Brand deleted");
      getList();
    });
  };
  const getList = () => {
    props.getBrand().then((res) => {
      let arr = res;
      let dataState = arr.map((prop, key) => {
        return {
          id: key,
          brand: prop.name,
          // img: prop.logo,
          time: prop.createdAt,
          actions: (
            // we've added some custom button actions
            <div key={key} className="actions-left">
              {/* use this button to add a edit kind of action */}
              <Button
                onClick={() => {
                  setAdd(false);
                  setItem(prop);
                  setPage("2");
                }}
                className="btn-icon btn-round"
                color="warning"
                size="sm">
                <i className="fa fa-edit" />
              </Button>{" "}
            </div>
          ),
          delActions: (
            <div key={key} className="actions-right">
              <Button
                className="btn-icon btn-round"
                color="warning"
                onClick={() => {
                  delItem(prop.id);
                }}
                size="sm">
                <i className="fa fa-trash" />
              </Button>
            </div>
          ),
        };
      });
      // console.log(dataState);
      setDataState(dataState);
    });
  };
  const [dataState, setDataState] = React.useState([]);
  return (
    <>
      {page === "1" ? (
        <div className="content users">
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">
                    <div>Automotive Brands</div>
                  </CardTitle>
                  <div className="float-right">
                    <Button
                      onClick={() => {
                        setAdd(true);
                        setPage("2");
                        setItem("");
                      }}
                      color="secondary"
                      size="md"
                      className="btn-round mr-2">
                      <i className="fa fa-plus mr-2" /> Create New Brand
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    showPagination
                    data={dataState}
                    columns={[
                      {
                        Header: "Brand Name",
                        accessor: "brand",
                        sortable: false,
                      },
                      // {
                      //   Header: "Image",
                      //   accessor: "img",
                      //   Cell: (tableProps) => (
                      //     <img
                      //       src={tableProps.row.original.img}
                      //       width={50}
                      //       alt="Picture"
                      //     />
                      //   ),
                      // },
                      {
                        Header: "Created On",
                        accessor: "time",
                        sortable: false,
                      },
                      // {
                      //   Header: "View",
                      //   accessor: "actions",
                      //   sortable: false,
                      //   filterable: false,
                      // },
                      {
                        Header: "Delete",
                        accessor: "delActions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      ) : page === "2" ? (
        <CreateOffer setPage={setPage} add={add} item={item} />
      ) : (
        ""
      )}
    </>
  );
}
function mapStateToProps(state) {
  return state.user;
}
export default connect(mapStateToProps, actionCreators)(Brand);
