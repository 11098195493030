import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js"
import Footer from "components/Footer/Footer.js"
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js"
import { withRouter } from "react-router-dom"
import routes from "routes.js"
import actionCreators from "../store/actionCreators/login"
import { connect } from "react-redux"
import LOGIN_TYPES from "../typings/login-types"
function Auth(props) {
  const [filterColor, setFilterColor] = React.useState("yellow")
  React.useEffect(() => {
    props.validate()
  }, [])

  let authUrl = ["/auth/createPassword"]

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views)
      }
      if (prop.layout === "/auth") {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />
      } else {
        return null
      }
    })
  }
  let content
  let openFlag =
    props.isLogin === LOGIN_TYPES.LOGIN_ED ||
    props.location.pathname == "/auth/login-page" ||
    (authUrl.some((item) => item == props.location.pathname) && props.isLogin != LOGIN_TYPES.LOGIN_ED)
  if (props.isLogin === LOGIN_TYPES.UN_VALIDATE) {
    content = null
  } else if (props.isLogin === LOGIN_TYPES.LOGIN_ED && props.location.pathname == "/auth/login-page") {
    content = <Redirect to="/" />
  } else if (openFlag) {
    content = (
      <div className="wrapper wrapper-full-page">
        <div className="full-page section-image" filter-color={filterColor}>
          <Switch>
            {getRoutes(routes)}
            <Redirect from="/auth" to="/auth/login-page" />
          </Switch>
        </div>
      </div>
    )
  } else if (props.isLogin === LOGIN_TYPES.UN_LOGIN && props.location.pathname == "/auth/reset-password-page") {
    content = (
      <div className="wrapper wrapper-full-page">
        <div className="full-page section-image" filter-color={filterColor}>
          <Switch>
            {getRoutes(routes)}
            <Redirect from="/auth" to="/auth/reset-password-page" />
          </Switch>
        </div>
      </div>
    )
  } else if (props.isLogin === LOGIN_TYPES.UN_LOGIN) {
    content = <Redirect to="/auth/login-page" />
  }

  return content
}
function mapStateToProps(state) {
  return state.user
}
export default connect(mapStateToProps, actionCreators)(Auth)
