import * as actionTypes from "../action-types";
import {
  getGroupAdmins,
  addGroupAdmin,
  getGroupAdminDetails,
  updateGroupAdmin,
  deleteGroupAdmin,
  getSubscribedAdmins,
} from "../../api/admin";
import { getDealershipPartner } from "../../api/partner";

const actionCreators = {
  getGroupAdmins(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await getGroupAdmins(data);
          resolve(res);
        } catch (error) {
          console.log(error);
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },

  getGroupAdminDetails(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await getGroupAdminDetails(data);
          resolve(res);
        } catch (error) {
          console.log(error);
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },

  getAllDealerships(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await getDealershipPartner(data);
          resolve(res);
        } catch (error) {
          console.log(error);
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },

  addGroupAdmin(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await addGroupAdmin(data);
          resolve(res);
        } catch (error) {
          console.log(error);
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },

  updateGroupAdmin(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await updateGroupAdmin(data);
          resolve(res);
        } catch (error) {
          console.log(error);
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },

  deleteGroupAdmin(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await deleteGroupAdmin(data);
          resolve(res);
        } catch (error) {
          console.log(error);
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
  getSubscribedAdmins(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await getSubscribedAdmins(data);
          resolve(res);
        } catch (error) {
          console.log(error);
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
};
export default actionCreators;
