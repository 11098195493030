import axios from "../utils/request"

export function updateTermsOfService(data) {
  return axios({
    url: `/api/v1/Platform/termsOfService`,
    method: "post",
    data,
  })
}

export function getTermsOfService(data) {
  return axios({
    url: `/api/v1/Platform/termsOfService`,
    method: "get",
    params:data,
  })
}
export function updatePrivacyPolicy(data) {
    return axios({
      url: `/api/v1/Platform/privacyPolicy`,
      method: "post",
      data,
    })
  }
  
  export function getPrivacyPolicy(data) {
    return axios({
      url: `/api/v1/Platform/privacyPolicy`,
      method: "get",
      params:data,
    })
  }
  export function updateAbout(data) {
    return axios({
      url: `/api/v1/Platform/about`,
      method: "post",
      data,
    })
  }
  
  export function getAbout(data) {
    return axios({
      url: `/api/v1/Platform/about`,
      method: "get",
      params:data,
    })
  }

