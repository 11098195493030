import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import Select from "react-select";
// import "../style.css";
import ImageUpload from "./CustomUpload/ImageUpload.js";
import { connect } from "react-redux";
import actionCreators from "../../../../../store/actionCreators/brand";
import { message } from "antd";
function CreateCategory({ setPage, addBrand, add, item }) {
  const [name, setName] = React.useState("");
  const [imagesId, setImagesId] = React.useState("");
  // if(item){
  //   setName(item.name)
  // }else{
  //   setName('')
  // }
  const continueButton = () => {
    if (!name) {
      message.error("Please enter brand name!");
      return;
    }
    // if (!imagesId) {
    //   message.error("Please upload pictures!");
    //   return;
    // }
    let obj = {
      name,
      // logo: imagesId,
    };
    addBrand(obj).then((res) => {
      message.success("Successfully created");
      setPage("1");
    });
    // window.location.href = "/admin/move-money/confirm";
  };
  let uploadSuccess = (res) => {
    //  console.log(res);
    setImagesId(res.id);
  };
  return (
    <>
      <div className="content paybill">
        <Row>
          <Col md="7">
            <Card>
              <CardHeader className="paybill-header">
                <h5 className="title ml-2">Create Automotive Brand</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <Input
                          placeholder="Automotive Brand Name"
                          value={item.name || name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col className="pr-1" md="6">
                      <div>
                        {" "}
                        <label>Image Upload</label>
                      </div>
                      {add ? (
                        <ImageUpload
                          addButtonProps={{
                            color: "rose",
                            round: true,
                          }}
                          changeButtonProps={{
                            color: "rose",
                            round: true,
                          }}
                          removeButtonProps={{
                            color: "danger",
                            round: true,
                          }}
                          uploadSuccess={uploadSuccess}
                        />
                      ) : (
                        <img src={item.logo} alt="" />
                      )}
                    </Col>
                  </Row> */}
                  <Row>
                    <Col
                      md="12"
                      className="footer-buttons d-flex justify-content-start">
                      <Button
                        onClick={() => setPage("1")}
                        color="secondary"
                        size="md"
                        className="btn-round mr-2 cancel-button">
                        Cancel{" "}
                      </Button>

                      <Button
                        onClick={continueButton}
                        color="primary"
                        size="md"
                        className="btn-round">
                        Ok
                      </Button>
                    </Col>
                    <Col md="6"></Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
function mapStateToProps(state) {
  return state.user;
}
export default connect(mapStateToProps, actionCreators)(CreateCategory);
