import React from "react";
import cookie from 'react-cookie';
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardTitle,
  Container,
  Col,
  FormGroup,
  Input,
  Button,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { Checkbox } from 'antd';
// core components
import logo from "assets/img/ER Logo Black.png";
import "../style.css";
import bgImage from "assets/img/IMG_Main_car.png";
import { connect } from "react-redux"
import actionCreators from "../../../../store/actionCreators/login"
import { message } from "antd"

function LoginPage(props) {
  let [isRemember,setIsRemember] = React.useState(true)
  let [password,setPassword] = React.useState("")
  let [password2,setPassword2] = React.useState("")
  React.useEffect(() => {
    document.body.classList.add("lock-page");
    return function cleanup() {
      document.body.classList.remove("lock-page");
    };
  }, []);

 
  const passwordChange = (e)=>{
    setPassword(e.target.value)
  }
  const passwordChange2 = (e)=>{
    setPassword2(e.target.value)
  }
  const login = ()=>{
    // to="/admin/dashboard"  
    // console.log(props);
    let passwordObj = {
      email: props.userProfile.email,
      password: password,
      passwordConfirmation: password2,
      changePasswordToken:props.userProfile.changePasswordToken || ''
    }
     //console.log(passwordObj);
    props.changePassword(passwordObj).then((res) => {
      message.success("Password changed!")
      props.history.push('/auth/login-page')
    })
  }
  return (
    <>
      <div className="content">
        <div className="lock-page">
          <Container>
            <Col md={8} xs={12} className="mr-auto ml-auto">
              <Card className="card-lock text-center">
                <CardHeader>
                <img style={{width:'60px'}} src={logo} alt="ER-logo" />
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label style={{ float: "left" }}>Password</Label>
                    <Input type="password" placeholder="Enter Password..." value={password} onChange={passwordChange}/>
                  </FormGroup>
                  <FormGroup>
                    <Label style={{ float: "left" }}>Confirmation Password</Label>
                    <Input type="password" placeholder="Enter Password..." value={password2} onChange={passwordChange2}/>
                  </FormGroup>
                </CardBody>


                <CardFooter>
                  <Link onClick={()=>{login()}}>
                    <button className="login-button">save</button>
                  </Link>
                </CardFooter>
              </Card>
            </Col>

          </Container>
        </div>
      </div>
      <div
        className="full-page-background"
        style={{ backgroundImage: "url(" + bgImage + ")" }}
      />
    </>
  );
}

function mapStateToProps(state) {
  return state.user
}

export default connect(mapStateToProps, actionCreators)(LoginPage);
