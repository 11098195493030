import React, { useEffect } from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
// import "./style.css";
import FeedbackDetail from "./FeedbackDetail.jsx";
import ReactTable from "components/ReactTable/ReactTable.js";
import actionCreators from "../../../../store/actionCreators/feedback";
import { connect } from "react-redux";
import { message, Pagination } from "antd";
import { useState } from "react";

function Feedback(props) {
  const [dataState, setDataState] = React.useState([]);
  const [page, setPage] = React.useState("1");
  const [add, setAdd] = React.useState(false);
  const [item, setItem] = React.useState("");
  const [keyword, setKeyword] = React.useState("");
  const [pageNumber, setPageNumber] = React.useState(1);
  const [itemsOnPage, setItemsOnPage] = React.useState(10);
  const [totalItems, setTotalItems] = React.useState("");

  useEffect(() => {
    getList(pageNumber - 1);
  }, [page]);
  const updateItem = (id) => {
    props.updateFeedback(id).then((res) => {
      message.success("Update success!");
      getList(pageNumber - 1);
    });
  };

  useEffect(() => {
    if (!keyword) setItem(null);
    updateListBySearch(keyword);
  }, [keyword]);

  const updateListBySearch = (keyword) => {
    if (!keyword) {
      getList();
      return;
    }
    props.searchFeedback(keyword).then((res) => {
      let arr = res;
      setItemsOnPage(999);
      let dataState = arr.map((item, key) => {
        return {
          id: key,
          offers: item.submittedBy,
          CreateTime: item.date,
          category: item.category.name,
          status: item.acknowledged ? "Acknowledged " : "Not Acknowledged",
          actions: (
            // we've added some custom button actions
            <div key={key} className="actions-left">
              {/* use this button to add a edit kind of action */}
              <Button
                onClick={() => {
                  setItem(item);
                }}
                className="btn-icon btn-round"
                color="warning"
                size="sm">
                <i className="fas fa-eye" />
              </Button>{" "}
            </div>
          ),
          acknowledge: (
            <div key={key} className="actions-right">
              {!item.acknowledged && (
                <Button
                  className="btn-icon btn-round"
                  color="warning"
                  onClick={() => {
                    updateItem(item.id);
                  }}
                  size="sm">
                  <i className="fa fa-edit" />
                </Button>
              )}
            </div>
          ),
        };
      });
      // console.log(dataState);
      setDataState(dataState);
    });
  };

  const getList = (page) => {
    props.getFeedbackCount().then(({ totalItems }) => {
      setTotalItems(totalItems);
      setItemsOnPage(10);
      props.getFeedback({ pageNumber: page, itemsOnPage }).then((res) => {
        let arr = res;
        let dataState = arr.map((item, key) => {
          return {
            id: key,
            offers: item.submittedBy,
            CreateTime: new Date(item.date).toLocaleString("en-US", {
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            }),
            category: item.category.name,
            status: item.acknowledged ? "Acknowledged " : "Not Acknowledged",
            actions: (
              // we've added some custom button actions
              <div key={key} className="actions-left">
                {/* use this button to add a edit kind of action */}
                <Button
                  onClick={() => {
                    setAdd(false);
                    setItem(item);
                    setPage("2");
                  }}
                  className="btn-icon btn-round"
                  color="warning"
                  size="sm">
                  <i className="fas fa-eye" />
                </Button>{" "}
              </div>
            ),
            acknowledge: (
              <div key={key} className="actions-right">
                {!item.acknowledged && (
                  <Button
                    className="btn-icon btn-round"
                    color="warning"
                    onClick={() => {
                      updateItem(item.id);
                    }}
                    size="sm">
                    <i className="fa fa-edit" />
                  </Button>
                )}
              </div>
            ),
          };
        });
        // console.log(dataState);
        setDataState(dataState);
      });
    });
  };
  const onShowSizeChange = (page, pageSize) => {
    setPageNumber(page);
    getList(page - 1);
  };
  return (
    <>
      <div className="content users">
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h5">
                  <div>View Feedback</div>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col className="" md="12">
                    <FormGroup>
                      <Input
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                        placeholder="Search by feedback"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <ReactTable
                  data={dataState}
                  columns={[
                    {
                      Header: "Submitted by",
                      accessor: "offers",
                      sortable: false,
                    },
                    {
                      Header: "Received on",
                      sortable: false,
                      accessor: "CreateTime",
                    },
                    {
                      Header: "Category",
                      accessor: "category",
                      sortable: false,
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                      sortable: false,
                    },
                    {
                      Header: "View",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      Header: "Acknowledge",
                      accessor: "acknowledge",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                />
                <Row>
                  <Col md="12">
                    <div className="erPagination">
                      <Pagination
                        showSizeChanger={false}
                        current={pageNumber}
                        defaultPageSize={itemsOnPage}
                        pageSize={itemsOnPage}
                        total={totalItems}
                        onChange={onShowSizeChange}
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          {item && (
            <FeedbackDetail
              onCloseFeedback={() => setItem(null)}
              feedback={item}
            />
          )}
        </Row>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return state.user;
}
export default connect(mapStateToProps, actionCreators)(Feedback);
