import React from "react"
import cookie from "react-cookie"
// reactstrap components
import { connect } from "react-redux"
import actionCreators from "../../../../store/actionCreators/login"
import { Card, CardBody, CardHeader, CardFooter, CardTitle, Container, Col, FormGroup, Input, Button, Label } from "reactstrap"
import { Link } from "react-router-dom"
import { Checkbox } from "antd"
// core components
import logo from "assets/img/ER Logo Black.png"
import "../style.css"
import bgImage from "assets/img/IMG_Main_car.png"
import { message } from "antd"
function LoginPage(props) {
  let [isRemember, setIsRemember] = React.useState(true)
  let [email, setEmail] = React.useState(cookie.load("email") || "")
  let [password, setPassword] = React.useState(cookie.load("password") || "")
  React.useEffect(() => {
    document.body.classList.add("lock-page")
    return function cleanup() {
      document.body.classList.remove("lock-page")
    }
  }, [])
  const onChange = (e) => {
    // console.log(`checked = ${e.target.checked}`);
    if (e.target.checked) {
      setIsRemember(true)
    } else {
      setIsRemember(false)
    }
  }
  const emailChange = (e) => {
    setEmail(e.target.value)
  }
  const passwordChange = (e) => {
    setPassword(e.target.value)
  }
  const login = () => {
    // to="/admin/dashboard"
    // console.log(props);
    if (isRemember === true) {
      let loginData = {}
      loginData.email = email
      loginData.password = password
      let d = new Date()
      d.setDate(d.getDate() + 30)
      cookie.save("email", loginData.email, { expires: d })
      cookie.save("password", loginData.password, { expires: d })
    } else {
      // console.log(11111);
      cookie.remove("email")
      cookie.remove("password")
    }
    let userObj = { email, password }
    props.getLogin(userObj).then((res) => {
      //console.log(res);
      // message.success("Login success!",3)
      if (res.requiresChangePassword) {
        props.history.push("/auth/createPassword")
        // props.history.push('/auth/tos')
      } else {
        props.history.push("/admin/dashboard")
      }
    })
  }
  const handleForgetPassword = () => {
    //send forget password request and redirect to verify page
    if (email == "" || email == null) {
      message.error("please enter your email")
      return
    }
    props
      .forgetPassword({ email })
      .then((res) => {
        message.success("Email has been sent to you.")
        props.history.push("/auth/reset-password-page")
      })
      .catch((e) => {
        message.error("Some error occurs, please try again later.")
      })
  }

  return (
    <>
      <div className="content">
        <div className="lock-page">
          <Container>
            <Col md={8} xs={12} className="mr-auto ml-auto ">
              <Card className="card-lock text-center">
                <CardHeader>
                  <img style={{ width: "60px" }} src={logo} alt="ER-logo" />
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <CardTitle tag="h4">Log in</CardTitle>
                    <Label style={{ float: "left" }}>Email</Label>
                    <Input type="email" placeholder="Enter email" value={email} onChange={emailChange} />
                  </FormGroup>
                  <FormGroup>
                    <Label style={{ float: "left" }}>Password</Label>
                    <Input type="password" placeholder="Enter Password..." value={password} onChange={passwordChange} />
                  </FormGroup>
                  <FormGroup>
                    <div className="flex">
                      <Checkbox onChange={onChange} defaultChecked>
                        remember me
                      </Checkbox>
                      <button type="button" className="btn btn-outline-primary" onClick={handleForgetPassword}>
                      Forgot Password
                      </button>
                    </div>
                  </FormGroup>
                </CardBody>

                <CardFooter>
                  <button
                    className="login-button"
                    onClick={() => {
                      login()
                    }}>
                    Login
                  </button>
                </CardFooter>
              </Card>
            </Col>
          </Container>
        </div>
      </div>
      <div className="full-page-background" style={{ backgroundImage: "url(" + bgImage + ")" }} />
    </>
  )
}
function mapStateToProps(state) {
  return state.user
}
export default connect(mapStateToProps, actionCreators)(LoginPage)
