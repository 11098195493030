import { Modal } from "antd";
import React from "react";

function User({ feedback, onCloseFeedback }) {
  return (
    <>
      <Modal
        onCancel={onCloseFeedback}
        footer={[]}
        isOpen={true}
        visible={true}>
        <div className="author">
          <h5 className="title">Feedback details</h5>
          <p className="">Category: {feedback.category?.name}</p>
          <p className="">
            Status:{" "}
            {feedback.acknowledged
              ? `Acknowledged By ${feedback.acknowledgedBy}`
              : "Not Acknowledged"}
          </p>
          <p className="">
            Received on:{" "}
            {new Date(feedback.date).toLocaleString("en-US", {
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            })}
          </p>
          <p className="">Submitted by: {feedback.submittedBy}</p>
        </div>
        <p>Message:</p>
        <p className="text-center">
          {feedback.message ? feedback.message : "feedback detail"}
        </p>
      </Modal>
    </>
  );
}

export default User;
