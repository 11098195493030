import React, { useEffect, useState } from "react";
import { produce } from "immer";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByPlaceId } from "react-google-places-autocomplete";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
  Alert,
} from "reactstrap";
import eRLogo from "../../../../../assets/img/ER Logo Black.png";

import Donations from "../DonationHistory/";
import leftIcon from "../../../../../assets/icons/icon-left.png";
import EditCampaign from "../EditPartner";
import actionCreators from "store/actionCreators/partner.js";
import { connect } from "react-redux";
import ReactTable from "components/ReactTable/ReactTable.js";
import ClearIcon from "@material-ui/icons/DeleteForever";
import { Pagination, message } from "antd";
import CouponAnalytics from "../../CouponAnalytics";
import "../../../../../style.css";
import moment from "moment";

function CampaignDetails({
  page,
  setPage,
  item,
  getPartnerDetails,
  upgradeLifestylePartner,
  deleteEnterpriseLocation,
  saveEnterpriseLocations,
  uploadCsv,
  processCsv,
  getPartnerOffersCount,
  getPartnerOffers,
  getEventsList,
}) {
  const [dataState, setDataState] = React.useState([
    {
      Name: "1",
      CreatedTime: "1",
      Expiry: "1",
      Redeemed: "1",
      Event: <div className="actions-right">yes</div>,
    },
  ]);

  const [checkboxVisibility, setCheckboxVisibility] = React.useState({
    checked: item.userType == "EnterpriseLifeStylePartner",
    disable: item.userType == "EnterpriseLifeStylePartner",
  });
  const [enterpriseLocations, setEnterpriseLocations] = React.useState(
    item.locations
  );
  const [offers, setOffers] = React.useState([]);
  const [pageNumberOffers, setPageNumberOffers] = React.useState(1);
  const [itemsOnPageOffers, setItemsOnPageOffers] = React.useState(10);
  const [totalItemsOffers, setTotalItemsOffers] = React.useState("");
  const [selectedOffer, setSelectedOffer] = React.useState([]);

  const [Events, setEvents] = React.useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchValue, setSearchValue] = useState(undefined);
  const [itemsOnPageEvents, setItemsOnPageEvents] = React.useState(10);
  const [pageNumberEvents, setPageNumberEvents] = React.useState(1);
  const [totalItemsEvents, setTotalItemsEvents] = React.useState("");

  const alertStyles = {
    width: "100%",
    fontSize: "0.8em",
    textAlign: "center",
    padding: "5px",
    backgroundColor: "#ffc3c3",
    color: "#600f0f",
  };

  const finishedAlert = {
    width: "100%",
    fontSize: "0.8em",
    textAlign: "center",
    padding: "5px",
    color: "#0f5132",
    backgroundColor: "#d1e7dd",
  };

  useEffect(() => {
    getOfferList(pageNumberOffers - 1);
  }, [pageNumberOffers]);

  useEffect(() => {
    getEvents(pageNumberEvents - 1);
  }, [pageNumberEvents]);

  const getEvents = (pageNumber) => {
    let obj = {
      pageNumber: pageNumber,
      itemsOnPage: itemsOnPageEvents,
      partnerId: item?.id,
    };
    if (searchValue) {
      obj.search = searchValue;
    }
    getEventsList(obj).then((res) => {
      let arr = res;
      console.log(arr);
      if (res) {
        setTotalItemsEvents(res?.totalItems);
        setItemsOnPageEvents(10);
        setTableData(res?.events);
      }
    });
  };

  const onChangePageEvents = (page, pageSize) => {
    setPageNumberEvents(page);
  };

  const onKeyDown = (e) => {
    var theEvent = window.event || e;
    var code = theEvent.keyCode || theEvent.which || theEvent.charCode;
    if (code == 13) {
      getEvents(pageNumberEvents - 1);
    }
  };

  const backButton = () => {
    setPage("1");
  };

  const editButton = () => {
    setPage("3");
  };

  const handleUpgradeToEnterprise = () => {
    upgradeLifestylePartner(item).then((result) => {
      setCheckboxVisibility({ checked: true, disable: true });
    });
  };

  const handleNewLocation = () => {
    setEnterpriseLocations(
      produce(enterpriseLocations, (draft) => {
        draft.push({
          name: "",
          email: "",
          phoneNumber: "",
          address: "",
          cityName: "",
          latitude: 0,
          longitude: 0,
          temporalId: enterpriseLocations.length + 1,
        });
      })
    );
  };

  console.log(enterpriseLocations);

  const handleDeleteLocation = (partner, index) => {
    if (partner.temporalId) {
      enterpriseLocations.splice(index, 1);

      setEnterpriseLocations([...enterpriseLocations]);
    } else {
      deleteEnterpriseLocation({
        partnerId: partner.lifeStylePartnerId,
        locationId: partner.lifestylePartnerLocationId,
      }).then((resp) => {
        const match = enterpriseLocations.find((i) => {
          return (
            i.lifestylePartnerLocationId == partner.lifestylePartnerLocationId
          );
        });

        enterpriseLocations.splice(enterpriseLocations.indexOf(match), 1);

        setEnterpriseLocations([...enterpriseLocations]);
      });
    }
  };

  const handleSaveLocations = () => {
    const payload = {
      id: item.id,
      locations: enterpriseLocations,
    };

    saveEnterpriseLocations(payload).then((resp) => {
      console.log(resp);
      let savedWithErrors = false;

      const withResponse = enterpriseLocations.map((l, index) => {
        const createdLocationResponse = resp.createdLocations[index];

        if (!savedWithErrors && createdLocationResponse.error) {
          savedWithErrors = true;
        }

        return {
          ...l,
          message: createdLocationResponse,
        };
      });

      if (!savedWithErrors) message.success("Finished!", 3);
      else message.warning("An error occured while saving some locations", 4);

      setEnterpriseLocations(withResponse);
    });
  };

  const handleNewCsvFile = (inputFile) => {
    const files = inputFile.files;

    const formData = new FormData();

    formData.append("File", files[0]);

    uploadCsv(formData).then((resp) => {
      console.log(resp);

      processCsv({ id: item.id, fileId: resp.fileId }).then((resp) => {
        console.log(resp);
        const mappedResults = resp.importedLocations.map((l) => {
          return {
            ...l,
            message: { message: l.message, error: l.error },
          };
        });
        setEnterpriseLocations(mappedResults);
      });
    });
  };

  const checkCanSaveLocations = () => {
    if (
      enterpriseLocations == undefined ||
      enterpriseLocations == undefined ||
      enterpriseLocations.length == 0
    )
      return false;

    for (var i = 0; i < enterpriseLocations.length; i++) {
      const location = enterpriseLocations[i];

      if (
        location.name == "" ||
        location.address == "" ||
        location.phoneNumber == "" ||
        location.manager == ""
      )
        return false;

      if (location.message && location.message.error) return false;
    }

    return true;
  };

  const getOfferList = (pageNumber) => {
    getPartnerOffersCount(item.id).then(({ totalItems }) => {
      setTotalItemsOffers(totalItems);
      setItemsOnPageOffers(10);
      getPartnerOffers(item.id, {
        pageNumber: pageNumber,
        itemsOnPage: itemsOnPageOffers,
      }).then((res) => {
        let arr = res;
        console.log(arr);
        if (arr) {
          let offers = arr.map((prop, key) => {
            return {
              id: key,
              title: prop.title,
              viewOffer: (
                <div className="actions-right">
                  <a
                    className="underline"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      setPage("4");
                      setSelectedOffer(prop);
                    }}>
                    View Analytics
                  </a>
                </div>
              ),
            };
          });
          console.log(offers);
          setOffers(offers);
        }
      });
    });
  };
  const onChangePageOffers = (page, pageSize) => {
    setPageNumberOffers(page);
  };

  const renderNewLocationsContainer = () => {
    return (
      <Card style={{ width: "99%" }}>
        <CardHeader className="paybill-header">
          <h5 className="title ml-2">Enterprise Additional Locations</h5>
          {/* <Input
            type="file"
            vale="csv upload"
            onChange={(e) => handleNewCsvFile(e.target)}
          /> */}
        </CardHeader>
        <CardBody>
          {enterpriseLocations &&
            enterpriseLocations.map((item, index) => (
              <>
                <Row
                  style={
                    item.message && item.message.error
                      ? { border: "1px dashed #ed7474", padding: "5px 0" }
                      : { padding: "5px 0" }
                  }>
                  <Col md="2">
                    <FormGroup>
                      <div style={{ margin: "0px 20px" }}>
                        <label>
                          Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          onChange={(e) => {
                            setEnterpriseLocations(
                              produce(enterpriseLocations, (draft) => {
                                draft[index].name = e.target.value;
                              })
                            );
                          }}
                          name="name"
                          value={item.name}
                          type="text"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <div style={{ margin: "0px 5px" }}>
                        <label>
                          Phone <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          onChange={(e) => {
                            setEnterpriseLocations(
                              produce(enterpriseLocations, (draft) => {
                                draft[index].phoneNumber = e.target.value;
                              })
                            );
                          }}
                          name="phoneNumber"
                          value={item.phoneNumber}
                          type="text"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <div style={{ margin: "0px 5px" }}>
                        <label>
                          Manager <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          onChange={(e) => {
                            setEnterpriseLocations(
                              produce(enterpriseLocations, (draft) => {
                                draft[index].manager = e.target.value;
                              })
                            );
                          }}
                          name="manager"
                          value={item.manager}
                          type="text"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <FormGroup>
                      <div style={{ margin: "0px 5px" }}>
                        <label>
                          Address <span style={{ color: "red" }}>*</span>
                        </label>
                        <GooglePlacesAutocomplete
                          apiKey="AIzaSyA26kAp7DrCPrax2U4cQDu1ffRLcEFbnM0"
                          selectProps={{
                            // city,
                            onChange: (city) => {
                              if (city?.value?.place_id) {
                                geocodeByPlaceId(city?.value?.place_id)
                                  .then((result) => {
                                    setEnterpriseLocations(
                                      produce(enterpriseLocations, (draft) => {
                                        draft[index].address =
                                          result?.formatted_address;
                                        draft[index].cityName =
                                          result?.formatted_address;
                                      })
                                    );
                                  })
                                  .catch((error) => console.error(error));
                              }
                            },
                          }}
                          apiOptions={{ language: "ca" }}
                        />
                        {/* <Input onChange={(e) => handleAddressChange(item, index, e)} name="address" value={item.address} type="text" /> */}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="1">
                    <FormGroup>
                      <div style={{ margin: "0px 5px" }}>
                        <Button
                          style={{
                            backgroundColor: "white",
                            margin: "0",
                            padding: "5px",
                            color: "purple",
                          }}
                          onClick={(e) => handleDeleteLocation(item, index)}>
                          <ClearIcon style={{ fontSize: "30px" }} />
                        </Button>
                      </div>
                    </FormGroup>
                  </Col>
                  {item.message && item.message.error && (
                    <Col md="12">
                      <Alert style={alertStyles} color="danger">
                        {item.message.message}
                      </Alert>
                    </Col>
                  )}
                  {item.message && !item.message.error && (
                    <Col md="12">
                      <Alert style={finishedAlert}>
                        {item.message.message}
                      </Alert>
                    </Col>
                  )}
                </Row>
              </>
            ))}

          <Row>
            <Button
              color="primary"
              style={{
                borderStyle: "dashed",
                width: "100%",
                color: "purple",
                backgroundColor: "white",
                borderWidth: "thin",
                borderColor: "purple",
              }}
              onClick={handleNewLocation}>
              + Add new location
            </Button>
          </Row>
          <Row>
            <Button
              disabled={!checkCanSaveLocations()}
              onClick={handleSaveLocations}>
              Save
            </Button>
          </Row>
        </CardBody>
      </Card>
    );
  };

  return (
    <>
      {page === "2" && (
        <div className="content paybill">
          <Row>
            <Col md="11">
              <div onClick={backButton} className="back-button-title mb-2">
                <img className="mb-1" width="14px" src={leftIcon} />
                Back
              </div>
              <Card style={{ width: "99%" }}>
                <CardHeader className="paybill-header">
                  <h5 className="title ml-2">Business Partners Details</h5>
                </CardHeader>

                <CardBody>
                  <Form>
                    <Row>
                      <Col md="12">
                        <div className="mb-4">
                          <img alt="..." src={item?.logo || eRLogo} />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Name</label>
                          <div>{item.businessName}</div>
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Number of Active Offers</label>
                          <div>{item.activeOffers}</div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {item &&
                        item.userType != "EnterpriseLifeStylePartner" && (
                          <Col className="pr-1" md="6">
                            <FormGroup>
                              <label>Location</label>
                              <div>{item.fullAddress}</div>
                            </FormGroup>
                          </Col>
                        )}
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Interests</label>
                          <div>{item.interests.join(", ")}</div>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label> Invitation Date</label>
                          <div>{item.invitationDate}</div>
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Expiry Date</label>
                          <div>{item.expiryDate}</div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Number of Redeemed Offers</label>
                          <div>{item.totalRedemptions}</div>
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Contact Information</label>
                          <div>
                            <label>Email: </label> {item.email}
                          </div>
                          <div>
                            <label>Contact Email: </label> {item.contactEmail}
                          </div>
                          <div>
                            <label>Main Corporate Phone Number: </label>{" "}
                            {item.mainCorporatePhoneNumber}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Enterprise-level Business Partner</label>
                          <div style={{ margin: "0px 20px" }}>
                            <Input
                              style={{
                                height: "25px",
                                width: "25px",
                                cursor: "pointer",
                              }}
                              type="checkbox"
                              checked={checkboxVisibility.checked}
                              disabled={checkboxVisibility.disable}
                              onChange={(e) => handleUpgradeToEnterprise()}
                            />
                          </div>
                          <div
                            style={{ margin: "5px 30px", fontSize: "0.85em" }}>
                            <label>
                              By marking a Business Partner as Enterprise, it
                              will allow them to add multiple locations of their
                              business. Once Enterprise is selected, this
                              feature cant be reverted.
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col className="pr-1" md="12">
                        {item && item.activeOffersList && (
                          <ReactTable
                            data={item.activeOffersList}
                            columns={[
                              {
                                Header: "Name",
                                accessor: "title",
                                sortable: false,
                              },
                              {
                                Header: "Date created",
                                accessor: "createdAt",
                                sortable: false,
                              },
                              {
                                Header: "Expiry/End date",
                                sortable: false,
                                filterable: false,
                                Cell: (tableProps) => (
                                  <span>
                                    {tableProps.row.original.expiryDate ??
                                      tableProps.row.original.endDate}
                                  </span>
                                ),
                              },
                              {
                                Header: "Redeemed number",
                                accessor: "redeemedCount",
                                sortable: false,
                                filterable: false,
                              },
                              {
                                Header: "Is Event",
                                accessor: "isEvent",
                                sortable: false,
                                Cell: (tableProps) => (
                                  <div style={{ textAlign: "right" }}>
                                    <span>
                                      {tableProps.row.original.isEvent
                                        ? "Yes"
                                        : "No"}
                                    </span>
                                  </div>
                                ),
                              },
                            ]}
                          />
                        )}
                      </Col>
                    </Row> */}
                  </Form>
                </CardBody>
              </Card>
              {item &&
                item.userType == "EnterpriseLifeStylePartner" &&
                renderNewLocationsContainer()}
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardHeader className="paybill-header">
                  <h5 className="title ml-2">Offers</h5>
                </CardHeader>
                <CardBody className="ml-4">
                  <div className="w-full pb-6 box-content">
                    <ReactTable
                      data={offers}
                      columns={[
                        {
                          Header: "Offer Title",
                          accessor: "title",
                          id: "columnId1",
                        },
                        {
                          Header: "View Analytics",
                          accessor: "viewOffer",
                          disableSortBy: true,
                        },
                      ]}
                    />
                    <Row>
                      <Col md="12">
                        <div className="erPagination">
                          <Pagination
                            showSizeChanger={false}
                            current={pageNumberOffers}
                            defaultPageSize={itemsOnPageOffers}
                            pageSize={itemsOnPageOffers}
                            total={totalItemsOffers}
                            onChange={onChangePageOffers}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Events */}
          <Row>
            <Col>
              <Card>
                <CardHeader className="paybill-header">
                  <h5 className="title ml-2">Events</h5>
                </CardHeader>
                <CardBody className="">
                  <Row>
                    <Col className="" md="12">
                      <FormGroup>
                        <Input
                          placeholder="Search"
                          type="text"
                          value={searchValue}
                          onChange={(e) => {
                            setSearchValue(e.target.value);
                          }}
                          onKeyDown={onKeyDown}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="sm:px-6 lg:px-4 bg-white">
                    <div className="mt-8 flow-root">
                      <div className="">
                        <div className="inline-block w-full py-1 align-middle">
                          {tableData?.length > 0 ? (
                            <table className="w-full border-separate border-spacing-0 eventsTable">
                              <thead>
                                <tr>
                                  <th scope="col">Name</th>
                                  <th scope="col">Start Date/Time</th>
                                  <th scope="col">End Date/Time</th>
                                  <th scope="col">Timezone</th>
                                  <th scope="col">Location</th>
                                  <th scope="col">Attendees</th>
                                  <th
                                    scope="col"
                                    className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-center text-sm font-normal text-gray-500 backdrop-blur backdrop-filter">
                                    Status
                                  </th>

                                  {/* <th
                                  scope="col">
                                  <span className="sr-only">Edit</span>
                                </th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {tableData?.map((item, personIdx) => (
                                  <tr
                                    key={item.email}
                                    className="even:bg-gray-50">
                                    <td
                                      className={
                                        "whitespace-nowrap py-1 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                                      }>
                                      {item?.title}
                                    </td>
                                    <td
                                      className={
                                        "whitespace-nowrap hidden px-3 py-1 text-sm text-gray-500 sm:table-cell"
                                      }>
                                      {item.startDate
                                        ? moment(item.startDate).format(
                                            "MM/DD/YYYY, h:mm a"
                                          )
                                        : ""}
                                    </td>
                                    <td
                                      className={
                                        "whitespace-nowrap hidden px-3 py-1 text-sm text-gray-500 lg:table-cell"
                                      }>
                                      {item.endDate
                                        ? moment(item.endDate).format(
                                            "MM/DD/YYYY, h:mm a"
                                          )
                                        : ""}
                                    </td>
                                    <td
                                      className={
                                        "whitespace-nowrap px-3 py-1 text-sm text-gray-500"
                                      }>
                                      {item.timezoneIanaId}
                                    </td>
                                    <td
                                      className={
                                        "whitespace-nowrap px-3 py-1 text-sm text-gray-500"
                                      }>
                                      {item.locationType === 0
                                        ? "In Person"
                                        : "Online"}
                                      <p className="text-gray-600 italic">
                                        {" "}
                                        {item.locationType === 0 &&
                                          item?.locationAddress}
                                      </p>
                                    </td>
                                    <td
                                      className={
                                        "whitespace-nowrap px-3 py-1 text-sm text-gray-500"
                                      }>
                                      {item.capacity
                                        ? item.capacity
                                        : "Unlimited"}
                                    </td>
                                    <td
                                      className={
                                        "whitespace-nowrap px-3 py-1 text-sm text-gray-500"
                                      }>
                                      <>
                                        {item.status == 1 ? (
                                          <span className="h-[30px] leading-[30px]  px-3 bg-[#CCF2F5] text-[#00C2CE] rounded-md flex justify-center font-bold one-clamp">
                                            Upcoming
                                          </span>
                                        ) : item.status == 2 ? (
                                          <span className="h-[30px] leading-[30px]  px-3 bg-[#E2D0EF] text-[#7217B3] rounded-md flex justify-center font-bold one-clamp">
                                            Completed
                                          </span>
                                        ) : item.status == 3 ? (
                                          <span className="h-[30px] leading-[30px]  px-3 bg-[#FFDBDB] text-[#FF4D4D] rounded-md flex justify-center font-bold one-clamp">
                                            Canceled
                                          </span>
                                        ) : item.status == 4 ? (
                                          <span className="h-[30px] leading-[30px]  px-3 bg-[#F9E3D5] text-[#F58541] rounded-md flex justify-center font-bold one-clamp">
                                            Closed
                                          </span>
                                        ) : (
                                          <span className="h-[30px] leading-[30px]  px-3 bg-slate-100 text-slate-700 rounded-md flex justify-center font-bold one-clamp">
                                            None
                                          </span>
                                        )}
                                      </>
                                    </td>

                                    {/* <td
                                    className={(
                                      personIdx !== tableData?.length - 1
                                        ? "border-b border-gray-200"
                                        : "",
                                      "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-8 lg:pr-8"
                                    )}>
                                    <a
                                      href="#"
                                      className="text-indigo-600 hover:text-indigo-900">
                                      Edit
                                      <span className="sr-only">
                                        , {item.name}
                                      </span>
                                    </a>
                                  </td> */}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <div className="px-4">No events</div>
                          )}
                          <Row>
                            <Col md="12">
                              <div className="erPagination">
                                <Pagination
                                  showSizeChanger={false}
                                  current={pageNumberEvents}
                                  defaultPageSize={itemsOnPageEvents}
                                  pageSize={itemsOnPageEvents}
                                  total={totalItemsEvents}
                                  onChange={onChangePageEvents}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}

      {page === "3" && <EditCampaign setPage={setPage} />}

      {page === "4" && (
        <CouponAnalytics
          setPage={setPage}
          partnerId={item.id}
          selectedOffer={selectedOffer}
        />
      )}
    </>
  );
}
function mapStateToProps(state) {
  return state.user;
}
export default connect(mapStateToProps, actionCreators)(CampaignDetails);
