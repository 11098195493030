export const SHOW_LOADING = 'SHOW_LOADING'
export const HIDE_LOADING = 'HIDE_LOADING'

export const VALIDATE = 'VALIDATE'
export const SET_USER = 'SET_USER'
export const UPDATE_USER = 'UPDATE_USER'


export const GET_CITY = 'GET_CITY' 
export const GET_PROVINCES = 'GET_PROVINCES' 

export const LOGOUT = 'LOGOUT';