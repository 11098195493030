import * as actionTypes from "../action-types"
import { updateFeedback, getFeedback, getFeedbackCount, searchFeedback } from "../../api/feedback"

const actionCreators = {
  getFeedback(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
          let res = await getFeedback(data)
          resolve(res)
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
        }
      })
  },
  getFeedbackCount(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
          let res = await getFeedbackCount(data)
          resolve(res)
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
        }
      })
  },
  searchFeedback(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          let res = await searchFeedback(data)
          resolve(res)
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
        }
      })
  },
  updateFeedback(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          let res = await updateFeedback(data)
          resolve(res)
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
        }
      })
  },
}
export default actionCreators
