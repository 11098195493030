import * as actionTypes from "../action-types"
import {
  getTotalNumberOfOfferClicks,
  getTotalOffersRedeemedByGender,
  getTotalNumberOfPageViewsOfAllVendors,
  getTotalOffersRedeemedByLocation,
  getTotalOffersRedeemedByAgeGroup,
  getTotalOffersRedeemedByCategoryAndGender,
  getTotalSavingByCategory,
  getSavingsByCategoryAndPartner,
  getPartnersActivity,
  getActiveUsers,
} from "../../api/dashboard"

const actionCreators = {
  getTotalNumberOfOfferClicks(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
          let res = await getTotalNumberOfOfferClicks(data)
          resolve(res)
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
        }
      })
  },
  getTotalNumberOfPageViewsOfAllVendors(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
          let res = await getTotalNumberOfPageViewsOfAllVendors(data)
          resolve(res)
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
        }
      })
  },
  getTotalOffersRedeemedByLocation(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
          let res = await getTotalOffersRedeemedByLocation(data)
          resolve(res)
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
        }
      })
  },
  getTotalSavingByCategory(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
          let res = await getTotalSavingByCategory(data)
          resolve(res)
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
        }
      })
  },
  getSavingsByCategoryAndPartner(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
          let res = await getSavingsByCategoryAndPartner(data)
          resolve(res)
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
        }
      })
  },
  getTotalOffersRedeemedByGender(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
          let res = await getTotalOffersRedeemedByGender(data)
          resolve(res)
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
        }
      })
  },
  getTotalOffersRedeemedByAgeGroup(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
          let res = await getTotalOffersRedeemedByAgeGroup(data)
          resolve(res)
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
        }
      })
  },
  getTotalOffersRedeemedByCategoryAndGender(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
          let res = await getTotalOffersRedeemedByCategoryAndGender(data)
          resolve(res)
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
        }
      })
  },
  getPartnersActivity(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
          let res = await getPartnersActivity(data)
          resolve(res)
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
        }
      })
  },
  getActiveUsers(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
          let res = await getActiveUsers(data)
          resolve(res)
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
        }
      })
  }
}
export default actionCreators
