import React from "react";
import { Link, useLocation } from "react-router-dom";
// used for making the prop types of this component
import PropTypes from "prop-types";

// reactstrap components
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
import moment from "moment";
import swal from "sweetalert2";

import logOutLogo from "../../assets/icons/log-out-icon@2x.png";
import "./style.css";
import actionCreators from "../../store/actionCreators/login";
import { connect } from "react-redux";
function AdminNavbar(props) {
  const location = useLocation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [color, setColor] = React.useState("transparent");
  const sidebarToggle = React.useRef();
  const toggle = () => {
    if (isOpen) {
      setColor("transparent");
    } else {
      setColor("white");
    }
    setIsOpen(!isOpen);
  };
  const dropdownToggle = (e) => {
    setDropdownOpen(!dropdownOpen);
  };
  const openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    sidebarToggle.current.classList.toggle("toggled");
  };
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && isOpen) {
      setColor("white");
    } else {
      setColor("transparent");
    }
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    // eslint-disable-next-line
  }, []);
  React.useEffect(() => {
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      sidebarToggle.current.classList.toggle("toggled");
    }
  }, [location]);

  const signOutButton = () => {
    props.logout();
    // props.history.push("/auth/login")
    window.location.href = "/auth/login-page";
    // swal
    //   .fire({
    //     text: "Are you sure you want to Sign out?",
    //     showCancelButton: true,
    //     confirmButtonColor: "#392E8B",
    //     confirmButtonText: "Yes",
    //     cancelButtonText: "No",
    //   })
    //   .then((result) => {
    //     if (result.isConfirmed) {
    //       window.location.href = "/auth/login-page";
    //     } else {
    //     }
    //   });
  };
  return (
    // add or remove classes depending if we are on full-screen-maps page or not
    <Navbar
      style={{ borderBottom: "1px solid #0000001A" }}
      color={
        window.location.href.indexOf("full-screen-maps") !== -1
          ? "white"
          : color
      }
      expand="lg">
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-toggle">
            <button
              type="button"
              ref={sidebarToggle}
              className="navbar-toggler"
              onClick={() => openSidebar()}>
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <div className="navbar-date">{moment().format("LL")}</div>
          {/* <NavbarBrand href="/">{props.brandText}</NavbarBrand> */}
        </div>
        <NavbarToggler onClick={toggle}>
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar className="justify-content-end">
          <Nav navbar>
            <NavItem>
              <Link to="/auth/login-page" className="nav-link">
                <img
                  style={{ width: 24, height: 24 }}
                  className="now-ui-icons users_single-02"
                  src={logOutLogo}
                  onClick={signOutButton}
                />
                {/* <p>
                  <span className="d-lg-none d-md-block">Account</span>
                </p> */}
              </Link>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

AdminNavbar.defaultProps = {
  brandText: "Default Brand Text",
};

AdminNavbar.propTypes = {
  // string for the page name
  brandText: PropTypes.string,
};
function mapStateToProps(state) {
  return state.user;
}
export default connect(mapStateToProps, actionCreators)(AdminNavbar);
