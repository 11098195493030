import { connectRouter } from "connected-react-router"

import { combineReducers } from "redux"
import history from "../../history"
import loading from "./loading"
import user from "./user"
import locations from "./locations"
let reducers = {
  router: connectRouter(history),
  loading,
  locations,
  user,
}
let combinedReducer = combineReducers(reducers)
export default combinedReducer
