import React,{useEffect} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
} from "reactstrap";
// import "./style.css";
import CreateOffer from "./Create";
import ReactTable from "components/ReactTable/ReactTable.js";
import actionCreators from "../../../../store/actionCreators/intesetsCategory"
import { connect } from "react-redux"
import { message } from "antd";
const dataTable = [
    ['abc',  '2021-01-25',],
    ['abc',  '2021-01-25',],
    ['abc',  '2021-01-25',]
  ];


function Offer(props) {
  const [newContactModal, setNewContactModal] = React.useState(false);
  const [page, setPage] = React.useState("1");
  const [dataState, setDataState] = React.useState(dataTable.map((prop, key) => {
    return {
      id: key,
      name: prop[0],
      CreateTime: (
        <div key={key} className="actions-right">
          {prop[1]}
        </div>
      ),
    };
  }));
  useEffect(()=>{
    getList()
  },[])


  const getList = ()=>{
    props.getIntesets().then(res=>{

    })
  }
  return (
    <>
      {page === "1" ? (
        <div className="content users">
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">
                    <div>Interests</div>
                  </CardTitle>
                  <div className="float-right">
                    <Button
                      onClick={() => setPage("2")}
                      color="secondary"
                      size="md"
                      className="btn-round mr-2">
                      <i className="fa fa-plus mr-2" /> Create
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                <ReactTable
                    data={dataState}
                    showPagination
                    columns={[
                      {
                        Header: "interests name",
                        accessor: "name",
                        sortable: false,
                      },
                      {
                        Header: "CreateTime",
                        accessor: "CreateTime",
                        sortable: false,
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      ) : page === "2" ? (
        <CreateOffer setPage={setPage} />
      ) : ""}
    </>
  );
}

function mapStateToProps(state) {
  return state.user
}

export default connect(mapStateToProps, actionCreators)(Offer);
