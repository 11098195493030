import React, { useMemo, useEffect } from "react";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";

import actionCreators from "store/actionCreators/partner.js";
import { connect } from "react-redux";

const localizer = momentLocalizer(moment);


function BillingsCalendar(props) {
    const [events, setEvents] = React.useState([]);
    const [alert, setAlert] = React.useState(null);
    const [currentDay, setCurrentDay] = React.useState({});
    const [event, setEvent] = React.useState(null);

    useEffect(() => {
        let year = moment().year();
        let month = moment().month();
        setCurrentDay({
            year, month
        });
    }, []);

    useEffect(() => {
        if (currentDay.month != null) {
            props.getBillingsCalendar({
                Month: currentDay.month + 1,
                Year: currentDay.year,
                Day: 1
            }).then(res => {
                console.log(res);
                let color = ['green', 'red', 'azure', 'rose'];
                let arr = res.map((item, index) => {
                    let colorIndex = (index + 1) > 4 ? (index + 1) % 4 : index;
                    return {
                        title: item.businessName,
                        start: moment(item.billingCycleStart, "MM/DD/YYYY").set('year', currentDay.year),
                        end: moment(item.billingCycleStart, "MM/DD/YYYY").add(23, "hours").set('year', currentDay.year),
                        allDay: true,
                        color: color[colorIndex],
                        description: `${item.billingAmount}`,
                        coverImage: item.logo,
                        email: item.email,
                    };
                });
                console.log(arr);
                setEvents(arr);
            });
        }
    }, [currentDay]);

    const selectedEvent = (event) => {
        console.log(event);
        setAlert(true);
        setEvent(event);
    };
    const MyToolbar = ({ onNavigate, label, onView, views: viewNames, view }) => {
        return (
            <span className="flex items-center py-2 relative">
                <img className="ml-2 cursor-pointer w-[30px]" onClick={() => onNavigate('PREV')} 
                    src={require("../../../assets/img/er/icon_chevron_arrowhead_left.svg").default} alt="arrow"></img>
                <span className="px-2 text-2xl font-bold">{label}</span>
                <img className="cursor-pointer w-[30px]" onClick={() => onNavigate('NEXT')} 
                    src={require("../../../assets/img/er/icon_chevron_arrowhead_right.svg").default} alt="arrow"></img>
                {view == 'day'
                    ? <span className="rbc-btn-group rbc-toolbar absolute right-3" style={{ margin: 0, padding: 0 }}>
                          <button onClick={() => onView('month')} className="month-button" type="button">view month</button>
                      </span>
                    : ''}
            </span>
        );
    };
    const eventColors = (event) => {
        var backgroundColor = "event-";
        event.color
            ? (backgroundColor = backgroundColor + event.color)
            : (backgroundColor = backgroundColor + "default");
        return {
            className: backgroundColor,
        };
    };
    const components = useMemo(() => ({
        toolbar: MyToolbar
    }), []);

    return (
        <div>
            {event && <Modal isOpen={alert} toggle={() => setAlert(false)}>
                <ModalHeader toggle={() => setAlert(false)}>{event.title}</ModalHeader>
                <ModalBody>
                    <div className="w-full p-2">
                        {event.coverImage ? <img className="w-full" src={event.coverImage}/> : ''}
                        <br/>
                        <p className="text-left mt-2">Billing Amount: ${event.description}</p>
                        <p className="text-left mt-2">Email: {event.email}</p>
                    </div>
                </ModalBody>
                <ModalFooter>
                <Button color="primary" onClick={() => setAlert(false)}>
                    OK
                </Button>
                </ModalFooter>
            </Modal>}
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardBody calendar>
                        <div className="h-[900px]">
                            <BigCalendar
                                selectable
                                localizer={localizer}
                                events={events}
                                components={components}
                                defaultView="month"
                                scrollToTime={new Date(1970, 1, 1, 6)}
                                defaultDate={new Date()}
                                onNavigate={date => {
                                    console.log(date);
                                    let year = moment(date).year();
                                    let month = moment(date).month();
                                     setCurrentDay({
                                          year, month
                                     });
                                     console.log(currentDay);
                                }}
                                onSelectEvent={(event) => selectedEvent(event)}
                                eventPropGetter={eventColors}
                            />
                        </div>
                    </CardBody>
                </Card>
            </GridItem>
        </div>
    );
}

function mapStateToProps(state) {
    return state.user;
}

export default connect(mapStateToProps, actionCreators)(BillingsCalendar);