import React, { useEffect, useState, useMemo, useRef } from "react";
import debounce from "lodash/debounce";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import { searchPartner } from "../../../../api/partner";
import Select from "react-select";
import { message, DatePicker, Select as AntdSelect, Spin } from "antd";
import moment from "moment";
import ImageUpload from "./CustomUpload/ImageUpload.js";
// import "../style.css";
import actionCreators from "store/actionCreators/offer";
import { connect } from "react-redux";
const CanOptions = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];

function CreateCategory({ setPage, addOffer, getIntesets, getCategory }) {
  const [OfferName, setOfferName] = React.useState("");
  const [description, setDescription] = useState("");
  const [memberValue, setMemberValue] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [tagsList, setTagList] = useState([]);
  const [discount, setDiscount] = useState("");
  const [isReusable, setIsReusable] = useState(null);
  const [createEvent, setCreateEvent] = useState(null);
  const [imagesId, setImagesId] = React.useState("");
  const [intesetsOptions, setIntesetsOptions] = React.useState([]);
  const [intesetsSelect, setIntesetsSelect] = React.useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [discountError, setDiscountError] = useState(null);
  const uploadRef = useRef();
  useEffect(() => {
    // getIntesets().then((res) => {
    //   let arr = res.map((item) => {
    //     return {
    //       value: item.id,
    //       label: item.name,
    //     }
    //   })
    //   setIntesetsOptions(arr)
    // })
    getInterestsList();
  }, []);
  const checkvalidDiscount = (value) => {
    if (!value) {
      setDiscountError("Discount is required");
    } else if (!value || value.match(/^[0-9]+$/) == null || value <= 0) {
      setDiscountError("Only number greater than 0 is allowed.");
    } else {
      setDiscountError(null);
    }
  };
  const getInterestsList = async () => {
    let categoryList = await getCategory();
    setCategoryList(categoryList);
    getIntesets().then((inter) => {
      let arrinter = inter.map((item) => {
        return {
          tags: item.name,
          select: false,
          id: item.id,
          category: item.category,
        };
      });
      setTagList(arrinter);
    });
  };
  let tagClick = (id, item) => {
    let arr = tagsList.concat();
    let length = arr.filter((item) => item.select).length;
    if (length == 3 && !item.select) {
      message.error("You can select up to 3 interests");
      return;
    }
    arr.forEach((item, index) => {
      if (item.id == id) {
        arr[index].select = !arr[index].select;
      }
    });
    // console.log(arr);
    setTagList(arr);
  };
  const continueButton = () => {
    // window.location.href = "/admin/move-money/confirm";
    if (
      !imagesId ||
      !OfferName ||
      !description ||
      !isReusable ||
      !discount ||
      !createEvent ||
      !memberValue ||
      !expiryDate ||
      !startDate ||
      tagsList.filter((item) => item.select).length == 0
    ) {
      message.error("Please complete the form!");
      return;
    }

    let selectTags = tagsList
      .filter((item) => item.select)
      .map((item) => item.id);
    // console.log(memberValue);
    // return
    let obj = {
      title: OfferName,
      coverImage: imagesId,
      description: description,
      isReusable: isReusable.value == 0 ? false : true,
      discountValue: discount,
      associatedInterests: selectTags,
      type: 1,
      // "partnerId": memberValue,
      partnerList: memberValue,
      createEvent: createEvent.value == 0 ? false : true,
    };

    obj.ExpiryDate = expiryDate && moment(expiryDate).format("YYYY/MM/DD");

    obj.StartDate = moment(startDate).format("YYYY/MM/DD");

    // console.log(obj);
    addOffer(obj).then((res) => {
      message.success("Offer successfully created");
      setOfferName("");
      setDescription("");
      setMemberValue([]);
      setDiscount("");
      setIsReusable(null);
      setStartDate(null);
      setExpiryDate(null);
      //
      let arr = tagsList.concat();
      arr.forEach((item, index) => {
        arr[index].select = false;
      });
      setTagList(arr);
      uploadRef.current.remove();
      //
    });
  };
  let uploadSuccess = (res) => {
    //  console.log(res);
    setImagesId(res.id);
  };
  let createEventChange = (value) => {
    setCreateEvent(value);
    // console.log(value);
  };
  return (
    <>
      <div className="content paybill">
        <Row>
          <Col md="7">
            <Card>
              <CardHeader className="paybill-header">
                <h5 className="title ml-2">Create Offer</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-1" md="12">
                      <FormGroup>
                        <label>
                          Offer Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          value={OfferName}
                          onChange={(e) => {
                            setOfferName(e.target.value);
                          }}
                          placeholder="Offer Name"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="12">
                      <div>
                        {" "}
                        <label>Image Upload</label>
                      </div>
                      <ImageUpload
                        ref={uploadRef}
                        addButtonProps={{
                          color: "rose",
                          round: true,
                        }}
                        changeButtonProps={{
                          color: "rose",
                          round: true,
                        }}
                        removeButtonProps={{
                          color: "danger",
                          round: true,
                        }}
                        uploadSuccess={uploadSuccess}
                      />
                    </Col>
                    {/* <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>
                          Interests <span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          placeholder="Single Select"
                          name="singleSelect"
                          value={intesetsSelect}
                          options={intesetsOptions}
                          onChange={(value) => setIntesetsSelect(value)}
                        />
                      </FormGroup>
                    </Col> */}
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>
                          Description <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          value={description}
                          onChange={(e) => {
                            setDescription(e.target.value);
                          }}
                          placeholder="Description"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>
                          Can offer be redeemed multiple times by same user{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          placeholder="Single Select"
                          name="singleSelect"
                          value={isReusable}
                          options={CanOptions}
                          onChange={(value) => setIsReusable(value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>
                          Offer Value in Dollars{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          value={discount}
                          onChange={(event) => {
                            setDiscount(event.target.value);
                            checkvalidDiscount(event.target.value);
                          }}
                          onBlur={(event) => {
                            checkvalidDiscount(event.target.value);
                          }}
                          placeholder="Discount"
                          type="text"
                        />
                        {discountError ? (
                          <p className="align-bottom text-error text-[12px]">
                            <div style={{ color: "red" }}>{discountError}</div>
                          </p>
                        ) : (
                          ""
                        )}
                      </FormGroup>
                    </Col>
                    {/* <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>
                          CreateEvent <span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          placeholder="Single Select"
                          name="singleSelect"
                          value={createEvent}
                          options={CanOptions}
                          onChange={(value) => createEventChange(value)}
                        />
                      </FormGroup>
                    </Col> */}

                    <>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>
                            Start Date <span style={{ color: "red" }}>*</span>
                          </label>
                          <br />
                          <DatePicker
                            style={{ width: "100%", height: "36px" }}
                            onChange={(date, dateString) => {
                              setStartDate(date);
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>
                            Expiry Date <span style={{ color: "red" }}>*</span>
                          </label>
                          <br />
                          <DatePicker
                            style={{ width: "100%", height: "36px" }}
                            onChange={(date, dateString) => {
                              setExpiryDate(date);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </>

                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>
                          Partner <span style={{ color: "red" }}>*</span>
                        </label>
                        <br />
                        <DebounceSelect
                          value={memberValue}
                          mode="multiple"
                          placeholder="Select users"
                          fetchOptions={fetchUserList}
                          onChange={(newValue) => {
                            setMemberValue(newValue);
                          }}
                          size="large"
                          style={{
                            width: "100%",
                            height: "36px",
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="12">
                      <p className="text-primary text-[16px]">
                        Please select up to 3 interests this offer belongs to:{" "}
                      </p>
                      {categoryList && categoryList.length ? (
                        <>
                          {categoryList.map((cate) => {
                            return (
                              <>
                                <p className="font-bold">{cate.category}:</p>
                                <div
                                  className=" flex-wrap w-full"
                                  style={{ display: "flex" }}>
                                  {tagsList && tagsList.length
                                    ? tagsList
                                        .filter(
                                          (item) =>
                                            item.category == cate.category
                                        )
                                        .map((item, index) => {
                                          if (item.select) {
                                            return (
                                              <div
                                                key={index}
                                                className={"tag-click"}
                                                onClick={() => {
                                                  tagClick(item.id, item);
                                                }}>
                                                {item.tags} &#x2715;{" "}
                                              </div>
                                            );
                                          } else {
                                            return (
                                              <div
                                                key={index}
                                                className={"tag"}
                                                onClick={() => {
                                                  tagClick(item.id, item);
                                                }}>
                                                <p
                                                  style={{
                                                    color: "black",
                                                  }}>
                                                  {item.tags}{" "}
                                                </p>
                                              </div>
                                            );
                                          }
                                        })
                                    : ""}
                                </div>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md="6"
                      className="footer-buttons d-flex justify-content-start">
                      <Button
                        onClick={() => setPage("1")}
                        color="secondary"
                        size="md"
                        className="btn-round mr-2 cancel-button">
                        Cancel{" "}
                      </Button>

                      <Button
                        onClick={continueButton}
                        color="primary"
                        size="md"
                        className="btn-round">
                        Ok
                      </Button>
                    </Col>
                    <Col md="6"></Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        if (newOptions && newOptions.length) {
          let arr = newOptions.map((item) => {
            return {
              label: item.businessName,
              value: item.id,
            };
          });
          setOptions(arr);
          setFetching(false);
        } else {
          message.error("Unable to search for partner");
          setOptions([]);
          setFetching(false);
        }
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <AntdSelect
      showSearch
      filterOption={false}
      onSearch={debounceFetcher}
      style={{ width: "100%", height: "36px" }}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}
async function fetchUserList(username) {
  // console.log('fetching user', username);
  return searchPartner({ searchTerm: username, type: 0 });
}
function mapStateToProps(state) {
  return state.user;
}

export default connect(mapStateToProps, actionCreators, null, {
  forwardRef: true,
})(CreateCategory);
