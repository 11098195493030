import { React, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Pagination,
} from "reactstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import NewGroupAdmin from "./NewGroupAdmin";
import actionCreators from "store/actionCreators/groupAdmin.js";
import { connect } from "react-redux";
import { message } from "antd";

function GroupAdminPage(props) {
  //state
  const [displayPage, setDisplayPage] = useState("listAll");
  const [groupAdmins, setGroupAdmins] = useState([]);
  const [allDealerships, setAllDealerships] = useState([
    { id: "", text: "abcdeff" },
    { id: "", text: "defdec" },
  ]);
  const [activeGroupAdmin, setActiveGroupAdmin] = useState({
    name: "",
    lastName: "",
    email: "",
    phone: "",
    dealerships: [],
    isNew: true,
  });
  const [isDeleteAdmin, setIsDeleteAdmin] = useState(false);
  const [adminToDelete, setadminToDelete] = useState(undefined);
  const [pagination, setPagination] = useState({
    pageNumber: 0,
    itemsOnPage: 25,
  });

  // Effects
  useEffect(() => {
    props.getGroupAdmins({ pageNumber: 0, itemsOnPage: 25 }).then((data) => {
      console.log("finished");

      const mapped = data.map((e) => {
        return { fullName: e.name + " " + e.lastName, ...e };
      });

      setGroupAdmins(mapped);
    });
  }, []);

  useEffect(() => {
    props.getAllDealerships({ pageNumber: 0, itemsOnPage: 25 }).then((data) => {
      console.log(data);

      const mapped = data?.partners?.map((e) => {
        return {
          text: e.businessName + " | " + e.fullAddress,
          selected: false,
          ...e,
        };
      });

      setAllDealerships(mapped);
    });
  }, []);

  // Functions
  const cancelNewGroupAdmin = () => {
    setDisplayPage("listAll");
    setActiveGroupAdmin({
      name: "",
      lastName: "",
      email: "",
      phone: "",
      dealerships: [],
      isNew: true,
    });
  };

  const onDealershipSelected = (event, dealership) => {
    console.log("Selected, ", dealership);
    var existingIndex = activeGroupAdmin.dealerships.findIndex(
      (d) => d.id == dealership.id
    );

    if (existingIndex < 0) {
      activeGroupAdmin.dealerships.push(dealership);
    } else {
      activeGroupAdmin.dealerships.splice(existingIndex, 1);
    }

    const updatedDealerships = allDealerships.map((dealership) => {
      const selected = activeGroupAdmin.dealerships.find(
        (d) => d.id == dealership.id
      );

      return { ...dealership, selected: selected != undefined };
    });
    console.log(activeGroupAdmin);
    setAllDealerships(updatedDealerships);
  };

  const updateNewGroupAdmin = (e, data) => {
    activeGroupAdmin[e.target.name] = e.target.value;

    setActiveGroupAdmin({ isNew: false, ...activeGroupAdmin });
  };

  const saveGroupAdmin = () => {
    console.log(activeGroupAdmin);
    var payload = {
      ...activeGroupAdmin,
      dealerships: activeGroupAdmin.dealerships.map((d) => d.id),
    };

    if (payload.isNew) {
      props.addGroupAdmin(payload).then((r) => {
        message.success("Group admin created!", 3);
        props
          .getGroupAdmins({ pageNumber: 0, itemsOnPage: 25 })
          .then((data) => {
            console.log("finished");

            const mapped = data.map((e) => {
              return { fullName: e.name + " " + e.lastName, ...e };
            });

            setGroupAdmins(mapped);
            setDisplayPage("listAll");
          });
      });
    } else {
      props.updateGroupAdmin(payload).then((r) => {
        props
          .getGroupAdmins({ pageNumber: 0, itemsOnPage: 25 })
          .then((data) => {
            console.log("finished");

            const mapped = data.map((e) => {
              return { fullName: e.name + " " + e.lastName, ...e };
            });

            setGroupAdmins(mapped);
            setDisplayPage("listAll");
            message.success("Group admin updated!", 3);
          });
      });
    }
  };

  const viewGroupAdmin = (id) => {
    props.getGroupAdminDetails(id).then((admin) => {
      const updatedDealerships = allDealerships.map((dealership) => {
        const selected = admin.dealerships.find((d) => d.id == dealership.id);

        return { ...dealership, selected: selected != undefined };
      });
      console.log(activeGroupAdmin);
      setAllDealerships(updatedDealerships);
      setActiveGroupAdmin(admin);
      setDisplayPage("viewGroupAdmin");
    });
  };

  const deleteGroupAdmin = (admin) => {
    setIsDeleteAdmin(true);
    setadminToDelete(admin);
  };

  const confirmDeleteGroupAdmin = () => {
    props.deleteGroupAdmin(adminToDelete).then((result) => {
      console.log(result);
      props.getGroupAdmins({ pageNumber: 0, itemsOnPage: 25 }).then((data) => {
        console.log(data);

        const mapped = data.map((e) => {
          return { fullName: e.name + " " + e.lastName, ...e };
        });

        setGroupAdmins(mapped);
        setIsDeleteAdmin(false);
        message.success("Group admin deleted!", 3);
      });
    });
  };

  //Rendering functions

  const renderDeleteAdminModal = () => {
    return (
      <Modal isOpen={isDeleteAdmin} toggle={() => setIsDeleteAdmin(false)}>
        <ModalHeader toggle={() => setIsDeleteAdmin(false)}>
          Confirm delete Group Admin
        </ModalHeader>
        <ModalBody>
          Are you sure you want to remove this group admin? This action can not
          be reversed.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => confirmDeleteGroupAdmin()}>
            OK
          </Button>{" "}
          <Button color="secondary" onClick={() => setIsDeleteAdmin(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const renderNewGroupAdmin = () => {
    return (
      <NewGroupAdmin
        activeGroupAdmin={activeGroupAdmin}
        cancelNewGroupAdmin={cancelNewGroupAdmin}
        dealerships={allDealerships}
        onDealershipSelected={onDealershipSelected}
        onGroupAdminChange={updateNewGroupAdmin}
        saveGroupAdmin={saveGroupAdmin}
      />
    );
  };

  const renderListing = (dataSet) => {
    return (
      <Card>
        <CardHeader>
          <CardTitle tag="h5">Dealership Group Admins</CardTitle>
          <div className="float-right">
            <Button
              onClick={() => setDisplayPage("newGroupAdmin")}
              color="secondary"
              size="md"
              className="btn-round mr-2">
              <i className="fa fa-plus mr-2" /> Add group admin
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <ReactTable
            data={dataSet}
            columns={[
              {
                Header: "Name",
                accessor: "fullName",
                sortable: false,
              },
              {
                Header: "Email",
                accessor: "email",
                sortable: false,
              },
              {
                Header: "View",
                accessor: "id",
                Cell: (tableProps) => (
                  <Button
                    onClick={() => viewGroupAdmin(tableProps.row.original.id)}>
                    View
                  </Button>
                ),
              },
              {
                Header: "Delete",
                Cell: (tableProps) => (
                  <Button
                    color="danger float-right"
                    onClick={() => deleteGroupAdmin(tableProps.row.original)}>
                    Delete
                  </Button>
                ),
              },
            ]}
          />
        </CardBody>
      </Card>
    );
  };

  //Renderer
  return (
    <div className="content users">
      <Row>
        <Col xs={12} md={12}>
          {displayPage == "listAll"
            ? renderListing(groupAdmins)
            : renderNewGroupAdmin()}
          {renderDeleteAdminModal()}
        </Col>
      </Row>
    </div>
  );
}

/*State hook up*/
function mapStateToProps(state) {
  return state;
}
export default connect(mapStateToProps, actionCreators)(GroupAdminPage);
