import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
} from "reactstrap";
// import "./style.css";
import CreateCategory from "./Create";
import EditCategory from "./Edit";

const thead = ["Name", "Date", "Actions"];
const tbody = [
  {
    className: "table-success",
    data: ["Dakota Rice", "Niger", "Oud-Turnhout", "(123)-456-7895"],
  },
  {
    className: "",
    data: ["Minerva Hooper", "Curaçao", "Sinaai-Waas", "(123)-456-7895"],
  },
  {
    className: "table-info",
    data: ["Sage Rodriguez", "Netherlands", "Baileux", "(123)-456-7895"],
  },
  {
    className: "",
    data: ["Philip Chaney", "Korea, South", "Overland Park", "(123)-456-7895"],
  },
  {
    className: "table-danger",
    data: [
      "Doris Greene",
      "Malawi",
      "Feldkirchen in Kärnten",
      "(123)-456-7895",
    ],
  },
  {
    className: "",
    data: ["Mason Porter", "Chile", "Gloucester", "(123)-456-7895"],
  },
  {
    className: "",
    data: ["Jon Porter", "Portugal", "Gloucester", "(123)-456-7895"],
  },
];

function Categories() {
  const [newContactModal, setNewContactModal] = React.useState(false);
  const [page, setPage] = React.useState("1");

  const createButton = () => {
    setPage("2");
  };
  return (
    <>
      {page === "1" ? (
        <div className="content users">
          <Row>
            <Col lg={7} md={12} sm={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">
                    <div>Categories</div>
                  </CardTitle>
                  <div className="float-right">
                    <Button
                      onClick={() => setPage("2")}
                      color="secondary"
                      size="md"
                      className="btn-round mr-2">
                      <i className="fa fa-plus mr-2" /> Create
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="users-table-head">
                      <tr>
                        {thead.map((prop, key) => {
                          if (key === thead.length - 1)
                            return (
                              <th key={key} className="">
                                {prop}
                              </th>
                            );
                          return <th key={key}>{prop}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Trade</td>

                        <td>06 /Apr/ 2020</td>

                        <td>
                          <div>
                            <Button
                              onClick={() => setPage("3")}
                              className="btn-icon btn-round mr-2"
                              color="warning"
                              size="sm">
                              <i className="fa fa-edit" />
                            </Button>
                            <Button
                              onClick={() =>
                                (window.location.href = "/admin/users/edit")
                              }
                              className="btn-icon btn-round"
                              title="Delete"
                              color="danger"
                              size="sm">
                              <i className="fa fa-times" />
                            </Button>{" "}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Request Money</td>

                        <td>06 /Apr/ 2020</td>

                        <td>
                          <div>
                            <Button
                              onClick={() => setPage("3")}
                              className="btn-icon btn-round mr-2"
                              color="warning"
                              size="sm">
                              <i className="fa fa-edit" />
                            </Button>
                            <Button
                              onClick={() =>
                                (window.location.href = "/admin/users/edit")
                              }
                              className="btn-icon btn-round"
                              title="Delete"
                              color="danger"
                              size="sm">
                              <i className="fa fa-times" />
                            </Button>{" "}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Request Money</td>

                        <td>06 /Apr/ 2020</td>

                        <td>
                          <div>
                            <Button
                              onClick={() => setPage("3")}
                              className="btn-icon btn-round mr-2"
                              color="warning"
                              size="sm">
                              <i className="fa fa-edit" />
                            </Button>
                            <Button
                              onClick={() =>
                                (window.location.href = "/admin/users/edit")
                              }
                              className="btn-icon btn-round"
                              title="Delete"
                              color="danger"
                              size="sm">
                              <i className="fa fa-times" />
                            </Button>{" "}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Send Money</td>

                        <td>06 /Apr/ 2020</td>

                        <td>
                          <div>
                            <Button
                              onClick={() => setPage("3")}
                              className="btn-icon btn-round mr-2"
                              color="warning"
                              size="sm">
                              <i className="fa fa-edit" />
                            </Button>
                            <Button
                              onClick={() =>
                                (window.location.href = "/admin/users/edit")
                              }
                              className="btn-icon btn-round"
                              title="Delete"
                              color="danger"
                              size="sm">
                              <i className="fa fa-times" />
                            </Button>{" "}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      ) : page === "2" ? (
        <CreateCategory setPage={setPage} />
      ) : (
        <EditCategory setPage={setPage} />
      )}
    </>
  );
}

export default Categories;
