let ApiURL = "";
if (process.env.NODE_ENV === "development") {
  ApiURL = "https://api.staging.exclusive-rewards.vogdevelopment.com";
}
if (process.env.NODE_ENV === "uat") {
  ApiURL = "https://api.uat.exclusive-rewards.vogdevelopment.com";
}
if (process.env.NODE_ENV === "production") {
  ApiURL = "https://api.uat.exclusive-rewards.vogdevelopment.com";
}
export const baseURL = ApiURL;
