import * as actionTypes from "../action-types"
import axios from "axios"
import { addOffer, approveOffer, deleteOffer, rejectOffer, getOffer, getOfferCount,getCategory, getCouponAnalytics, getOfferDetails } from "../../api/offer"
import { getIntesets } from "../../api/intesetsCategory"

const actionCreators = {
  getIntesets(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
          let res = await getIntesets(data)
          resolve(res)
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
        }
      })
  },
  getCategory(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
          let res = await getCategory(data)
          resolve(res)
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
        }
      })
  },
  addOffer(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
          let res = await addOffer(data)
          resolve(res)
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
        }
      })
  },
  getOffer(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
          let res = await getOffer(data)
          resolve(res)
        } catch (error) {
          reject(error)
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
        }
      })
  },
  getOfferCount(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
          let res = await getOfferCount(data)
          resolve(res)
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
        }
      })
  },
  approveOffer(id) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
          let res = await approveOffer(id)
          resolve(res)
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
        }
      })
  },
  rejectOffer(id, data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
          let res = await rejectOffer(id, data)
          resolve(res)
        } catch (error) {
          reject(error)
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
        }
      })
  },
  deleteOffer(id) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
          let res = await deleteOffer(id)
          resolve(res)
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
        }
      })
  },
  getCouponAnalytics(partnerId, offerId, data) {
    return (dispatch) =>
      new Promise(async(resolve, reject) => {
        try {
          let res = await getCouponAnalytics(partnerId, offerId, data);
          resolve(res);
        } catch(error){
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
        }
      })
  },

  getOfferDetails(data) {
    return (dispatch) =>
    new Promise(async(resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await getOfferDetails(data);
        resolve(res);
      } catch(error){
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    })
  },
}

export default actionCreators
