import React from "react"
import cookie from "react-cookie"
// reactstrap components
import { connect } from "react-redux"
import actionCreators from "../../../../store/actionCreators/login"
import { Card, CardBody, CardHeader, CardFooter, CardTitle, Container, Col, FormGroup, Input, Button, Label } from "reactstrap"
import { Link } from "react-router-dom"
import { Checkbox } from "antd"
// core components
import logo from "assets/img/ER Logo Black.png"
import "../style.css"
import bgImage from "assets/img/IMG_Main_car.png"
import { message } from "antd"
function ResetPassword(props) {
  let [email, setEmail] = React.useState(cookie.load("email") || "")
  let [hasError, setHasError] = React.useState(false)
  let [errorMessage, setErrorMessage] = React.useState("")
  let [password, setPassword] = React.useState("")
  let [confirmedPassword, setConfirmedPassword] = React.useState("")
  let [verifyCode, setVerifyCode] = React.useState("")

  React.useEffect(() => {
    document.body.classList.add("lock-page")
    return function cleanup() {
      document.body.classList.remove("lock-page")
    }
  }, [])
  const emailChange = (e) => setEmail(e.target.value)
  const passwordChange = (e) => setPassword(e.target.value)
  const confirmedPasswordChange = (e) => setConfirmedPassword(e.target.value)
  const verifyCodeChange = (e) => setVerifyCode(e.target.value)

  const handleResentCode = () => {
    //send forget password request and redirect to verify page
    if (email == "" || email == null) {
      message.error("please enter your email")
      return
    }
    props
      .forgetPassword({ email })
      .then((res) => {
        message.success("Email has been sent to you.")
        // props.history.push("/auth/reset-password-page")
      })
      .catch((e) => {
        message.error("Some error occurs, please try again later.")
      })
  }

  const handleResetPassword = () => {
    if (email == "" || email == null) {
      message.error("please enter your email")
      return
    }
    if (password == "" || password == null) {
      message.error("please enter your password")
      return
    }
    if (confirmedPassword == "" || confirmedPassword == null) {
      message.error("please confirm your password")
      return
    }
    if (verifyCode == "" || verifyCode == null) {
      message.error("please enter your verify code")
      return
    }
    if (password !== confirmedPassword) {
      message.error("please make sure your passwords are matched.")
      return
    }
    let obj = {
      email,
      password,
      passwordConfirmation: confirmedPassword,
      code: verifyCode,
    }
    console.log(obj)
    props
      .resetPassword(obj)
      .then((res) => {
        message.success("Password has been reset.")
        props.history.push("/auth/login-page")
      })
      .catch((e) => {
        setErrorMessage(e.response.data.message)
        setHasError(true)
      })
  }

  return (
    <>
      <div className="content">
        <div className="lock-page">
          <Container>
            <Col md={8} xs={12} className="mr-auto ml-auto ">
              <Card className="card-lock text-center">
                <CardHeader>
                  <img style={{ width: "60px" }} src={logo} alt="ER-logo" />
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <CardTitle tag="h4">Reset Password</CardTitle>
                    <Label style={{ float: "left" }}>Email</Label>
                    <Input type="email" placeholder="Enter email" value={email} onChange={emailChange} />
                  </FormGroup>
                  <FormGroup>
                    <Label style={{ float: "left" }}>Password</Label>
                    <Input type="password" placeholder="Enter Password..." value={password} onChange={passwordChange} />
                  </FormGroup>
                  <FormGroup>
                    <Label style={{ float: "left" }}>Confirm Password</Label>
                    <Input type="password" placeholder="Confirm Password..." value={confirmedPassword} onChange={confirmedPasswordChange} />
                  </FormGroup>
                  <FormGroup>
                    <Label style={{ float: "left" }}>Verify Code</Label>
                    <Input type="password" placeholder="Enter Verify Code..." value={verifyCode} onChange={verifyCodeChange} />
                  </FormGroup>
                  <FormGroup>
                    <div className="flex">
                      <button type="button" className="btn btn-Secondary" onClick={() => props.history.push("/")}>
                        Cancel
                      </button>
                      <button type="button" className="btn btn-outline-primary" onClick={handleResentCode}>
                      Resend verification code
                      </button>
                      <button type="button" className="btn btn-Secondary" onClick={handleResetPassword}>
                        Reset Password
                      </button>
                    </div>
                    <br></br>
                    {hasError ? (
                      <>
                        <div className="alert alert-warning">{errorMessage}</div>
                        <div className="alert alert-danger" role="alert">
                          <div className="text-left">1. Please make sure you have provided valid email and verification code.</div>
                          <div className="text-left">2. Please make sure you have your passwords matched.</div>
                          <div className="text-left">
                            3. Please make sure your password is long enough at the same contains uppercase, lowercase and special characters.
                          </div>
                          <div className="text-left">4. If you have exceeded attempt limits, please wait for 15mins to try again.</div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </FormGroup>
                </CardBody>

                {/* <CardFooter>
                  <button className="login-button" onClick={() => alert("test")}>
                    Reset Password
                  </button>
                </CardFooter> */}
              </Card>
            </Col>
          </Container>
        </div>
      </div>
      <div className="full-page-background" style={{ backgroundImage: "url(" + bgImage + ")" }} />
    </>
  )
}
function mapStateToProps(state) {
  return state.user
}
export default connect(mapStateToProps, actionCreators)(ResetPassword)
