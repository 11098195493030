/*eslint-disable*/
import React from "react";
import { NavLink } from "react-router-dom";
// used for making the prop types of this component
import PropTypes from "prop-types";

import "./style.css";
// reactstrap components
import { Nav, Collapse, Button } from "reactstrap";
import swal from "sweetalert2";

// core components
import avatar from "assets/img/ryan.jpg";
import logo from "logo-white.svg";
import quiltLogo from "assets/img/ER Logo Black.png";
import { connect } from "react-redux";
import actionCreators from "store/actionCreators/login";

var ps;

function Sidebar(props) {
  const [openAvatar, setOpenAvatar] = React.useState(false);
  const [collapseStates, setCollapseStates] = React.useState({});
  const sidebar = React.useRef();

  React.useEffect(() => {
    setCollapseStates(getCollapseStates(props.routes));
  }, []);
  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };

  const renderIcons = (params, params2) => {
    switch (params) {
      case "More":
        return <i className="fas fa-bars"></i>;
      case "Contacts":
        return <i className="fas fa-user-friends"></i>;
      case "Development":
        return <i className="fas fa-code"></i>;
      case "Sign Out":
        return <i className="fas fa-sign-out-alt"></i>;
      case "Help":
        return <i className="far fa-question-circle"></i>;
      case "Reports":
        return <i className="far fa-file-alt"></i>;
      default:
        return <i className={params2} />;
    }
  };
  const signOutButton = () => {
    swal
      .fire({
        text: "Are you sure you want to Sign out?",
        showCancelButton: true,
        confirmButtonColor: "#392E8B",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
      .then((result) => {
        console.log(result);
        if (result.isConfirmed) {
          props.logout();
          window.location.href = "/auth/login-page";
        } else {
        }
      });
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.invisible) return null;
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !collapseStates[prop.state];
        return (
          <li
            className={getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}>
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={collapseStates[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                setCollapseStates(st);
              }}>
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={collapseStates[prop.state]}>
              <ul className="nav">{createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }

      return (
        <li
          // style={
          //   prop.name === "Development" && window.innerWidth > 1919
          //     ? { marginTop: 180 }
          //     : null
          // }
          className={activeRoute(prop.layout + prop.path)}
          key={key}>
          <NavLink to={prop.layout + prop.path} activeClassName="">
            {prop.icon !== undefined ? (
              <>
                {renderIcons(prop.name, prop.icon)}
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  };
  return (
    <>
      <div className="sidebar" data-color={props.backgroundColor}>
        <div className="logo">
          <img className="px-3" src={quiltLogo} alt="quilt-logo" />
        </div>

        <div className="sidebar-wrapper" ref={sidebar}>
          <Nav>{createLinks(props.routes)}</Nav>

          <div onClick={signOutButton} className="sign-out-button">
            <div className="sign-out-icon">
              <i className="fas fa-sign-out-alt"></i>
            </div>
            <p className="sign-out-text">SIGN OUT</p>
          </div>
        </div>
      </div>
    </>
  );
}

Sidebar.defaultProps = {
  routes: [],
  showNotification: false,
  backgroundColor: "blue",
  minimizeSidebar: () => {},
};

Sidebar.propTypes = {
  // links that are rendered
  routes: PropTypes.arrayOf(PropTypes.object),
  // if you want to show a notification when switching between mini sidebar and normal
  showNotification: PropTypes.bool,
  // background color for the component
  backgroundColor: PropTypes.oneOf([
    "blue",
    "yellow",
    "green",
    "orange",
    "red",
  ]),
  // function that is called upon pressing the button near the logo
  minimizeSidebar: PropTypes.func,
};

function mapStateToProps(state) {
  return state.user;
}

export default connect(mapStateToProps, actionCreators)(Sidebar);
