import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

function UserDetails({ setPage }) {
  const backButton = () => {
    setPage("1");
  };
  return (
    <>
      <div className="content paybill">
        <Row>
          <Col md="7">
            <Card>
              <CardHeader className="paybill-header">
                <h5 className="title ml-2">User Details</h5>
              </CardHeader>

              <CardBody className="ml-4">
                <Form>
                  <Row>
                    <Col md="12">
                      <div className="mb-4">
                        <img
                          alt="..."
                          src="https://c.ndtvimg.com/2019-12/l2fpvv2k_baby-yoda-meme-the-mandalorian_295x200_03_December_19.jpg"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>Name</label>
                        <div>Jorge Smith</div>
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label> Address</label>
                        <div>1157 11 Ave SW</div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>Beneficiary</label>
                        <div>RBC</div>
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>Address</label>
                        <div>1157 11 Ave SW</div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-1" md="12">
                      <FormGroup>
                        <label>Memo</label>
                        <div>memo here</div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="12">
                      <FormGroup>
                        <label>Purpose </label>
                        <div>Purpose here</div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md="6"
                      className="footer-buttons d-flex justify-content-start">
                      <Button
                        color="primary"
                        size="md"
                        className="btn-round"
                        onClick={backButton}>
                        Go back
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default UserDetails;
