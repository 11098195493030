import axios from "../utils/request"

export function getCities(provinceID) {
  return axios({
    url: `/api/v1/Locations/cities/by-province/${provinceID}`,
    method: "get",
  })
}
export function getProvinces(countryId) {
  return axios({
    // url: `/api/v1/Locations/provinces/by-country/${countryId}`,
    url: `/api/v1/Locations/provinces/by-country/${countryId}`,
    method: "get",
  })
}

