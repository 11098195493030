import React, { useEffect } from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
} from "reactstrap";
// import "./style.css";
import CreateOffer from "./Create";
import ReactTable from "components/ReactTable/ReactTable.js";
import actionCreators from "../../../../store/actionCreators/intesetsCategory";
import { connect } from "react-redux";
import { message } from "antd";

function AddTags(props) {
  const [newContactModal, setNewContactModal] = React.useState(false);
  const [page, setPage] = React.useState("1");
  const [dataState, setDataState] = React.useState([]);
  useEffect(() => {
    getList();
  }, [page]);

  const deleteInterest = (interest) => {
    props.deleteInterest(interest).then((res) => {
      message.success("Interest deleted", 3);
      getList();
    });
  };

  const getList = () => {
    props.getIntesets().then((res) => {
      let dataTable = res.map((prop, key) => {
        return {
          id: key,
          name: prop.name,
          category: prop.category,
          img: prop.picture,
          actions: (
            // we've added some custom button actions
            <div key={key} className="actions-right">
              {/* use this button to add a edit kind of action */}
              <Button
                onClick={() => deleteInterest(prop)}
                disabled={!prop.deleteable}
                className="btn-icon btn-round"
                color="warning"
                size="sm">
                <i className="fa fa-trash" />
              </Button>
            </div>
          ),
        };
      });
      setDataState(dataTable);
    });
  };

  return (
    <>
      {page === "1" ? (
        <div className="content users">
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">
                    <div>Interests</div>
                  </CardTitle>
                  <div className="float-right">
                    <Button
                      onClick={() => setPage("2")}
                      color="secondary"
                      size="md"
                      className="btn-round mr-2">
                      <i className="fa fa-plus mr-2" /> Create
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={dataState}
                    showPagination
                    columns={[
                      {
                        Header: "Interest Name",
                        accessor: "name",
                        sortable: false,
                      },
                      {
                        Header: "Category",
                        accessor: "category",
                        sortable: false,
                      },
                      {
                        Header: "Image",
                        accessor: "img",
                        Cell: (tableProps) => (
                          <div className="">
                            <img
                              src={tableProps.row.original.img}
                              width={50}
                              alt="Picture"
                            />
                          </div>
                        ),
                      },
                      {
                        Header: "Delete",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      ) : page === "2" ? (
        <CreateOffer setPage={setPage} />
      ) : (
        ""
      )}
    </>
  );
}

function mapStateToProps(state) {
  return state.user;
}

export default connect(mapStateToProps, actionCreators)(AddTags);
