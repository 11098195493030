import * as actionTypes from "../action-types";
import {
  getDealershipPartnerCount,
  getLifeStylePartnerCount,
  getCorporatePartnerCount,
  addPartner,
  getDealershipPartner,
  getLifeStylePartner,
  getCorporatePartner,
  getPartnerDetails,
  updatePartner,
  updateTierStatus,
  searchPartner,
  upgradeLifestylePartner,
  deleteEnterpriseLocation,
  saveEnterpriseLocations,
  uploadCsv,
  processCsv,
  getBillingsCalendar,
  getPartnerOffers,
  getPartnerOffersCount,
  getPartnerQualifyingPurchasesCount,
  getPartnerQualifyingPurchases,
  getMemberTiers,
  updatePartnerTier,
  updatePartnerStartOfBillingCycle,
  getPartnerActiveUsers,
  unPauseTier,
  getEventsList,
} from "../../api/partner";
import { getCities, getProvinces } from "../../api/locations";
import { getIntesets } from "../../api/intesetsCategory";
const actionCreators = {
  addPartner(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await addPartner(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
  getPartnerDetails(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await getPartnerDetails(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
  getMemberTiers(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await getMemberTiers(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
  updatePartnerTier(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await updatePartnerTier(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
  updateTierStatus(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await updateTierStatus(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
  getCities(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await getCities(data);
          dispatch({ type: actionTypes.GET_CITY, payload: res });
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
  getProvinces(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await getProvinces(data);
          dispatch({ type: actionTypes.GET_PROVINCES, payload: res });
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
  getIntesets(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await getIntesets(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
  //feeco added
  //feeco added
  //feeco added
  getDealershipPartner(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await getDealershipPartner(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
  getLifeStylePartner(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await getLifeStylePartner(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
  getCorporatePartner(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await getCorporatePartner(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
  updatePartner(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await updatePartner(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
  searchPartner(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await searchPartner(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
  getLifeStylePartnerCount(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await getLifeStylePartnerCount(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
  getDealershipPartnerCount(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await getDealershipPartnerCount(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
  getCorporatePartnerCount(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await getCorporatePartnerCount(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
  upgradeLifestylePartner(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await upgradeLifestylePartner(data.id);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },

  deleteEnterpriseLocation(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await deleteEnterpriseLocation(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },

  saveEnterpriseLocations(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await saveEnterpriseLocations(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },

  uploadCsv(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await uploadCsv(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },

  processCsv(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await processCsv(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },

  getBillingsCalendar(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await getBillingsCalendar(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },

  updatePartnerStartOfBillingCycle(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await updatePartnerStartOfBillingCycle(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },

  getPartnerOffersCount(id, data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await getPartnerOffersCount(id, data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },

  getPartnerOffers(id, data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await getPartnerOffers(id, data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },

  getPartnerQualifyingPurchasesCount(id, data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await getPartnerQualifyingPurchasesCount(id, data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },

  getPartnerQualifyingPurchases(id, data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await getPartnerQualifyingPurchases(id, data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },

  getPartnerActiveUsers(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await getPartnerActiveUsers(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },

  unPauseTier(id) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await unPauseTier(id);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
  getEventsList(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await getEventsList(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
};
export default actionCreators;
