import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Input,
} from "reactstrap";
// import "./style.css";

import ReactTable from "components/ReactTable/ReactTable.js";
import actionCreators from "store/actionCreators/groupAdmin";
import { connect } from "react-redux";
import { message, Pagination } from "antd";
import { useEffect } from "react";

function SubscribedUsers(props) {
  const [pageNumber, setPageNumber] = React.useState(0);
  const [add, setAdd] = React.useState(false);
  const [item, setItem] = React.useState("");
  const [dataState, setDataState] = React.useState([]);
  const [itemsOnPage, setItemsOnPage] = React.useState(10);
  const [totalPages, setTotalPages] = React.useState(10);
  const [name, setName] = React.useState("");

  const [totalItems, setTotalItems] = React.useState("");
  const [approveOffer, setApproveOffer] = React.useState(false);

  useEffect(() => {
    getList();
  }, [pageNumber]);

  const getList = (search) => {
    let data = { ItemsOnPage: itemsOnPage, PageNumber: pageNumber, search };
    props.getSubscribedAdmins(data).then((res) => {
      let arr = res?.members;
      setTotalItems(res?.totalItems);
      setTotalPages(res?.totalPages);
      if (arr) {
        let dataState = arr.map((item, key) => {
          return {
            id: key,
            fullName: item?.fullName,
            email: item?.email,
            gender: item?.gender,
            subscriptionStatus: item?.subscriptionStatus
              ?.replace(/([A-Z])/g, " $1")
              .trim(),
            redeemedOffers: item?.redeemedOffers,
            CreatedTime: item.accountCreatedDate,
            status: <div className="actions-right"> {item?.userStatus}</div>,
          };
        });
        // console.log(dataState);
        setDataState(dataState);
      }
    });
  };
  const onShowSizeChange = (pageNumber, pageSize) => {
    setPageNumber(pageNumber);
    getList(pageNumber - 1);
  };

  const onNextPage = () => {
    let x = pageNumber + 1;
    console.log(x);
    // setPageNumber(JSON.parse(x));
  };

  const onKeyDown = (e) => {
    var theEvent = window.event || e;
    var code = theEvent.keyCode || theEvent.which || theEvent.charCode;
    setPageNumber(0);
    if (code == 13) {
      if (e.target.value == "") {
        getList();
      } else {
        getList(e.target.value);
      }
    }
  };

  return (
    <>
      <div className="content users">
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h5">
                  <div>Subscribed Users</div>
                </CardTitle>
                {/* <div className="float-right">
                    <Button
                      onClick={() => setPageNumber("2")}
                      color="secondary"
                      size="md"
                      className="btn-round mr-2">
                      <i className="fa fa-plus mr-2" /> Create
                    </Button>
                  </div> */}
              </CardHeader>
              <CardBody>
                <Row>
                  <Col className="" md="12">
                    <FormGroup>
                      <Input
                        placeholder="Search by Email"
                        type="text"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        onKeyDown={onKeyDown}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <ReactTable
                  data={dataState}
                  columns={[
                    {
                      Header: "Full name",
                      accessor: "fullName",
                      sortable: false,
                    },
                    {
                      Header: "Email",
                      accessor: "email",
                      sortable: false,
                    },
                    {
                      Header: "Gender",
                      accessor: "gender",
                      sortable: false,
                    },
                    {
                      Header: "Subscription Status",
                      accessor: "subscriptionStatus",
                      sortable: false,
                    },

                    {
                      Header: "Redeemed Offers",
                      accessor: "redeemedOffers",
                      sortable: false,
                    },
                    {
                      Header: "Created on",
                      sortable: false,
                      Cell: (tableProps) => (
                        <span>
                          {new Date(
                            tableProps.row.original.CreatedTime
                          ).toLocaleString("en-US", {
                            timeZone:
                              Intl.DateTimeFormat().resolvedOptions().timeZone,
                          })}
                        </span>
                      ),
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                />
                <Row>
                  {/* <Col md="12">
                    <div className="erPagination">
                      <Pagination
                        showSizeChanger={false}
                        current={pageNumber}
                        defaultPageSize={itemsOnPage}
                        pageSize={itemsOnPage}
                        total={totalItems}
                        onChange={onShowSizeChange}
                      />
                    </div>
                  </Col> */}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <nav
          className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
          aria-label="Pagination">
          <div className="hidden sm:block my-auto">
            <p className="text-sm text-gray-700">
              On Page <span className="font-medium">{pageNumber + 1}</span> of{" "}
              {totalPages}
              {", "}
              <span className="font-medium">Total results</span>
              {": "}
              <span className="font-medium">{totalItems}</span>
            </p>
          </div>
          <div className="flex flex-1 justify-between sm:justify-end">
            <Button
              disabled={pageNumber === 0}
              onClick={() => setPageNumber(pageNumber - 1)}>
              Previous
            </Button>
            <Button
              disabled={pageNumber + 1 === totalPages}
              onClick={() => setPageNumber(pageNumber + 1)}>
              Next
            </Button>
          </div>
        </nav>
      </div>

      {/* {pageNumber === "2" && <CreateOffer setPageNumber={setPageNumber} />}
      {pageNumber === "3" && (
        <EditOffer
          setPageNumber={setPageNumber}
          onApprove={() => setApproveOffer(true)}
          item={item}
        />
      )} */}
    </>
  );
}

function mapStateToProps(state) {
  return state.user;
}
export default connect(mapStateToProps, actionCreators)(SubscribedUsers);
