import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import CardCustom from "components/Card/Card.js";
import CardHeaderCustom from "components/Card/CardHeader.js";
import CardIconCustom from "components/Card/CardIcon.js";
import CardBodyCustom from "components/Card/CardBody.js";
import CardFooterCustom from "components/Card/CardFooter.js";
import Donations from "../DonationHistory/";
import actionCreators from "store/actionCreators/partner.js";
import { connect } from "react-redux";
import leftIcon from "../../../../../assets/icons/icon-left.png";
import eRLogo from "../../../../../assets/img/ER Logo Black.png";

import EditCampaign from "../EditPartner";
import { Title } from "chart.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import { Pagination, DatePicker } from "antd";
import moment from "moment";
import Select from "react-select";
import CouponAnalytics from "../../CouponAnalytics";
import { makeStyles } from "@material-ui/core/styles";
import "../../../../../style.css";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
const useStyles = makeStyles(styles);

function CampaignDetails({
  page,
  setPage,
  item,
  getMemberTiers,
  getPartnerDetails,
  getPartnerOffers,
  getPartnerOffersCount,
  updateTierStatus,
  updatePartnerTier,
  updatePartnerStartOfBillingCycle,
  getPartnerActiveUsers,
  unPauseTier,
  getEventsList,
}) {
  const classes = useStyles();
  const [details?, setDetails] = React.useState(item);
  const [itemsOnPageOffers, setItemsOnPageOffers] = React.useState(10);
  const [pageNumberOffers, setPageNumberOffers] = React.useState(1);
  const [totalItemsOffers, setTotalItemsOffers] = React.useState("");
  const [selectedOffer, setSelectedOffer] = React.useState(null);
  const [offers, setOffers] = React.useState([]);
  const [qualifyingPurchases, setQualifyingPurchase] = React.useState([]);
  const [itemsOnPageQualifying, setItemsOnPageQualifying] = React.useState(10);
  const [pageNumberQualifying, setPageNumberQualifying] = React.useState(1);
  const [totalItemsQualifying, setTotalItemsQualifying] = React.useState("");
  const [referralRewardsProgram, setReferralRewardsProgram] = React.useState(
    []
  );
  const [startOfBillingCycle, setStartOfBillingCycle] = React.useState("");
  const [tierOptions, setTierOptions] = React.useState([]);
  const [selectedTier, setSelectedTier] = React.useState(null);
  const [activeUsersMonth, setActiveUsersMonth] = React.useState("");
  const [activeUsersSixMonths, setActiveUsersSixMonths] = React.useState("");

  const [Events, setEvents] = React.useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchValue, setSearchValue] = useState(undefined);
  const [itemsOnPageEvents, setItemsOnPageEvents] = React.useState(10);
  const [pageNumberEvents, setPageNumberEvents] = React.useState(1);
  const [totalItemsEvents, setTotalItemsEvents] = React.useState("");

  const backButton = () => {
    setPage("1");
  };

  const editButton = () => {
    setPage("3");
  };
  useEffect(() => {
    updatePartnerDetails();
  }, []);
  useEffect(() => {
    getOfferList(pageNumberOffers - 1);
  }, [pageNumberOffers]);

  useEffect(() => {
    getEvents(pageNumberEvents - 1);
  }, [pageNumberEvents]);

  const getEvents = (pageNumber) => {
    let obj = {
      pageNumber: pageNumber,
      itemsOnPage: itemsOnPageEvents,
      partnerId: item?.id,
    };
    if (searchValue) {
      obj.search = searchValue;
    }
    getEventsList(obj).then((res) => {
      let arr = res;
      console.log(arr);
      if (res) {
        setTotalItemsEvents(res?.totalItems);
        setItemsOnPageEvents(10);
        setTableData(res?.events);
      }
    });
  };

  const onChangePageEvents = (page, pageSize) => {
    setPageNumberEvents(page);
  };

  const onKeyDown = (e) => {
    var theEvent = window.event || e;
    var code = theEvent.keyCode || theEvent.which || theEvent.charCode;
    if (code == 13) {
      getEvents(pageNumberEvents - 1);
    }
  };

  const getOfferList = (pageNumber) => {
    getPartnerOffersCount(item.id).then(({ totalItems }) => {
      setTotalItemsOffers(totalItems);
      setItemsOnPageOffers(10);
      getPartnerOffers(item.id, {
        pageNumber: pageNumber,
        itemsOnPage: itemsOnPageOffers,
      }).then((res) => {
        let arr = res;
        console.log(arr);
        if (arr) {
          let offers = arr.map((prop, key) => {
            return {
              id: key,
              title: prop.title,
              viewOffer: (
                <div className="actions-right">
                  <a
                    className="underline"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      setPage("4");
                      setSelectedOffer(prop);
                    }}>
                    View Analytics
                  </a>
                </div>
              ),
            };
          });
          console.log(offers);
          setOffers(offers);
        }
      });
    });
  };

  const nFormatter = (num, digits) => {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "K" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find((item) => {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  };
  const getActiveUsers = () => {
    getPartnerActiveUsers({ id: item.id }).then((res) => {
      let totalLastMonth = nFormatter(res.totalLastMonth, 1);
      let totalLastSixMonths = nFormatter(res.totalLastSixMonths, 1);
      setActiveUsersMonth(totalLastMonth);
      setActiveUsersSixMonths(totalLastSixMonths);
    });
  };
  const updatePartnerDetails = () => {
    getPartnerDetails(item.id).then((res) => {
      console.log(res);
      setDetails(res);
      setStartOfBillingCycle(moment(details?.startOfBillingCycle));
      let referralRewardsProgram = res?.rewardsProgramTiers?.map(
        (prop, key) => {
          return {
            tierNumber: "Tier " + (key + 1),
            referralTierId: prop.referralTierId,
            title: prop.title,
            referralRange: prop.minReferrals + " - " + prop.maxReferrals,
            earningPerReferral: "$" + prop.earningPerReferral,
            successfulReferrals: prop.successfulReferrals,
            approve: (
              <div key={key} className="actions-left">
                <Input
                  type="radio"
                  checked={prop.referralTierStatus.id === 2}
                  disabled={prop.isPaused}
                  onChange={(e) => {
                    onApproval(prop, 2);
                  }}></Input>
                <label>Approved</label>
              </div>
            ),
            decline: (
              <div key={key} className="actions-left">
                <Input
                  type="radio"
                  checked={prop.referralTierStatus.id === 3}
                  disabled={prop.isPaused}
                  onChange={(e) => {
                    onApproval(prop, 3);
                  }}></Input>
                <label>Declined</label>
              </div>
            ),
            pause: (
              <div key={key} className="actions-right">
                <Input
                  type="checkbox"
                  checked={prop.isPaused}
                  onChange={(e) => {
                    console.log(prop);
                    onPause(prop);
                  }}></Input>
                <label> </label>
              </div>
            ),
          };
        }
      );
      setReferralRewardsProgram(referralRewardsProgram);
      getMemberTiers().then((res) => {
        console.log(res);
        let tierOptions = res.map((prop, key) => {
          return {
            value: prop.membersTierId,
            label: prop.tierName,
          };
        });
        setTierOptions(tierOptions);
        setSelectedTier(
          tierOptions.filter((tiers) => {
            return tiers.label === details?.membersTier;
          })
        );
      });
      getActiveUsers();
    });
  };

  const onChangePageOffers = (page, pageSize) => {
    setPageNumberOffers(page);
  };
  const onChangePageQualifying = (page, pageSize) => {
    setPageNumberQualifying(page);
  };
  const onChangeTier = (tier) => {
    setSelectedTier(tier);
    updatePartnerTier({ partnerId: item.id, tierId: tier.value });
  };
  const onChangeStartOfBillingCycle = (date) => {
    setStartOfBillingCycle(date);
    updatePartnerStartOfBillingCycle({
      partnerId: item.id,
      billingCycleStart: moment(date).format("YYYY/MM/DD"),
    });
  };
  const onPause = (item) => {
    console.log(item);
    if (item.isPaused) {
      unPauseTier(item.referralTierId).then((res) => {
        updatePartnerDetails();
      });
    } else {
      updateTierStatus({ id: item.referralTierId, statusCode: 4 }).then(
        (res) => {
          updatePartnerDetails();
        }
      );
    }
  };
  const onApproval = (item, status) => {
    updateTierStatus({
      id: item.referralTierId,
      statusCode: item.referralTierStatus.id === status ? 1 : status,
    }).then((res) => {
      updatePartnerDetails();
    });
  };

  return (
    <>
      {page === "2" && (
        <div className="content paybill">
          <Row>
            <Col md="7">
              <div onClick={backButton} className="back-button-title mb-2">
                <img className="mb-1" width="14px" src={leftIcon} />
                Back
              </div>
              <Card>
                <CardHeader className="paybill-header">
                  <h5 className="title ml-2">Corporate Partner Details</h5>
                </CardHeader>

                <CardBody className="ml-4">
                  <Form>
                    <Row>
                      <Col md="12">
                        <div className="mb-4">
                          <img alt="..." src={details?.logo || eRLogo} />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Name</label>
                          <div>{details?.businessName}</div>
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Number of Active Offers</label>
                          <div>{item?.activeOffers}</div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Location</label>
                          <div>{item?.fullAddress}</div>
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Membership status</label>
                          <div>{details?.membershipStatus}</div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Number of Active events</label>
                          <div>{details?.activeEvents}</div>
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Number of Redeemed Offers</label>
                          <div>{details?.totalRedemptions}</div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {/*  <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Number of Exclusive Members</label>
                          <div>{details?.totalExclusiveMembers}</div>
                        </FormGroup>
                      </Col>
                     <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Number of new users</label>
                          <div>{details?.newUsers}</div>
                        </FormGroup>
                      </Col> */}
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Invitation Date</label>
                          <div>{details?.invitationDate}</div>
                        </FormGroup>
                      </Col>

                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Contact Information</label>
                          <div>
                            <label>Email: </label>{" "}
                            {details?.contactEmail
                              ? details?.contactEmail
                              : details?.email}
                          </div>

                          <div>
                            <label>Phone Number: </label>{" "}
                            {details?.contactPhoneNumber
                              ? details?.contactPhoneNumber
                              : details?.phoneNumber}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Expiry Date</label>
                          <div>{details.expiryDate}</div>
                          <label className="" style={{ fontSize: "9px" }}>
                            *This is 1 year from the date the invitation was
                            sent.
                          </label>
                        </FormGroup>
                      </Col>
                      {details.startOfBillingCycle && (
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Start of Billing Cycle</label>
                            <div> {details.startOfBillingCycle}</div>
                            <label className="" style={{ fontSize: "9px" }}>
                              *Partner will be billed every year from this date.
                            </label>
                          </FormGroup>
                        </Col>
                      )}
                    </Row>

                    {/* <Row>
                      <Col
                        md="6"
                        className="footer-buttons d-flex justify-content-start">
                        <Button
                          onClick={editButton}
                          color="primary"
                          size="md"
                          className="btn-round">
                          Edit
                        </Button>
                      </Col>
                      <Col md="6"></Col>
                    </Row> */}
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <CardCustom>
                <CardHeaderCustom color="success" stats icon>
                  <CardIconCustom color="primary">
                    <i className="fas fa-users"></i>
                  </CardIconCustom>
                  <p className={classes.cardCategory}>Active Users</p>
                  <h3
                    className={classes.cardTitle}
                    style={{
                      fontColor: "#9A9A9A",
                      fontSize: "33px",
                      opacity: "1",
                    }}>
                    {activeUsersMonth}
                  </h3>
                </CardHeaderCustom>
                <CardFooterCustom stats>
                  <div className={classes.stats}>Last month</div>
                </CardFooterCustom>
              </CardCustom>
              <br></br>
              <CardCustom>
                <CardHeaderCustom color="success" stats icon>
                  <CardIconCustom color="primary">
                    <i className="fas fa-users"></i>
                  </CardIconCustom>
                  <p className={classes.cardCategory}>Active Users</p>
                  <h3
                    className={classes.cardTitle}
                    style={{
                      fontColor: "#9A9A9A",
                      fontSize: "33px",
                      opacity: "1",
                    }}>
                    {activeUsersSixMonths}
                  </h3>
                </CardHeaderCustom>
                <CardFooterCustom stats>
                  <div className={classes.stats}>Last 6 months</div>
                </CardFooterCustom>
              </CardCustom>
            </Col>
          </Row>
          {/* Events */}
          <Row>
            <Col>
              <Card>
                <CardHeader className="paybill-header">
                  <h5 className="title ml-2">Events</h5>
                </CardHeader>
                <CardBody className="">
                  <Row>
                    <Col className="" md="12">
                      <FormGroup>
                        <Input
                          placeholder="Search"
                          type="text"
                          value={searchValue}
                          onChange={(e) => {
                            setSearchValue(e.target.value);
                          }}
                          onKeyDown={onKeyDown}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="sm:px-6 lg:px-4 bg-white">
                    <div className="mt-8 flow-root">
                      <div className="">
                        <div className="inline-block w-full py-1 align-middle">
                          {tableData?.length > 0 ? (
                            <table className="w-full border-separate border-spacing-0 eventsTable">
                              <thead>
                                <tr>
                                  <th scope="col">Name</th>
                                  <th scope="col">Start Date/Time</th>
                                  <th scope="col">End Date/Time</th>
                                  <th scope="col">Timezone</th>
                                  <th scope="col">Location</th>
                                  <th scope="col">Attendees</th>
                                  <th
                                    scope="col"
                                    className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-center text-sm font-normal text-gray-500 backdrop-blur backdrop-filter">
                                    Status
                                  </th>

                                  {/* <th
                                  scope="col">
                                  <span className="sr-only">Edit</span>
                                </th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {tableData?.map((item, personIdx) => (
                                  <tr
                                    key={item.email}
                                    className="even:bg-gray-50">
                                    <td
                                      className={
                                        "whitespace-nowrap py-1 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                                      }>
                                      {item?.title}
                                    </td>
                                    <td
                                      className={
                                        "whitespace-nowrap hidden px-3 py-1 text-sm text-gray-500 sm:table-cell"
                                      }>
                                      {item.startDate
                                        ? moment(item.startDate).format(
                                            "MM/DD/YYYY, h:mm a"
                                          )
                                        : ""}
                                    </td>
                                    <td
                                      className={
                                        "whitespace-nowrap hidden px-3 py-1 text-sm text-gray-500 lg:table-cell"
                                      }>
                                      {item.endDate
                                        ? moment(item.endDate).format(
                                            "MM/DD/YYYY, h:mm a"
                                          )
                                        : ""}
                                    </td>
                                    <td
                                      className={
                                        "whitespace-nowrap px-3 py-1 text-sm text-gray-500"
                                      }>
                                      {item.timezoneIanaId}
                                    </td>
                                    <td
                                      className={
                                        "whitespace-nowrap px-3 py-1 text-sm text-gray-500"
                                      }>
                                      {item.locationType === 0
                                        ? "In Person"
                                        : "Online"}
                                      <p className="text-gray-600 italic">
                                        {" "}
                                        {item.locationType === 0 &&
                                          item?.locationAddress}
                                      </p>
                                    </td>
                                    <td
                                      className={
                                        "whitespace-nowrap px-3 py-1 text-sm text-gray-500"
                                      }>
                                      {item.capacity
                                        ? item.capacity
                                        : "Unlimited"}
                                    </td>
                                    <td
                                      className={
                                        "whitespace-nowrap px-3 py-1 text-sm text-gray-500"
                                      }>
                                      <>
                                        {item.status == 1 ? (
                                          <span className="h-[30px] leading-[30px]  px-3 bg-[#CCF2F5] text-[#00C2CE] rounded-md flex justify-center font-bold one-clamp">
                                            Upcoming
                                          </span>
                                        ) : item.status == 2 ? (
                                          <span className="h-[30px] leading-[30px]  px-3 bg-[#E2D0EF] text-[#7217B3] rounded-md flex justify-center font-bold one-clamp">
                                            Completed
                                          </span>
                                        ) : item.status == 3 ? (
                                          <span className="h-[30px] leading-[30px]  px-3 bg-[#FFDBDB] text-[#FF4D4D] rounded-md flex justify-center font-bold one-clamp">
                                            Canceled
                                          </span>
                                        ) : item.status == 4 ? (
                                          <span className="h-[30px] leading-[30px]  px-3 bg-[#F9E3D5] text-[#F58541] rounded-md flex justify-center font-bold one-clamp">
                                            Closed
                                          </span>
                                        ) : (
                                          <span className="h-[30px] leading-[30px]  px-3 bg-slate-100 text-slate-700 rounded-md flex justify-center font-bold one-clamp">
                                            None
                                          </span>
                                        )}
                                      </>
                                    </td>

                                    {/* <td
                                    className={(
                                      personIdx !== tableData?.length - 1
                                        ? "border-b border-gray-200"
                                        : "",
                                      "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-8 lg:pr-8"
                                    )}>
                                    <a
                                      href="#"
                                      className="text-indigo-600 hover:text-indigo-900">
                                      Edit
                                      <span className="sr-only">
                                        , {item.name}
                                      </span>
                                    </a>
                                  </td> */}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <div className="px-4">No events</div>
                          )}
                          <Row>
                            <Col md="12">
                              <div className="erPagination">
                                <Pagination
                                  showSizeChanger={false}
                                  current={pageNumberEvents}
                                  defaultPageSize={itemsOnPageEvents}
                                  pageSize={itemsOnPageEvents}
                                  total={totalItemsEvents}
                                  onChange={onChangePageEvents}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Donations /> */}
          {/* <Row>
            <Col>
              <Card>
                <CardHeader className="paybill-header">
                  <h5 className="title ml-2">Referral Rewards Program</h5>
                </CardHeader>
                <CardBody className="ml-4">
                  <div className="w-full pb-6 box-content">
                    <ReactTable
                      data={referralRewardsProgram}
                      columns={[
                        {
                          Header: "Tier",
                          accessor: "tierNumber",
                          id: "columnId1",
                        },
                        {
                          Header: "Tier Name",
                          accessor: "title",
                          disableSortBy: true,
                        },
                        {
                          Header: "Successful Referral Range",
                          accessor: "referralRange",
                          disableSortBy: true,
                        },
                        {
                          Header: "Earnings per Referral",
                          accessor: "earningPerReferral",
                          disableSortBy: true,
                        },
                        {
                          Header: "Total Successful Referrals",
                          accessor: "successfulReferrals",
                          disableSortBy: true,
                        },
                        {
                          Header: "Approval Status",
                          disableSortBy: true,
                          accessor: "approve",
                        },
                        {
                          Header: " ",
                          disableSortBy: true,
                          accessor: "decline",
                        },
                        {
                          Header: "Pause",
                          accessor: "pause",
                          disableSortBy: true,
                        },
                      ]}
                    />
                    <Row>
                      <Col md="12">
                        <div className="erPagination"></div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          */}
          <Row>
            <Col>
              <Card>
                <CardHeader className="paybill-header">
                  <h5 className="title ml-2">Offers</h5>
                </CardHeader>
                <CardBody className="ml-4">
                  <div className="w-full pb-6 box-content">
                    <ReactTable
                      data={offers}
                      columns={[
                        {
                          Header: "Offer Title",
                          accessor: "title",
                          id: "columnId1",
                        },
                        {
                          Header: "View Analytics",
                          accessor: "viewOffer",
                          disableSortBy: true,
                        },
                      ]}
                    />
                    <Row>
                      <Col md="12">
                        <div className="erPagination">
                          <Pagination
                            showSizeChanger={false}
                            current={pageNumberOffers}
                            defaultPageSize={itemsOnPageOffers}
                            pageSize={itemsOnPageOffers}
                            total={totalItemsOffers}
                            onChange={onChangePageOffers}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}

      {page === "3" && <EditCampaign setPage={setPage} />}
      {page === "4" && (
        <CouponAnalytics
          setPage={setPage}
          selectedOffer={selectedOffer}
          partnerId={item.id}
        />
      )}
    </>
  );
}
function mapStateToProps(state) {
  return state.user;
}
export default connect(mapStateToProps, actionCreators)(CampaignDetails);
