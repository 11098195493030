import * as actionTypes from '../action-types';
import {
  addIntesets,
  addCategory,
  getIntesets,
  getCategory,
  deleteInterest,
} from "../../api/intesetsCategory";

const actionCreators = {
  addIntesets(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await addIntesets(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
  addCategory(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await addCategory(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
  getIntesets(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await getIntesets(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
  getCategory(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await getCategory(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },

  deleteInterest(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await deleteInterest(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
};
export default actionCreators;