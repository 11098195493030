import Dashboard from "views/Pages/Dashboard/Dashboard.js";
import Notifications from "views/Components/Notifications.js";
import Icons from "views/Components/Icons.js";
import Charts from "views/Charts/Charts.js";
import LoginPage from "views/Pages/Authentication/LoginPage/index.jsx";
import ResetPassword from "views/Pages/Authentication/ResetPassword";
import createPassword from "views/Pages/Authentication/LoginPage/createPassword.jsx";

import ViewFeedback from "views/Pages/ManageEr/ViewFeedback";
import AddBrand from "views/Pages/ManageEr/AddBrand";
import AddInterests from "views/Pages/ManageEr/AddInterests";
import AddTags from "views/Pages/ManageEr/AddTags";
import AddCategories from "views/Pages/ManageEr/AddCategories";
import InviteAdmin from "views/Pages/ManageEr/InviteAdmin";

import Feedback from "views/Pages/Feedback";

import Users from "views/Pages/Users";
import Categories from "views/Pages/Categories";

import addNewPartner from "views/Pages/Partner/addNewPartner/index.jsx";
import BusinessPartners from "views/Pages/Partner/BusinessPartners/index.jsx";
import DealershipPartners from "views/Pages/Partner/DealershipPartners/index.jsx";
import CorporatePartners from "views/Pages/Partner/CorporatePartners/index.jsx";
import Offer from "views/Pages/Offer/index.jsx";
import SubscribedUsers from "views/Pages/SubscribedUsers/index.jsx";

import Providers from "views/Pages/Providers";
import BillingsCalendar from "views/Pages/BillingsCalendar/index.jsx";

import UserDetail from "views/Pages/Users/UserDetails/index.jsx";

import TermsOfService from "views/Pages/ManageEr/TermsOfService";
import PrivacyPolicy from "views/Pages/ManageEr/PrivacyPolicy";
import About from "views/Pages/ManageEr/About";
import GroupAdminPage from "views/Pages/GroupAdmin/GroupAdminPage";

let routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "now-ui-icons business_chart-pie-36",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/users/:id",
    name: "Users",
    icon: "now-ui-icons users_single-02",
    component: UserDetail,
    layout: "/admin",
    invisible: true,
  },
  {
    collapse: true,
    path: "/partners",
    name: "Manage Partners",
    state: "openManagePartners",
    icon: "now-ui-icons business_money-coins",
    views: [
      {
        path: "/DealershipPartners",
        name: "Dealership Partners",
        mini: "RP",
        component: DealershipPartners,
        layout: "/admin",
      },
      {
        path: "/GroupAdmins",
        name: "Dealership Group Admins",
        mini: "DGA",
        component: GroupAdminPage,
        layout: "/admin",
      },
      {
        path: "/corporate-partners",
        name: "Corporate Partners",
        mini: "CP",
        component: CorporatePartners,
        layout: "/admin",
      },
      {
        path: "/BusinessPartners",
        name: " Business Partners",
        mini: "BP",
        component: BusinessPartners,
        layout: "/admin",
      },
      {
        path: "/addNewPartner",
        name: "Add new partner",
        mini: "+",
        component: addNewPartner,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/subscribed",
    name: "Subscribed Users",
    icon: "now-ui-icons users_single-02",
    component: SubscribedUsers,
    layout: "/admin",
  },
  {
    path: "/offerManager",
    name: "offer manager",
    icon: "now-ui-icons design_bullet-list-67",
    component: Offer,
    layout: "/admin",
  },
  {
    path: "/billings-calendar",
    name: "billings calendar",
    icon: "now-ui-icons ui-1_calendar-60",
    component: BillingsCalendar,
    layout: "/admin",
  },
  {
    collapse: true,
    path: "/pages",
    name: "Pages",
    state: "openPages",
    icon: "now-ui-icons design_image",
    invisible: true,
    views: [
      {
        path: "/login-page",
        name: "Login Page",
        short: "Login",
        mini: "LP",
        component: LoginPage,
        layout: "/auth",
        invisible: true,
      },
      {
        path: "/createPassword",
        name: "Login Page",
        short: "Login",
        mini: "LP",
        component: createPassword,
        layout: "/auth",
        invisible: true,
      },
      {
        path: "/reset-password-page",
        name: "Login Page",
        short: "Login",
        mini: "LP",
        component: ResetPassword,
        layout: "/auth",
        invisible: true,
      },
    ],
  },
  {
    collapse: true,
    path: "/settings",
    name: "Settings",
    state: "opnSettings",
    icon: "now-ui-icons loader_gear",
    views: [
      {
        path: "/ViewFeedback",
        name: "View Feedback",
        mini: "A",
        component: ViewFeedback,
        layout: "/admin",
      },
      {
        path: "/AddBrand",
        name: "Add Automotive Brands",
        mini: "A",
        component: AddBrand,
        layout: "/admin",
      },
      {
        path: "/AddTags",
        name: "Add Interest Tags",
        mini: "A",
        component: AddTags,
        layout: "/admin",
      },
      {
        path: "/AddCategories",
        name: "Add Categories",
        mini: "A",
        component: AddCategories,
        layout: "/admin",
      },
      // {
      //   path: "/AddInterests",
      //   name: "Add Interests",
      //   mini: "A",
      //   component: AddInterests,
      //   layout: "/admin",
      // },
      {
        path: "/InviteAdmin",
        name: "invite super admin",
        mini: "I",
        component: InviteAdmin,
        layout: "/admin",
      },
      {
        path: "/TermsOfService",
        name: "Terms Of Service",
        mini: "T",
        component: TermsOfService,
        layout: "/admin",
      },
      {
        path: "/PrivacyPolicy",
        name: "Privacy Policy",
        mini: "P",
        component: PrivacyPolicy,
        layout: "/admin",
      },
      //super admin does not need this for now
      // {
      //   path: "/About",
      //   name: "About",
      //   mini: "A",
      //   component: About,
      //   layout: "/admin",
      // },
    ],
  },
  {
    collapse: true,
    path: "/components",
    name: "Components",
    state: "openComponents",
    invisible: true,
    icon: "now-ui-icons education_atom",
    views: [
      {
        path: "/notifications",
        name: "Notifications",
        mini: "N",
        component: Notifications,
        layout: "/admin",
      },
      {
        path: "/icons",
        name: "Icons",
        mini: "I",
        component: Icons,
        layout: "/admin",
      },
    ],
  },

  {
    path: "/charts",
    name: "Charts",
    icon: "now-ui-icons business_chart-pie-36",
    component: Charts,
    layout: "/admin",
    invisible: true,
  },
];

export default routes;
