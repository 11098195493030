import axios from "../utils/request";

export function addOffer(data) {
  return axios({
    url: `/api/v1/Admin/offers`,
    method: "post",
    data,
  });
}
export function getOffer(data) {
  return axios({
    url: `/api/v1/Admin/offers-list`,
    method: "get",
    params: data,
  });
}
export function getOfferCount(data) {
  return axios({
    url: `/api/v1/Admin/offers/count`,
    method: "get",
    data,
  });
}
export function approveOffer(id) {
  return axios({
    url: `/api/v1/offers/${id}/authorize`,
    method: "PUT",
  });
}

export function rejectOffer(id, data) {
  return axios({
    url: `/api/v1/offers/${id}/reject`,
    method: "PUT",
    data,
  });
}

export function deleteOffer(id) {
  return axios({
    url: `/api/v1/offers/${id}/admin`,
    method: "delete",
  });
}
export function getCategory(data) {
  return axios({
    url: `/api/v1/Interests/grouped-by-category`,
    method: "get",
    params: data,
  });
}
export function getCouponAnalytics(partnerId, offerId, data) {
  return axios({
    url: `/api/v1/admin/analytics/partners/${partnerId}/offers/${offerId}`,
    method: "get",
    params: data,
  });
}

export function getOfferDetails(data) {
  return axios({
    url: `/api/v1/Offers/${data.offerId}`,
    method: "get",
    data,
  });
}
