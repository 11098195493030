import axios from "../utils/request"

export function accountLogin(data) {
  return axios({
    url: `/api/v1/Admin/login`,
    method: "post",
    data,
  })
}
export function changePassword(data) {
  return axios({
    url: `/api/v1/Admin/change-password`,
    method: "post",
    data,
  })
}
export function createAccount(data) {
  return axios({
    url: `/api/v1/Admin/invite`,
    method: "post",
    data,
  })
}

export const forgetPassword = (data) => {
  return axios({
    url: `/api/v1/Account/forgotPassword`,
    method: "post",
    data,
  })
}

export const resetPassword = (data) => {
  return axios({
    url: `/api/v1/Account/resetPassword`,
    method: "post",
    data,
  })
}
