import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
} from "reactstrap";
// import "./style.css";
import actionCreators from "store/actionCreators/platform";
import { connect } from "react-redux";
import { message, Pagination } from "antd";
import { useEffect, useState } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
function PrivacyPolicy(props) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [html, setHtml] = useState();
  let setEditor = React.useRef();
  useEffect(() => {
    props.getPrivacyPolicy().then((res) => {
      // console.log(res);
      toDraft(res);
      setHtml(res);
    });
  }, []);
  const focusEditor = () => {
    // if (setEditor.current) {
    //     setEditor.current.focus();
    // }
  };
  const onEditorStateChange = (editorState) => {
    toHtml(editorState);
    setEditorState(editorState);
  };
  const toHtml = (value) => {
    let templateContent = draftToHtml(convertToRaw(value.getCurrentContent()));
    // console.log(templateContent);
    setHtml(templateContent);
  };
  const toDraft = (value) => {
    const blocksFromHtml = htmlToDraft(value);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    setEditorState(editorState);
  };
  const continueButton = () => {
    let string = JSON.stringify(html);
    props.updatePrivacyPolicy(string).then((res) => {
      if (res.id) {
        message.success("Privacy Policy successfully updated");
      }
    });
  };
  const styles = {
    editor: {
      border: "1px solid #eef0f4",
      minHeight: "200px",
    },
  };

  return (
    <>
      <div className="content users">
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Card>
              <CardHeader>
                <CardTitle tag="h5">
                  <div>Privacy Policy</div>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12">
                    <div style={styles.editor} onClick={focusEditor}>
                      <Editor
                        ref={setEditor}
                        onEditorStateChange={onEditorStateChange}
                        editorState={editorState}
                        // onChange={onChange}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    md="6"
                    className="footer-buttons d-flex justify-content-start">
                    <Button
                      onClick={continueButton}
                      color="primary"
                      size="md"
                      className="btn-round">
                      Save
                    </Button>
                  </Col>
                  <Col md="6"></Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return state.user;
}
export default connect(mapStateToProps, actionCreators)(PrivacyPolicy);
