import React, { useEffect, useRef, useState } from "react";
// react plugin used to create charts
import { Line } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker } from "antd";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
// reactstrap components
import {
  Table,
  // Progress,
  Row,
  Col,
  // Button,
  // Badge,
} from "reactstrap";

import { Button, Modal } from "antd";

import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
// core components
import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";
import {
  dashboardPanelChart,
  dashboardActiveUsersChart,
  dashboardSummerChart,
  dashboardActiveCountriesCard,
} from "variables/charts.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js"
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import jacket from "assets/img/saint-laurent.jpg";
import shirt from "assets/img/balmain.jpg";
import swim from "assets/img/prada.jpg";
import "./style.css";
import moment from "moment";
import option from "./charts/pieData.js";
import option2 from "./charts/barData.js";
import echarts from "echarts";
import actionCreators from "store/actionCreators/dashboard";
import { connect } from "react-redux";
import { message } from "antd";

let vStartDate = new moment().add("month", 0).format("YYYY/MM") + "/01";
let today = moment(vStartDate);

let vEndM = moment(vStartDate).add("month", 1).add("days", -1);
let vEndDate = moment(vEndM).format("YYYY/MM/DD");
let tomorrow = moment(vEndDate);
const monthName = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const { RangePicker } = DatePicker;
const useStyles = makeStyles(styles);
var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920,
};

function Dashboard(props) {
  const classes = useStyles();
  const [globalDateRange, setGlobalDateRange] = useState([]);
  const [dateDropdownValue, setDateDropdownValue] = useState("");
  const [isCustom, setIsCustom] = useState(true);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [legend, setLegend] = useState(
    `For the period of ${today.format("LL")} - ${tomorrow.format("LL")} `
  );
  const handleOk = () => setIsModalVisible(false);
  const handleCancel = () => setIsModalVisible(false);
  const ref = useRef();

  const [totalOffersRedeemedByLocation, setTotalOffersRedeemedByLocation] =
    useState([]);
  const [dealershipActivity, setDealershipActivity] = useState([]);
  const [lifestyleActivity, setLifestyleActivity] = useState([]);
  const [corporationsActivity, setCorporationsActivity] = useState([]);

  const [activeUsersMonth, setActiveUsersMonth] = useState(0);
  const [activeUsersSixMonths, setActiveUsersSixMonths] = useState(0);

  useEffect(() => {
    props.getPartnersActivity().then((res) => {
      setDealershipActivity(
        createPartnerActivityTableData(res.dealershipsActivity)
      );
      setLifestyleActivity(
        createPartnerActivityTableData(res.lifestylePartnersActivity)
      );
      setCorporationsActivity(
        createPartnerActivityTableData(res.corporationsActivity)
      );
    });
    props.getActiveUsers().then((res) => {
      let totalLastMonth = nFormatter(res.totalLastMonth, 1);
      let totalLastSixMonths = nFormatter(res.totalLastSixMonths, 1);
      setActiveUsersMonth(totalLastMonth);
      setActiveUsersSixMonths(totalLastSixMonths);
    });
  }, []);
  const createPartnerActivityTableData = (data_table) => {
    var tableRows = [];
    for (var i = 0; i < data_table.length; i++) {
      tableRows.push(
        <tr key={i}>
          <td>{data_table[i].partnerName}</td>
          <td>{data_table[i].action}</td>
          <td>{moment(data_table[i].date).format("YYYY-MM-DD HH:mm")}</td>
        </tr>
      );
    }
    return tableRows;
  };
  useEffect(() => {
    let StartDate = startDate ? startDate.format("YYYY/MM/DD") : vStartDate;
    let EndDate = endDate ? endDate.format("YYYY/MM/DD") : vEndDate;
    props
      .getTotalOffersRedeemedByLocation({
        startDate: StartDate,
        endDate: EndDate,
      })
      .then((res) => {
        setTotalOffersRedeemedByLocation(
          createTableData(res.offersAnalyticsByCity)
        );
      });

    props
      .getTotalSavingByCategory({
        startDate: StartDate,
        endDate: EndDate,
      })
      .then((res) => {
        setTotalSavingsByCategories(createTableDataSaving(res));
      });

    props
      .getSavingsByCategoryAndPartner({
        startDate: StartDate,
        endDate: EndDate,
      })
      .then((res) => {
        setTotalSavingsByCategoriesAndPartner(
          createTableDataSavingAndPartner(res)
        );
        // console.log(totalSavingsByCategoriesAndPartner)
      });

    //get totalNumberOfOfferClicks
    props
      .getTotalNumberOfOfferClicks({
        startDate: StartDate,
        endDate: EndDate,
      })
      .then((res) => {
        setTotalNumberOfClicksOnOffers(res.total);
      });
    props
      .getTotalNumberOfPageViewsOfAllVendors({
        startDate: StartDate,
        endDate: EndDate,
      })
      .then((res) => {
        setTotalNumberOfPageViewsOfAllVendors(res.total);
      });

    // Change
    props
      .getTotalOffersRedeemedByGender({
        startDate: StartDate,
        endDate: EndDate,
      })
      .then((res) => {
        // console.log(res.redeemedByGender)
        const data = {
          title: {
            text: "Total offers redeemed by Gender",
            subtext: "",
            left: "center",
          },
          tooltip: {
            trigger: "item",
          },
          series: [
            {
              name: "Exclusive Reward",
              type: "pie",
              radius: "50%",
              data: [
                {
                  value: res.redeemedByGender[0]?.total,
                  name: res.redeemedByGender[0]?.gender,
                },
                {
                  value: res.redeemedByGender[1]?.total,
                  name: res.redeemedByGender[1]?.gender,
                },
                {
                  value: res.redeemedByGender[2]?.total,
                  name: res.redeemedByGender[2]?.gender,
                },
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };
        var dom = document.getElementById("container1");
        var myChart = echarts.init(dom);
        if (data && typeof data === "object") {
          myChart.setOption(data);
        }
      });

    // Change

    props
      .getTotalOffersRedeemedByCategoryAndGender({
        startDate: StartDate,
        endDate: EndDate,
      })
      .then((res) => {
        // console.log(res.results)
        let categoriesArr = res.results.map((item) => item.category);
        let maleArr = res.results.map((item) => item.redeemedByMale);
        let femaleArr = res.results.map((item) => item.redeemedByFemale);
        let otherArr = res.results.map((item) => item.redeemedByOther);
        // console.log(categoriesArr)

        var app = {};
        var chartDom = document.getElementById(
          "redeemed-by-category-and-gender"
        );
        var myChart = echarts.init(chartDom);
        var option;

        option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // Use axis to trigger tooltip
              type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
            },
          },
          legend: {},
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "value",
          },
          yAxis: {
            type: "category",
            data: categoriesArr,
          },
          series: [
            {
              name: "Male",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: maleArr,
            },
            {
              name: "Female",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: femaleArr,
            },
            {
              name: "Others",
              type: "bar",
              stack: "total",
              label: {
                show: true,
              },
              emphasis: {
                focus: "series",
              },
              data: otherArr,
            },
          ],
        };
        option && myChart.setOption(option);
      });

    //  Change

    props
      .getTotalOffersRedeemedByAgeGroup({
        startDate: StartDate,
        endDate: EndDate,
      })
      .then((res) => {
        // console.log(res.offersAnalyticsByAgeGroup)
        const data = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              data: [
                res.offersAnalyticsByAgeGroup[0].ageGroupLabel,
                res.offersAnalyticsByAgeGroup[1].ageGroupLabel,
                res.offersAnalyticsByAgeGroup[2].ageGroupLabel,
                res.offersAnalyticsByAgeGroup[3].ageGroupLabel,
                res.offersAnalyticsByAgeGroup[4].ageGroupLabel,
                res.offersAnalyticsByAgeGroup[5].ageGroupLabel,
              ],
              axisTick: {
                alignWithLabel: true,
              },
            },
          ],
          yAxis: [
            {
              type: "value",
            },
          ],
          series: [
            {
              name: "redeemed",
              type: "bar",
              barWidth: "60%",
              data: [
                res.offersAnalyticsByAgeGroup[0].redeemedOffers,
                res.offersAnalyticsByAgeGroup[1].redeemedOffers,
                res.offersAnalyticsByAgeGroup[2].redeemedOffers,
                res.offersAnalyticsByAgeGroup[3].redeemedOffers,
                res.offersAnalyticsByAgeGroup[4].redeemedOffers,
                res.offersAnalyticsByAgeGroup[5].redeemedOffers,
              ],
            },
          ],
        };

        var dom2 = document.getElementById("container2");
        var myChart2 = echarts.init(dom2);
        if (data && typeof data === "object") {
          myChart2.setOption(data);
        }
      });

    if (startDate && endDate) {
      const legend = `For the period of ${startDate.format(
        "LL"
      )} - ${endDate.format("LL")} `;
      setLegend(legend);
    }
  }, [startDate, endDate]);

  const createTableData = (data_table) => {
    var tableRows = [];
    for (var i = 0; i < data_table.length; i++) {
      tableRows.push(
        <tr key={i}>
          <td>{data_table[i].city}</td>
          <td className="text-left">{data_table[i].redeemedOffers}</td>
          <td className="text-left">{data_table[i].percentage}</td>
        </tr>
      );
    }
    return tableRows;
  };
  const [totalSavingsByCategories, setTotalSavingsByCategories] = useState([]);

  const createTableDataSaving = (data_table) => {
    var tableRows = [];
    for (var i = 0; i < data_table.length; i++) {
      tableRows.push(
        <tr key={i}>
          <td className="text-left">{data_table[i].categoryName}</td>
          <td className="text-left">{data_table[i].total}</td>
        </tr>
      );
    }
    return tableRows;
  };

  const [
    totalSavingsByCategoriesAndPartner,
    setTotalSavingsByCategoriesAndPartner,
  ] = useState([]);
  const [tempArr, setTempArr] = useState([]);

  const [savingsByPartnerList, setSavingsByPartnerList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => setIsModalVisible(true);
  const showSavingsByPartnerList = (event) => {
    showModal();
    // console.log(event.currentTarget.id)
    let cId = event.currentTarget.id;
    // console.log(totalSavingsByCategoriesAndPartner)
    // console.log(tempArr)
    // setSavingsByPartnerList(result)
    props
      .getSavingsByCategoryAndPartner({
        startDate: startDate,
        endDate: endDate,
      })
      .then((res) => {
        // console.log(cId)
        // console.log(res)
        res.forEach((item) => {
          if (item.categoryId == cId) {
            setSavingsByPartnerList(item.savingsByPartner);
            // console.log(item)
          }
        });
        // console.log(savingsByPartnerList)
      });
  };

  const createTableDataSavingAndPartner = (data_table) => {
    var tableRows = [];
    for (var i = 0; i < data_table.length; i++) {
      tableRows.push(
        <tr key={i}>
          <td className="text-left">{data_table[i].categoryName}</td>
          <td className="text-left">
            {data_table[i]?.totalByPartner?.toFixed(2)}
          </td>
          <td className="text-left">
            <Button
              ref={ref}
              id={data_table[i].categoryId}
              onClick={showSavingsByPartnerList}>
              View the List
            </Button>
          </td>
        </tr>
      );
    }
    return tableRows;
  };

  const [totalNumberOfClicksOnOffers, setTotalNumberOfClicksOnOffers] =
    useState(0);
  const [
    totalNumberOfPageViewsOfAllVendors,
    setTotalNumberOfPageViewsOfAllVendors,
  ] = useState(0);

  const getRange = (date, dateString) => {
    setGlobalDateRange(dateString);
  };

  const dateChange = (date, dateString) => {
    setDateDropdownValue("");
    if (date && date?.length > 0) {
      setStartDate(date[0]);
      setEndDate(date[1]);
    }
  };

  const handleDateDropdown = (e) => {
    setDateDropdownValue(e.target.value);
    switch (e.target.value) {
      case "":
        setIsCustom(true);
        break;
      case "0":
        setStartDate(moment().subtract("days", 7));
        setEndDate(moment());
        setIsCustom(false);
        break;
      case "1":
        setStartDate(moment().subtract(1, "months"));
        setEndDate(moment());
        setIsCustom(false);

        break;
      case "2":
        setStartDate(moment().subtract(3, "months"));
        setEndDate(moment());
        setIsCustom(false);

        break;
      case "3":
        setStartDate(moment().subtract(12, "months"));
        setEndDate(moment());
        setIsCustom(false);

        break;
      case "4":
        setStartDate(moment().subtract(50, "years"));
        setEndDate(moment());
        setIsCustom(false);
        break;
      default:
      // code block
    }
  };

  const nFormatter = (num, digits) => {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "K" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find((item) => {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  };

  return (
    <>
      <div className="content dashboard-page ">
        {/* <RangePicker onChange={getRange} size="large" /> */}
        <div className=" items-center">
          <div>
            <div className="">
              <label class="m-2 text-sm font-medium text-gray-90 truncate ">
                Select period
              </label>
              <select
                // value={dateDropdownValue}
                onChange={handleDateDropdown}
                class="border border-gray-300 text-gray-900 text-sm focus:ring-purple-500 focus:border-purple-500 p-2 mb-2">
                <option selected value="">
                  Custom Date
                </option>
                <option value="0">Last 7 days</option>
                <option value="1">Last month</option>
                <option value="2">Last 3 months</option>
                <option value="3">Last Year</option>
                <option value="4">All time</option>
              </select>
            </div>
            <div className="flex">
              {isCustom && (
                <RangePicker
                  size="large"
                  format="YYYY/MM/DD"
                  onChange={dateChange}
                />
              )}
              <div className="my-2">{legend}</div>
            </div>
          </div>
        </div>
        <>
          {/* <Button type="primary" onClick={showModal}>
            Open Modal
          </Button> */}
          <Modal
            title="Saving By Partner"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}>
            {savingsByPartnerList.map((item) => {
              return (
                <div
                  key={item.partner}
                  style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>{item.partner}</div>{" "}
                  <div>{item?.totalSavings?.toFixed(2)}</div>
                </div>
              );
            })}
          </Modal>
        </>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="primary">
                  <i className="fas fa-mouse-pointer"></i>
                </CardIcon>
                <p className={classes.cardCategory}>
                  Total Number Of Clicks on Offers
                </p>
                <h3 className={classes.cardTitle}>
                  {totalNumberOfClicksOnOffers}
                </h3>
              </CardHeader>
              <CardFooter stats>
                {/* <div className={classes.stats}>
                  <LocalOffer />
                  number
                </div> */}
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="primary" stats icon>
                <CardIcon color="success">
                  <i className="fas fa-users"></i>
                </CardIcon>
                <p className={classes.cardCategory}>
                  Total No Of Page Views Of All Vendors
                </p>
                <h3 className={classes.cardTitle}>
                  {totalNumberOfPageViewsOfAllVendors}
                </h3>
              </CardHeader>
              <CardFooter stats>
                {/* <div className={classes.stats}>
                  <LocalOffer />
                  number
                </div> */}
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="primary">
                  <i className="fas fa-users"></i>
                </CardIcon>
                <p className={classes.cardCategory}>Active Users</p>
                <h3 className={classes.cardTitle}>{activeUsersMonth}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>Last month</div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="primary">
                  <i className="fas fa-users"></i>
                </CardIcon>
                <p className={classes.cardCategory}>Active Users</p>
                <h3 className={classes.cardTitle}>{activeUsersSixMonths}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>Last 6 months</div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>

        <Row>
          <Col>
            <h3
              className="mb-1"
              style={{
                fontSize: "24px",
                color: "#272727",
                opacity: "1",
                fontStyle: "bold",
              }}>
              Recent Partner Activity
            </h3>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Card style={{ height: "649px" }}>
              <CardHeader className="pb-1">
                <h5
                  className="card-category mb-1 pb-1"
                  style={{ fontSize: "20px", color: "#A5A5A5", opacity: "1" }}>
                  Dealership Partners
                </h5>
              </CardHeader>
              <CardBody style={{ overflowY: "auto" }}>
                <Table responsive>
                  <thead>
                    <tr className="text-left">
                      <th>Dealership</th>
                      <th>Action</th>
                      <th>Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>{dealershipActivity}</tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card style={{ height: "649px", overflowY: "scroll" }}>
              <CardHeader className="pb-1">
                <h5
                  className="card-category mb-1"
                  style={{ fontSize: "20px", color: "#A5A5A5", opacity: "1" }}>
                  Business Partners
                </h5>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead>
                    <tr className="text-left">
                      <th>Partner</th>
                      <th>Action</th>
                      <th>Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>{lifestyleActivity}</tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card style={{ height: "649px", overflowY: "scroll" }}>
              <CardHeader className="pb-1">
                <h5
                  className="card-category mb-1"
                  style={{ fontSize: "20px", color: "#A5A5A5", opacity: "1" }}>
                  Corporate Partners
                </h5>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead>
                    <tr className="text-left">
                      <th>Partner</th>
                      <th>Action</th>
                      <th>Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>{corporationsActivity}</tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">
                  Total Savings By Categories And Partners
                </h5>
                {/* <CardTitle tag="h2">34,252</CardTitle> */}
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead>
                    <tr className="text-left">
                      <th>Category Name</th>
                      <th>Total Savings By Partners</th>
                      <th>Savings By Partner</th>
                    </tr>
                  </thead>
                  <tbody>{totalSavingsByCategoriesAndPartner}</tbody>
                </Table>
              </CardBody>
              <CardFooter>
                {/* <div className="stats">
                  <i className="now-ui-icons arrows-1_refresh-69" />
                  Just Updated
                </div> */}
              </CardFooter>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12}>
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">
                  Total offers redeemed by Location (city)
                </h5>
                {/* <CardTitle tag="h2">34,252</CardTitle> */}
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead>
                    <tr className="text-left">
                      <th>City</th>
                      <th>Total Offers</th>
                      <th>Percentage</th>
                    </tr>
                  </thead>
                  <tbody>{totalOffersRedeemedByLocation}</tbody>
                </Table>
              </CardBody>
              <CardFooter>
                {/* <div className="stats">
                  <i className="now-ui-icons arrows-1_refresh-69" />
                  Just Updated
                </div> */}
              </CardFooter>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">
                  Total offers redeemed by Gender
                </h5>
                {/* <CardTitle tag="h2">34,252</CardTitle> */}
              </CardHeader>
              <CardBody>
                <div id="container1" style={{ height: "600px" }}></div>
              </CardBody>
              <CardFooter>
                {/* <div className="stats">
                  <i className="now-ui-icons arrows-1_refresh-69" />
                  Just Updated
                </div> */}
              </CardFooter>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">
                  Total offers redeemed by Age group
                </h5>
                {/* <CardTitle tag="h2">34,252</CardTitle> */}
              </CardHeader>
              <CardBody>
                <div id="container2" style={{ height: "600px" }}></div>
              </CardBody>
              <CardFooter>
                {/* <div className="stats">
                  <i className="now-ui-icons arrows-1_refresh-69" />
                  Just Updated
                </div> */}
              </CardFooter>
            </Card>
          </Col>
          <Col>
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">
                  Total offers redeemed by category and gender
                </h5>
                {/* <CardTitle tag="h2">34,252</CardTitle> */}
              </CardHeader>
              <CardBody>
                <div
                  id="redeemed-by-category-and-gender"
                  style={{ height: "600px", width: "100%" }}></div>
              </CardBody>
              <CardFooter>
                {/* <div className="stats">
                  <i className="now-ui-icons arrows-1_refresh-69" />
                  Just Updated
                </div> */}
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return state.user;
}
export default connect(mapStateToProps, actionCreators)(Dashboard);
