import { React, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import "./groupAdmin.css";
import leftIcon from "../../../assets/icons/icon-left.png";

export default function NewGroupAdmin(props) {
  const styles = {
    active: { backgroundColor: "#ecd4fa", padding: "0rem 1.25rem" },
    none: { padding: "0rem 1.25rem" },
  };

  const checkDisabled = () => {
    const isDisabled =
      props.activeGroupAdmin.name == "" ||
      props.activeGroupAdmin.phone == "" ||
      props.activeGroupAdmin.lastName == "" ||
      props.activeGroupAdmin.email == "" ||
      props.activeGroupAdmin.dealerships.length == 0;
    return isDisabled;
  };

  const renderDealershipList = (dealerships) => {
    return (
      <Card className="blue">
        <CardHeader>
          <CardTitle tag="h6">Select dealerships</CardTitle>
        </CardHeader>
        <CardBody>
          <ListGroup
            style={{ height: "450px", overflowY: "auto", cursor: "pointer" }}>
            {dealerships.map((partner) => (
              <ListGroupItem
                style={partner.selected ? styles.active : styles.none}
                key={partner.id}
                onClick={(e) => {
                  props.onDealershipSelected(e, partner);
                }}>
                <p>
                  <input
                    className="purple"
                    checked={partner.selected}
                    type="checkbox"></input>
                  <span>{partner.text}</span>
                </p>
              </ListGroupItem>
            ))}
          </ListGroup>
        </CardBody>
      </Card>
    );
  };
  return (
    <>
      <div
        className="back-button-title mb-2 cursor-pointer"
        onClick={props.cancelNewGroupAdmin}>
        <img className="mb-1" width="14px" src={leftIcon} />
        Back
      </div>
      <Card>
        <CardHeader>
          <CardTitle tag="h5">New Group admin</CardTitle>
          <div className="float-right">
            <Button
              onClick={props.cancelNewGroupAdmin}
              size="lg"
              className="btn-round"
              style={{ backgroundColor: "#9b9b9b" }}>
              Cancel
            </Button>

            <Button
              disabled={checkDisabled()}
              onClick={props.saveGroupAdmin}
              color="primary"
              size="lg"
              className="btn-round">
              {props.activeGroupAdmin.isNew
                ? "Add Group Admin"
                : "Save Changes to Group Admin"}
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col className="pr-1" md="6">
                <FormGroup>
                  <label>
                    First name <span style={{ color: "red" }}>*</span>
                  </label>
                  <Input
                    value={props.activeGroupAdmin.name}
                    onChange={(e) => props.onGroupAdminChange(e)}
                    placeholder="First name"
                    name="name"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col className="pr-1" md="6">
                <FormGroup>
                  <label>
                    Last name <span style={{ color: "red" }}>*</span>
                  </label>
                  <Input
                    value={props.activeGroupAdmin.lastName}
                    onChange={(e) => props.onGroupAdminChange(e)}
                    placeholder="Last name"
                    name="lastName"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="6">
                <FormGroup>
                  <label>
                    Email <span style={{ color: "red" }}>*</span>
                  </label>
                  <Input
                    value={props.activeGroupAdmin.email}
                    onChange={(e) => props.onGroupAdminChange(e)}
                    name="email"
                    placeholder="Email"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col className="pr-1" md="6">
                <FormGroup>
                  <label>
                    Phone <span style={{ color: "red" }}>*</span>
                  </label>
                  <Input
                    value={props.activeGroupAdmin.phone}
                    onChange={(e) => props.onGroupAdminChange(e)}
                    name="phone"
                    placeholder="Phone"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>{renderDealershipList(props.dealerships)}</Row>
          </Form>
        </CardBody>
      </Card>
    </>
  );
}
