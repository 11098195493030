import axios from "axios";
import { message } from "antd";
import { baseURL } from "./config";
// create an axios instance
const service = axios.create({
  baseURL: baseURL,
  timeout: 50000,
  headers: {
    "content-type": "application/json;charset=UTF-8",
  },
  //withCredentials: true, // default
});
// request interceptor
service.interceptors.request.use(
  (config) => {
    let access_token = localStorage.getItem("access_token");
    if (access_token) {
      config.headers["Authorization"] = `Bearer ${access_token}`;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    if (error.response) {
      // console.log("error.response", error.response?.status);

      if (error.response?.status == 401 || error.response?.status == 404) {
        message.error("Session timed out, Please sign in again");
        localStorage.removeItem("access_token");
        localStorage.removeItem("persist:root");
        window.location.href = "/";
      } else {
        message.error(
          error.response.data.title ||
            error.response.data.message ||
            error.response.data ||
            "network error"
        );
      }
    } else {
      let config = error.config;
      let access_token = localStorage.getItem("access_token");
      let res = await fetch(`${config.url}`, {
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        method: config.method,
      });
      if (res.status == 401 || res.status == 404) {
        message.error("Session timed out, Please sign in again");
        localStorage.removeItem("access_token");
        localStorage.removeItem("persist:root");
        window.location.href = "/";
      } else {
        message.error("network error");
      }
    }
    return Promise.reject(error);
  }
);

export default service;
