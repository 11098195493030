import axios from "../utils/request"

export function updateFeedback(id) {
  return axios({
    url: `/api/v1/HelpCenter/feedback/${id}/acknowledge`,
    method: "put",
    id,
  })
}

export function getFeedback(data) {
  return axios({
    url: `/api/v1/HelpCenter/feedback`,
    method: "get",
    params:data,
  })
}

export function getFeedbackCount(data) {
  return axios({
    url: `/api/v1/HelpCenter/feedback/count`,
    method: "get",
    params:data,
  })
}

export function searchFeedback(searchTerm) {
  return axios({
    url: `/api/v1/HelpCenter/feedback/search/${searchTerm}`,
    method: "get",
  })
}
