import React, { useEffect } from "react"

// reactstrap components
import { Button, Card, CardHeader, CardBody, Row, Col, Form, Input, FormGroup, Label } from "reactstrap"
import Select from "react-select"
// import "../style.css";
import actionCreators from "../../../../../store/actionCreators/intesetsCategory"
import { connect } from "react-redux"
import ImageUpload from "./CustomUpload/ImageUpload.js"
import { message } from "antd"
function CreateCategory({ setPage, getCategory, addIntesets }) {
  const [categorySelect, setCategorySelect] = React.useState(null)

  const [categoryOptions, setCategoryOptions] = React.useState(null)
  const [name, setName] = React.useState("")
  const [imagesId, setImagesId] = React.useState("")

  useEffect(() => {
    getCategoryList()
  }, [])
  let getCategoryList = () => {
    getCategory().then((res) => {
      let arr = []
      res.forEach((item) => {
        arr.push({
          value: item.category,
          label: item.category,
          id: item.categoryId,
        })
      })
      setCategoryOptions(arr)
    })
  }
  let uploadSuccess = (res) => {
    console.log(res)
    setImagesId(res.id)
  }
  const continueButton = () => {
    if (!name) {
      message.error("Please enter brand name!")
      return
    }
    if (!imagesId) {
      message.error("Please upload pictures!")
      return
    }
    if (!categorySelect.id) {
      message.error("Please select category!")
      return
    }
    let obj = {
      name,
      picture: imagesId,
      categoryId: categorySelect.id,
    }
    addIntesets(obj).then((res) => {
      if (!res.created) {
        message.error(res.message)
        return
      } else {
        message.success("Successfully created")
        setPage("1")
      }
    })
    // console.log(obj,categorySelect);

    // window.location.href = "/admin/move-money/confirm";
  }

  return (
    <>
      <div className="content paybill">
        <Row>
          <Col md="7">
            <Card>
              <CardHeader className="paybill-header">
                <h5 className="title ml-2">Create Interests</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>Interest Name</label>
                        <Input
                          placeholder="Interest Name"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value)
                          }}
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>Category</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          placeholder="Single Select"
                          name="singleSelect"
                          value={categorySelect}
                          options={categoryOptions}
                          onChange={(value) => setCategorySelect(value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <div>
                        <label>Image Upload</label>
                      </div>
                      <ImageUpload
                        type="admin"
                        addButtonProps={{
                          color: "rose",
                          round: true,
                        }}
                        changeButtonProps={{
                          color: "rose",
                          round: true,
                        }}
                        removeButtonProps={{
                          color: "danger",
                          round: true,
                        }}
                        uploadSuccess={uploadSuccess}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6" className="footer-buttons d-flex justify-content-start">
                      <Button onClick={() => setPage("1")} color="secondary" size="md" className="btn-round mr-2 cancel-button">
                        Cancel{" "}
                      </Button>

                      <Button onClick={continueButton} color="primary" size="md" className="btn-round">
                        Ok
                      </Button>
                    </Col>
                    <Col md="6"></Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}
function mapStateToProps(state) {
  return state.user
}
export default connect(mapStateToProps, actionCreators)(CreateCategory)
