import React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
// react plugin for creating notifications
import NotificationAlert from "react-notification-alert";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";
import actionCreators from "../store/actionCreators/login";
import { connect } from "react-redux";
import LOGIN_TYPES from "../typings/login-types";
var ps;

function Admin(props) {
  const location = useLocation();
  const [sidebarMini, setSidebarMini] = React.useState(true);
  const [backgroundColor, setBackgroundColor] = React.useState("blue");
  const notificationAlert = React.useRef();
  const mainPanel = React.useRef();
  React.useEffect(() => {
    props.validate();
  }, []);

  React.useEffect(() => {
    if (props.isLogin === LOGIN_TYPES.LOGIN_ED) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      mainPanel.current.scrollTop = 0;
    }
  }, [location, props.isLogin]);
  const minimizeSidebar = () => {
    var message = "Sidebar mini ";
    if (document.body.classList.contains("sidebar-mini")) {
      setSidebarMini(false);
      message += "deactivated...";
    } else {
      setSidebarMini(true);
      message += "activated...";
    }
    document.body.classList.toggle("sidebar-mini");
    var options = {};
    options = {
      place: "tr",
      message: message,
      type: "info",
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };
  const handleColorClick = (color) => {
    setBackgroundColor(color);
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  let content;
  if (props.isLogin === LOGIN_TYPES.UN_VALIDATE) {
    content = null;
  } else if (props.isLogin === LOGIN_TYPES.LOGIN_ED) {
    content = (
      <div className="wrapper">
        <NotificationAlert ref={notificationAlert} />
        <Sidebar
          {...props}
          routes={routes}
          minimizeSidebar={minimizeSidebar}
          backgroundColor={backgroundColor}
        />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar {...props} brandText={getActiveRoute(routes)} />
          <Switch>
            {getRoutes(routes)}
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          {/* {
          // we don't want the Footer to be rendered on full screen maps page
          window.location.href.indexOf("full-screen-maps") !== -1 ? null : (
            <Footer fluid />
          )
        } */}
        </div>
        {/* <FixedPlugin
        handleMiniClick={minimizeSidebar}
        sidebarMini={sidebarMini}
        bgColor={backgroundColor}
        handleColorClick={handleColorClick}
      /> */}
      </div>
    );
  } else {
    content = <Redirect to="/auth/login-page" />;
  }

  return <>{content}</>;
}
function mapStateToProps(state) {
  return state.user;
}
export default connect(mapStateToProps, actionCreators)(Admin);
