import { createStore, applyMiddleware } from "redux";

import { persistStore, persistReducer } from "redux-persist";
// import storageSession from "redux-persist/lib/storage/session"
import storage from "redux-persist/lib/storage";

import combinedReducer from "./reducers";
import logger from "redux-logger";
import thunk from "redux-thunk";
import promise from "redux-promise";
import { routerMiddleware } from "connected-react-router";
import history from "../history";
const storageConfig = {
  key: "root",
  storage,
  blacklist: [],
};
const myPersistReducer = persistReducer(storageConfig, combinedReducer);
let store;
if (process.env.NODE_ENV === "development") {
  // store = applyMiddleware(routerMiddleware(history), thunk, promise, logger)(createStore)(myPersistReducer)
  store = applyMiddleware(
    routerMiddleware(history),
    thunk,
    promise
  )(createStore)(myPersistReducer);
} else {
  store = applyMiddleware(
    routerMiddleware(history),
    thunk,
    promise
  )(createStore)(myPersistReducer);
}

export const persistor = persistStore(store);
export default store;
