import * as actionTypes from '../action-types';
import LOGIN_TYPES from '../../typings/login-types.js';
let initialState = {
    userProfile:{},
    isLogin:LOGIN_TYPES.UN_VALIDATE,
    error: false,
    provider:{},
    isProvider: false
};
function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.VALIDATE:
            if(action.payload.success){
                return {
                    ...state,
                    isLogin:LOGIN_TYPES.LOGIN_ED
                }
            }else{
                return {
                    ...state,
                    isLogin:LOGIN_TYPES.UN_LOGIN
                }
            }
        case actionTypes.SET_USER:
            let list = action.payload
            return {
                ...state,
                userProfile:{...list}
            }  
        case actionTypes.UPDATE_USER:
        let obj = action.payload
        return {
            ...state,
            userProfile:{
                ...state.userProfile,
                ...obj
            }
        } 
        case actionTypes.LOGOUT:
            return {
                ...state,
                userProfile:{},
                isLogin:LOGIN_TYPES.UN_LOGIN
            }
        default:
            return state;
    }
}
export default reducer;