import React, { useEffect } from "react"

// reactstrap components
import { Card, CardBody, CardHeader, CardTitle, Table, Row, Col, Button, FormGroup, Label, Input, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap"
// import "./style.css";

import Table1 from "./Table/table1"
import CreateOffer from "./Create"
import actionCreators from "../../../../store/actionCreators/intesetsCategory"
import { connect } from "react-redux"
import { message } from "antd"
function Categories(props) {
  const [message, setMessage] = React.useState(false)
  const [openedCollapses, setOpenCollapses] = React.useState(["collapseOne"])
  const [hTabs, sethTabs] = React.useState("")
  const [categoryData, setCategoryData] = React.useState([])
  const [page, setPage] = React.useState("1")
  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page
  const collapsesToggle = (collapse) => {
    if (openedCollapses.includes(collapse)) {
      setOpenCollapses([])
    } else {
      setOpenCollapses([collapse])
    }
  }
  useEffect(() => {
    props.getCategory().then((res) => {
      // console.log(res);
      setCategoryData(res)
      sethTabs(res[0].category)
    })
  }, [page])

  return (
    <>
      {page === "1" ? (
        <div className="content users">
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">
                    <div>Add Categories</div>
                  </CardTitle>
                  <div className="float-right">
                    <Button onClick={() => setPage("2")} color="secondary" size="md" className="btn-round mr-2">
                      <i className="fa fa-plus mr-2" /> Create
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <Nav pills className="nav-pills-primary">
                    {categoryData && categoryData.length ? (
                      <>
                        {categoryData.map((item, index) => {
                          return (
                            <NavItem style={{ cursor: "pointer" }}>
                              <NavLink key={item.category} className={hTabs === item.category ? "active" : ""} onClick={() => sethTabs(item.category)}>
                                {item.category}
                              </NavLink>
                            </NavItem>
                          )
                        })}
                      </>
                    ) : (
                      ""
                    )}
                  </Nav>
                  <TabContent activeTab={hTabs} className="tab-space">
                    {categoryData && categoryData.length ? (
                      <>
                        {categoryData.map((item, index) => {
                          return (
                            <TabPane tabId={item.category} key={item.category}>
                              <Table1 dataTable={item.interests} />
                            </TabPane>
                          )
                        })}
                      </>
                    ) : (
                      ""
                    )}
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      ) : page === "2" ? (
        <CreateOffer setPage={setPage} />
      ) : (
        ""
      )}
    </>
  )
}
function mapStateToProps(state) {
  return state.user
}

export default connect(mapStateToProps, actionCreators)(Categories)
