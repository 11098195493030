/*eslint-disable*/
import React, { Component } from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  Table,
  UncontrolledTooltip,
  ButtonGroup,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

// core components
import ProviderDetails from "./ProviderDetails/index.jsx";
import ReactTable from "components/ReactTable/ReactTable.js";

const dataTable = [
  ["Jack Ryan", "Trade", "25-01-2022"],
  ["Jack Ryan", "Trade", "25-01-2022"],
  ["Jack Ryan", "Trade", "25-01-2022"],
  ["Jack Ryan", "Trade", "25-01-2022"],
];

function ReactTables() {
  const [page, setPage] = React.useState("1");

  const backButton = () => {
    if (page === "2") {
      setPage("1");
    } else {
      window.location.href = "/admin/products/identification-history";
    }
  };
  const [dataState, setDataState] = React.useState(
    dataTable.map((prop, key) => {
      return {
        id: key,
        Name: prop[0],
        category: prop[1],
        creationDate: prop[2],
        actions: (
          // we've added some custom button actions
          <div key={key} className="actions-right">
            {/* use this button to add a like kind of action
            <Button
              onClick={() => {
                let obj = dataState.find((o) => o.id === key);
                alert(
                  "You've clicked LIKE button on \n{ \nName: " +
                    obj.name +
                    ", \nposition: " +
                    obj.position +
                    ", \noffice: " +
                    obj.office +
                    ", \nage: " +
                    obj.age +
                    "\n}."
                );
              }}
              className="btn-icon btn-round"
              color="info"
              size="sm">
              <i className="fa fa-heart" />
            </Button>{" "}  
            */}
            {/* use this button to add a edit kind of action */}
            <Button
              onClick={() => setPage("2")}
              className="btn-icon btn-round"
              color="info"
              size="sm">
              <i className="fas fa-info"></i>
            </Button>{" "}
          </div>
        ),
      };
    })
  );
  return (
    <>
      {page === "1" ? (
        <div className="content users">
          <Row>
            <Col xs={12} md={8}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Providers</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="" md="12">
                      <FormGroup>
                        <Input
                          placeholder="Search by name or email"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <ReactTable
                    data={dataState}
                    columns={[
                      {
                        Header: "name",
                        accessor: "Name",
                      },
                      {
                        Header: "Category",
                        accessor: "category",
                        sortable: false,
                      },
                      {
                        Header: "Creation Date",
                        accessor: "creationDate",
                        canFilter: false,
                      },
                      {
                        Header: "View",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <ProviderDetails page={page} setPage={setPage} />
      )}
    </>
  );
}

export default ReactTables;
