import React from "react"
import ReactTable from "components/ReactTable/ReactTable.js"
import { Card, CardBody, CardHeader, CardTitle, Table, Row, Col, Button, FormGroup, Label, Input, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap"

const Table1 = (props) => {
  const [message, setMessage] = React.useState(false)
  const [dataState, setDataState] = React.useState(
    props.dataTable.map((prop, key) => {
      return {
        id: key,
        interests: prop.name,
        // CreateTime: prop.name,
        actions: (
          // we've added some custom button actions
          <div key={key} className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button onClick={() => setMessage(true)} className="btn-icon btn-round" color="warning" size="sm">
              <i className="fa fa-trash" />
            </Button>
          </div>
        ),
      }
    })
  )
  return (
    <>
      <Row>
        <Col className="" md="12">
          {/* <FormGroup>
                        <Input
                            placeholder="Search by categories"
                            type="text"
                        />
                    </FormGroup> */}
        </Col>
      </Row>
      <ReactTable
        data={dataState}
        showPagination
        columns={[
          {
            Header: "interests",
            accessor: "interests",
            sortable: false,
          },
          {
            //remove delete button
            Header: "  ",
            // accessor: "actions",
            sortable: false,
            filterable: false,
          },
        ]}
      />
    </>
  )
}

export default Table1
