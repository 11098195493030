import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
// import "./style.css";
import CreateOffer from "./Create";
import EditOffer from "./Edit";
import Approved from "./Approved";
import ReactTable from "components/ReactTable/ReactTable.js";
import actionCreators from "store/actionCreators/offer";
import { connect } from "react-redux";
import { message, Pagination } from "antd";
import { useEffect } from "react";

function Offer(props) {
  const [page, setPage] = React.useState("1");
  const [add, setAdd] = React.useState(false);
  const [item, setItem] = React.useState("");
  const [dataState, setDataState] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [itemsOnPage, setItemsOnPage] = React.useState(10);
  const [totalItems, setTotalItems] = React.useState("");
  const [name, setName] = React.useState("");

  const [approveOffer, setApproveOffer] = React.useState(false);

  useEffect(() => {
    if (page == 1) {
      getList(pageNumber - 1);
    }
  }, [page]);

  const onKeyDown = (e) => {
    var theEvent = window.event || e;
    var code = theEvent.keyCode || theEvent.which || theEvent.charCode;
    setPageNumber(0);
    if (code == 13) {
      if (e.target.value == "") {
        getList(page - 1);
      } else {
        getList(page - 1, e.target.value);
      }
    }
  };

  const getList = (page, search) => {
    let data = { pageNumber: page, itemsOnPage, search };
    props.getOffer(data).then((res) => {
      setItemsOnPage(res?.perPage);
      setTotalItems(res?.totalItems);
      let arr = res?.offers;
      if (arr) {
        let dataState = arr.reverse().map((item, key) => {
          return {
            id: key,
            offers: item.title,
            CreatedTime: item.createdAt,
            offerType: item?.offerType,
            partnerName: item?.partnerName,
            partnerType: item?.partnerType,
            actions:
              item.status == 3
                ? "Approved"
                : item.status == 2
                ? "Rejected"
                : "Pending",
            // actions: item.status==1?'Pending':'Pending',
            editActions: (
              // we've added some custom button actions
              <div key={key} className="actions-left">
                {/* use this button to add a edit kind of action */}
                <Button
                  onClick={() => {
                    setAdd(false);
                    setItem(item);
                    setPage("3");
                  }}
                  className="btn-icon btn-round"
                  color="warning"
                  size="sm">
                  <i className="fa fa-edit" />
                </Button>
              </div>
            ),
            deleteActions: (
              <div key={key} className="actions-right">
                <Button
                  className="btn-icon btn-round"
                  color="warning"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to Delete this offer?"
                      )
                    ) {
                      props.deleteOffer(item.offerId).then((res) => {
                        message.success("Offer deleted");
                        getList();
                      });
                    }
                  }}
                  size="sm">
                  <i className="fa fa-trash" />
                </Button>
              </div>
            ),
          };
        });
        // console.log(dataState);
        setDataState(dataState);
      }
    });
  };
  const onShowSizeChange = (page, pageSize) => {
    setPageNumber(page);
    getList(page - 1);
  };
  return (
    <>
      {item && (
        <Modal isOpen={approveOffer} toggle={() => setApproveOffer(false)}>
          {/* <ModalHeader toggle={() => setApproveOffer(false)}>
            {item.title}
          </ModalHeader> */}
          <ModalBody>
            <Approved
              offer={item}
              setPage={() => {
                setApproveOffer(false);
              }}
            />
          </ModalBody>
        </Modal>
      )}
      {page === "1" && (
        <div className="content users">
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">
                    <div>Offers Manager</div>
                  </CardTitle>
                  {/* <div className="float-right">
                    <Button
                      onClick={() => setPage("2")}
                      color="secondary"
                      size="md"
                      className="btn-round mr-2">
                      <i className="fa fa-plus mr-2" /> Create
                    </Button>
                  </div> */}
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="" md="12">
                      <FormGroup>
                        <Input
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          onKeyDown={onKeyDown}
                          placeholder="Search"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <ReactTable
                    data={dataState}
                    columns={[
                      {
                        Header: "Offer title",
                        accessor: "offers",
                        sortable: false,
                      },
                      {
                        Header: "Created on",
                        sortable: false,
                        Cell: (tableProps) => (
                          <span>
                            {new Date(
                              tableProps.row.original.CreatedTime
                            ).toLocaleString("en-US", {
                              timeZone:
                                Intl.DateTimeFormat().resolvedOptions()
                                  .timeZone,
                            })}
                          </span>
                        ),
                      },
                      {
                        Header: "Offer Type",
                        accessor: "offerType",
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: "Partner Name",
                        accessor: "partnerName",
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: "Partner Type",
                        accessor: "partnerType",
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: "Status",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: "Edit",
                        accessor: "editActions",
                        sortable: false,
                        filterable: false,
                      },
                      {
                        Header: "Delete",
                        accessor: "deleteActions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                  />
                  <Row>
                    <Col md="12">
                      <div className="erPagination">
                        <Pagination
                          showSizeChanger={false}
                          current={pageNumber}
                          defaultPageSize={itemsOnPage}
                          pageSize={itemsOnPage}
                          total={totalItems}
                          onChange={onShowSizeChange}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}
      {page === "2" && <CreateOffer setPage={setPage} />}
      {page === "3" && (
        <EditOffer
          setPage={setPage}
          onApprove={() => setApproveOffer(true)}
          item={item}
        />
      )}
    </>
  );
}

function mapStateToProps(state) {
  return state.user;
}
export default connect(mapStateToProps, actionCreators)(Offer);
