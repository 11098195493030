import axios from "../utils/request"

export function getTotalNumberOfOfferClicks(data) {
  return axios({
    url: `/api/v1/admin/analytics/offer-clicks`,
    method: "get",
    params: { StartDate: data.startDate, EndDate: data.endDate },
  })
}

export function getTotalNumberOfPageViewsOfAllVendors(data) {
  return axios({
    url: `/api/v1/admin/analytics/partner-views`,
    method: "get",
    params: { StartDate: data.startDate, EndDate: data.endDate },
  })
}

export function getTotalOffersRedeemedByLocation(data) {
  return axios({
    url: `/api/v1/admin/analytics/redeemed-by-city`,
    method: "get",
    params: { StartDate: data.startDate, EndDate: data.endDate },
  })
}

export function getTotalSavingByCategory(data) {
  return axios({
    url: `/api/v1/admin/analytics/totalsavings-by-category`,
    method: "get",
    params: { StartDate: data.startDate, EndDate: data.endDate },
  })
}

export function getSavingsByCategoryAndPartner(data) {
  return axios({
    url: `/api/v1/admin/analytics/savings-by-category-and-partner`,
    method: "get",
    params: { StartDate: data.startDate, EndDate: data.endDate },
  })
}

export function getTotalOffersRedeemedByGender(data) {
  return axios({
    url: `/api/v1/admin/analytics/redeemed-by-gender`,
    method: "get",
    params: { StartDate: data.startDate, EndDate: data.endDate },
  })
}

export function getTotalOffersRedeemedByAgeGroup(data) {
  return axios({
    url: `/api/v1/admin/analytics/redeemed-by-agegroup`,
    method: "get",
    params: { StartDate: data.startDate, EndDate: data.endDate },
  })
}

export function getTotalOffersRedeemedByCategoryAndGender(data) {
  return axios({
    url: `/api/v1/admin/analytics/offers/redeemed-by-category-and-gender`,
    method: "get",
    params: { StartDate: data.startDate, EndDate: data.endDate },
  })
}

export function getPartnersActivity(data) {
  return axios({
    url: `/api/v1/admin/analytics/partners/activity`,
    method: "get",
    data
  });
}

export function getActiveUsers(data) {
  return axios({
    url: `/api/v1/admin/analytics/active-users`,
    method: "get",
    data
  });
}