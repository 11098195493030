/*eslint-disable*/
import React, { Component, useEffect } from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  Table,
  UncontrolledTooltip,
  ButtonGroup,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import eRLogo from "../../../../assets/img/ER Logo Black.png";

// core components
import PartnerDetails from "./PartnerDetails/index.jsx";
import ReactTable from "components/ReactTable/ReactTable.js";
import actionCreators from "store/actionCreators/partner.js";
import { connect } from "react-redux";
import { message, Switch, Modal, Pagination } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const { confirm } = Modal;
function BusinessPartners(props) {
  const [page, setPage] = React.useState("1");
  const [add, setAdd] = React.useState(false);
  const [item, setItem] = React.useState("");
  const [name, setName] = React.useState("");
  const [pageNumber, setPageNumber] = React.useState(1);
  const [itemsOnPage, setItemsOnPage] = React.useState(10);
  const [totalItems, setTotalItems] = React.useState("");
  useEffect(() => {
    if (page == 1) {
      getList(pageNumber - 1);
    }
  }, [page]);
  const delItem = (id) => {
    props.delBrand(id).then((res) => {
      message.success("Brand deleted");
      getList(pageNumber - 1);
    });
  };

  const [dataState, setDataState] = React.useState([]);

  const fetchPartnerDetails = (partner) => {
    props.getPartnerDetails(partner.id).then((resp) => {
      setAdd(false);
      setItem(resp);
      setPage("2");
    });
  };

  const getList = (page, search) => {
    let data = { ItemsOnPage: itemsOnPage, PageNumber: page, search };

    props.getLifeStylePartner(data).then((res) => {
      setTotalItems(res?.totalItems);
      setItemsOnPage(10);
      let arr = res?.partners;
      // console.log(res)
      if (arr) {
        let dataState = arr.map((prop, key) => {
          return {
            id: key,
            name: prop.businessName,
            logo: prop.logo || eRLogo,
            userType:
              prop?.userType === "EnterpriseLifeStylePartner"
                ? "Enterprise"
                : "Business",
            ofActiveOffers: (
              <p style={{ marginLeft: "20%" }}>{prop.activeOffers}</p>
            ),
            activeOffersList: prop.activeOffersList,
            location: prop.fullAddress ? prop.fullAddress : "--",
            status: prop.membershipStatus,
            interests: prop.interests.join(", "),
            expiry: prop.expiryDate,
            actions: (
              // we've added some custom button actions
              <div key={key} className="actions-left">
                {/* use this button to add a edit kind of action */}
                <Button
                  onClick={() => {
                    fetchPartnerDetails(prop);
                  }}
                  className="btn-icon btn-round"
                  color="warning"
                  size="sm">
                  <i className="fa fa-eye" />
                </Button>{" "}
              </div>
            ),
            deactivate: (
              <div className="actions-left">
                <Switch
                  checked={
                    prop.partnerStatus.partnerStatusId == 1 ? false : true
                  }
                  onChange={(e) => {
                    if (window.confirm("Are you sure you?")) {
                      deactivateOnChange(e, prop);
                    }
                  }}
                />
              </div>
            ),
            delect: (
              <div className="actions-right">
                <Button
                  className="btn-icon btn-round"
                  color="warning"
                  onClick={() => {
                    deleteOnChange(prop);
                  }}
                  size="sm">
                  <i className="fa fa-trash" />
                </Button>
              </div>
            ),
          };
        });
        // console.log(dataState);
        setDataState(dataState);
      }
    });
  };

  const onKeyDown = (e) => {
    var theEvent = window.event || e;
    var code = theEvent.keyCode || theEvent.which || theEvent.charCode;
    if (code == 13) {
      if (e.target.value == "") {
        getList();
      } else {
        getList(pageNumber - 1, e.target.value);
      }
    }
  };
  const backButton = () => {
    if (page === "2") {
      setPage("1");
    } else {
      window.location.href = "/admin/products/identification-history";
    }
  };
  const deactivateOnChange = (checked, item) => {
    props
      .updatePartner({ id: item.id, statusCode: checked ? 2 : 1 })
      .then((res) => {
        onShowSizeChange(1);
      });
    console.log(`switch to ${checked}`);
  };
  const deleteOnChange = (item) => {
    confirm({
      title: "Do you Want to delete items?",
      icon: <ExclamationCircleOutlined />,
      content: "Cannot recover after deletion",
      onOk() {
        props.updatePartner({ id: item.id, statusCode: 3 }).then((res) => {
          message.success("Partner successfully deleted");
          getList();
        });
      },
      onCancel() {},
    });
  };
  const onShowSizeChange = (page, pageSize) => {
    setPageNumber(page);
    getList(page - 1);
  };
  return (
    <>
      {page === "1" ? (
        <div className="content users">
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Business Partners</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="" md="12">
                      <FormGroup>
                        <Input
                          placeholder="Search by name"
                          type="text"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          onKeyDown={onKeyDown}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <ReactTable
                    data={dataState}
                    columns={[
                      {
                        Header: "Name",
                        accessor: "name",
                        id: "columnId1",
                        maxWidth: 250,
                      },
                      {
                        Header: "Logo",
                        accessor: "logo",
                        Cell: (tableProps) => (
                          <img
                            src={tableProps.row.original.logo}
                            width={50}
                            alt="Picture"
                          />
                        ),
                        maxWidth: 100,
                      },
                      {
                        Header: "Partner Type",
                        accessor: "userType",
                      },
                      {
                        Header: "Active Offers",
                        accessor: "ofActiveOffers",
                        // id: "columnId2",
                        maxWidth: 130,
                      },
                      {
                        Header: "Location",
                        accessor: "location",
                        // id: "columnId3",
                      },
                      {
                        Header: "Interests",
                        accessor: "interests",
                        maxWidth: 300,
                      },
                      {
                        Header: "Expiry Date",
                        accessor: "expiry",
                        maxWidth: 120,
                        sortable: false,
                      },
                      {
                        Header: "Membership Status",
                        accessor: "status",
                        maxWidth: 180,
                        sortable: false,
                      },
                      {
                        Header: "View",
                        accessor: "actions",
                        maxWidth: 100,
                      },
                      {
                        Header: "Deactivate",
                        accessor: "deactivate",
                        sortable: false,
                        maxWidth: 100,
                      },
                      {
                        Header: "Delete",
                        accessor: "delect",
                        sortable: false,
                        maxWidth: 100,
                      },
                    ]}
                  />
                  <Row>
                    <Col md="12">
                      <div className="erPagination">
                        <Pagination
                          showSizeChanger={false}
                          current={pageNumber}
                          defaultPageSize={itemsOnPage}
                          pageSize={itemsOnPage}
                          total={totalItems}
                          onChange={onShowSizeChange}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <PartnerDetails page={page} item={item} setPage={setPage} />
      )}
    </>
  );
}

function mapStateToProps(state) {
  return state.user;
}
export default connect(mapStateToProps, actionCreators)(BusinessPartners);
