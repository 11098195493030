import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import Select from "react-select";
import actionCreators from "store/actionCreators/offer";
import { connect } from "react-redux";
import { message } from "antd";
import { useEffect } from "react";
// import "../style.css";

const CanOptions = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];
function ApproveOffer(props) {
  const [approved, setApproved] = React.useState(null);
  const [rejectReason, setRejectReason] = React.useState("");

  const handleRejectReasonInput = (e) => {
    let rejectReasonObj = { message: e.target.value };
    setRejectReason(rejectReasonObj);
  };

  const continueButton = () => {
    if (approved?.value === 1) {
      props.approveOffer(props.offer.offerId).then((res) => {
        message.success("Offer Approved !");
        props.setPage();
      });
    }

    if (approved?.value === 0) {
      if (!rejectReason) {
        message.error("A reason is required for rejection");
        return;
      }
      props.rejectOffer(props.offer.offerId, rejectReason).then((res) => {
        message.success("Offer Rejected !");
        props.setPage();
      });
    }
  };

  const checkDisableOkButton = () => {
    if (approved && approved.label == "Yes") return false;

    return (
      rejectReason == null || rejectReason == "" || rejectReason.message == ""
    );
  };

  return (
    <>
      <div className="content paybill">
        <Row>
          <Col>
            <Card>
              <CardHeader className="paybill-header">
                <h5 className="title ml-2">Are you approving this offer? </h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>Approved?</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          placeholder="Single Select"
                          name="singleSelect"
                          value={approved}
                          options={CanOptions}
                          onChange={(value) => {
                            setApproved(value);
                            setRejectReason("");
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {approved?.value == 0 ? (
                    <Row>
                      <Col className="pr-1" md="12">
                        <FormGroup>
                          <label>Reject Reason</label>
                          <Input
                            onChange={(e) => handleRejectReasonInput(e)}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  <Row>
                    <Col
                      md="6"
                      className="footer-buttons d-flex justify-content-start">
                      <Button
                        onClick={() => props.setPage()}
                        color="secondary"
                        size="md"
                        className="btn-round mr-2 cancel-button">
                        Cancel
                      </Button>
                      <Button
                        disabled={checkDisableOkButton()}
                        onClick={continueButton}
                        color="primary"
                        size="md"
                        className="btn-round">
                        Ok
                      </Button>
                    </Col>
                    <Col md="6"></Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return state.user;
}
export default connect(mapStateToProps, actionCreators)(ApproveOffer);
