import * as actionTypes from '../action-types';
import { updateTermsOfService,getTermsOfService,updatePrivacyPolicy,getPrivacyPolicy ,updateAbout,getAbout} from '../../api/platform';

const actionCreators = {
    updateTermsOfService(data) {
        return (dispatch) => new Promise(async (resolve, reject) => {
            try {
                dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
                let res = await updateTermsOfService(data)
                resolve(res)
            } catch (error) {                
               
            } finally {
                dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
            }
        })
       
    },
    getTermsOfService(data) {
        return (dispatch) => new Promise(async (resolve, reject) => {
            try {
                dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
                let res = await getTermsOfService(data)
                resolve(res)
            } catch (error) {                
               
            } finally {
                dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
            }
        })
       
    },
    updatePrivacyPolicy(data) {
        return (dispatch) => new Promise(async (resolve, reject) => {
            try {
                dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
                let res = await updatePrivacyPolicy(data)
                resolve(res)
            } catch (error) {                
               
            } finally {
                dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
            }
        })
       
    },
    getPrivacyPolicy(data) {
        return (dispatch) => new Promise(async (resolve, reject) => {
            try {
                dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
                let res = await getPrivacyPolicy(data)
                resolve(res)
            } catch (error) {                
               
            } finally {
                dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
            }
        })
       
    },
    updateAbout(data) {
        return (dispatch) => new Promise(async (resolve, reject) => {
            try {
                dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
                let res = await updateAbout(data)
                resolve(res)
            } catch (error) {                
               
            } finally {
                dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
            }
        })
       
    },
    getAbout(data) {
        return (dispatch) => new Promise(async (resolve, reject) => {
            try {
                dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
                let res = await getAbout(data)
                resolve(res)
            } catch (error) {                
               
            } finally {
                dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
            }
        })
       
    },
}
export default actionCreators;