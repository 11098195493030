import axios from "../utils/request"

export function addBrand(data) {
    return axios({
        url: `/api/v1/Manufacturers`,
        method: "post",
        data,
      })
}
export function delBrand(id) {
    return axios({
        url: `/api/v1/Manufacturers/${id}`,
        method: "DELETE",
      })
}
export function getBrand(data) {
    return axios({
        url: `/api/v1/Manufacturers`,
        method: "get",
        data,
      })
}
