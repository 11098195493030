import React, { useEffect, useState, useMemo, useRef } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import Select from "react-select";
import { DatePicker } from "antd";
import moment from "moment";
// import "../style.css";
import actionCreators from "store/actionCreators/offer";
import leftIcon from "../../../../assets/icons/icon-left.png";

import { connect } from "react-redux";
const CanOptions = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];

function EditOffer(props) {
  const [tagsList, setTagList] = useState([]);
  const [offer, setOffer] = useState(null);

  useEffect(() => {
    getOffer();
  }, []);
  const getOffer = () => {
    console.log(props.item);
    props.getOfferDetails({ offerId: props.item.offerId }).then((res) => {
      setOffer(res);
      let interests = res.associatedInterests.map((interest) => {
        return interest.name;
      });
      setTagList(interests);
    });
  };
  const approveButton = () => {
    props.onApprove();
  };

  const backButton = () => {
    props.setPage("1");
  };
  console.log(offer);
  return (
    <>
      {offer && (
        <div className="content paybill">
          <div onClick={backButton} className="back-button-title mb-2">
            <img className="mb-1" width="14px" src={leftIcon} />
            Back
          </div>
          <Row>
            <Col md="7">
              <Card>
                <CardHeader className="paybill-header">
                  <h5 className="title ml-2">Offer Details</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="12">
                        <FormGroup>
                          <label>Offer Name</label>
                          <Input
                            readOnly
                            defaultValue={offer.title}
                            placeholder="OfferName"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="12">
                        <FormGroup>
                          <label>Description</label>
                          <div
                            style={{
                              backgroundColor: "#E3E3E3",
                              cursor: "not-allowed",
                            }}
                            className="p-2 rounded cursor-not-allowed"
                            dangerouslySetInnerHTML={{
                              __html: offer?.description,
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="6">
                        <div>
                          {" "}
                          <label>Cover Image</label>
                        </div>
                        <img src={offer.coverImage} />
                      </Col>
                      {offer?.offerType?.offerTypeId === 1 && (
                        <Col className="pr-1" md="6">
                          <div>
                            {" "}
                            <label>Additional Image</label>
                          </div>
                          <img
                            alt="No image"
                            src={offer.additionalOfferImage}
                          />
                        </Col>
                      )}

                      {offer?.offerType?.offerTypeId === 1 && (
                        <Col className="pr-1 mt-2" md="6">
                          <FormGroup>
                            <label>Is the Offer Reusable?</label>
                            <Input
                              readOnly
                              defaultValue={offer.isReusable ? "Yes" : "No"}
                              placeholder="Reusable"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      )}
                      {offer?.offerType?.offerTypeId === 1 && (
                        <Col className="pr-1 mt-2" md="6">
                          <FormGroup>
                            <label>Discount</label>
                            <Input
                              readOnly
                              defaultValue={`$ ${offer.discountValue}`}
                              placeholder="Discount"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      )}
                      {/* <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Is it an Event?</label>
                          <Input
                            readOnly
                            defaultValue={offer.isEvent ? "Yes" : "No"}
                            placeholder="Reusable"
                            type="text"
                          />
                        </FormGroup>
                      </Col> */}
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Start Date</label>
                          <br />
                          <Input
                            defaultValue={moment(offer?.startDate).format(
                              "YYYY-MM-DD"
                            )}
                            style={{ width: "100%", height: "36px" }}
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Expiry Date</label>
                          <br />
                          <Input
                            readOnly
                            defaultValue={moment(offer?.expiryDate).format(
                              "YYYY-MM-DD"
                            )}
                            style={{ width: "100%", height: "36px" }}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Partner</label>
                          <Input
                            readOnly
                            defaultValue={offer.partner}
                            placeholder="Partner"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Partner Type</label>
                          <Input
                            readOnly
                            defaultValue={offer?.partnerType}
                            placeholder="Partner"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Status</label>
                          <Input
                            readOnly
                            defaultValue={
                              offer.status == 3
                                ? "Approved"
                                : offer.status == 2
                                ? "Rejected"
                                : "Pending"
                            }
                            placeholder="Status"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="pr-1" md="12">
                        <p className="text-[16px]">Offer Interests:</p>
                        {tagsList?.length > 0 ? tagsList.join(", ") : "None"}
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        md="6"
                        className="footer-buttons d-flex justify-content-start mt-4">
                        <Button
                          onClick={() => props.setPage("1")}
                          color="secondary"
                          size="md"
                          className="btn-round mr-2 cancel-button">
                          Return{" "}
                        </Button>
                        <Button
                          onClick={approveButton}
                          color="primary"
                          size="md"
                          className="btn-round">
                          Approve/Reject{" "}
                        </Button>
                      </Col>
                      <Col md="6"></Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return state.user;
}

export default connect(mapStateToProps, actionCreators, null, {
  forwardRef: true,
})(EditOffer);
