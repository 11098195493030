import * as actionTypes from "../action-types";
import {
  accountLogin,
  resetPassword,
  changePassword,
  forgetPassword,
  createAccount,
} from "../../api/login";
import { message } from "antd";
const getValidate = () => {
  let token = localStorage.getItem("access_token");
  if (token) {
    return { success: true };
  } else {
    return { success: false };
  }
};
const actionCreators = {
  validate() {
    return (dispatch) => {
      dispatch({ type: actionTypes.VALIDATE, payload: getValidate() });
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    };
  },
  getLogin(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await accountLogin(data);
          localStorage.setItem("access_token", res.accessToken);
          dispatch({ type: actionTypes.VALIDATE, payload: getValidate() });
          dispatch({
            type: actionTypes.SET_USER,
            payload: {
              ...res.adminProfile,
              email: data.email,
              changePasswordToken: res.changePasswordToken,
            },
          });
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
  changePassword(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await changePassword(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
  createAccount(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await createAccount(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
  logout() {
    return function (dispatch) {
      localStorage.removeItem("access_token");
      dispatch({ type: actionTypes.LOGOUT });
    };
  },
  resetPassword(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await resetPassword(data);
          resolve(res);
        } catch (error) {
          reject(error);
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
  forgetPassword(data) {
    return (dispatch) =>
      new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
          let res = await forgetPassword(data);
          resolve(res);
        } catch (error) {
        } finally {
          dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        }
      });
  },
};

export default actionCreators;
